<template>
  <div class="bg-white dark:bg-navy-700 rounded-xl py-2 h-full w-full">
    <div
      class="flex px-4 justify-between border-b dark:border-navy-500 border-navy-10 relative"
    >
      <div class="flex gap-6">
        <div
          v-for="item in headerItems"
          :key="item.id"
          :class="[
            'cursor-pointer pb-3 flex justify-center text-sm font-medium',
            item.shouldHide ? `sm:hidden block` : '',
            selectedItem.id === item.id
              ? 'text-navy-800 dark:text-white'
              : 'text-navy-200 dark:text-navy-100',
          ]"
          @click="handleSelectTab(item)"
        >
          <p>{{ item.name }}</p>
          <div
            :class="[
              'border-2 border-b rounded-full absolute bottom-0 w-5',
              selectedItem.id === item.id
                ? 'dark:border-white border-navy-800'
                : 'border-none',
            ]"
          />
        </div>
      </div>
      <slot name="extra-header" />
    </div>
    <div>
      <slot :name="`content-${selectedItem.id}`" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  headerItems: {
    type: Array,
    default: () => [],
  },
});

const selectedItem = ref(props.headerItems[0] ?? {});

const handleSelectTab = (item) => {
  selectedItem.value = item;
};
</script>
