<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2116 9.64625L9.21157 4.64625C9.16513 4.59976 9.10998 4.56288 9.04929 4.53772C8.98859 4.51256 8.92352 4.49961 8.85782 4.49961C8.79211 4.49961 8.72704 4.51256 8.66635 4.53772C8.60565 4.56288 8.5505 4.59976 8.50407 4.64625L3.50407 9.64625C3.43406 9.71618 3.38638 9.8053 3.36705 9.90235C3.34773 9.99939 3.35763 10.1 3.3955 10.1914C3.43338 10.2828 3.49753 10.3609 3.57982 10.4159C3.66212 10.4708 3.75887 10.5001 3.85782 10.5H13.8578C13.9568 10.5001 14.0535 10.4708 14.1358 10.4159C14.2181 10.3609 14.2823 10.2828 14.3201 10.1914C14.358 10.1 14.3679 9.99939 14.3486 9.90235C14.3293 9.8053 14.2816 9.71618 14.2116 9.64625Z"
      fill="currentColor"
    />
  </svg>
</template>
