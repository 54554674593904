<template>
  <div>
    <phone-input
      label="Phone authentication"
      placeholder="Enter your number"
      v-model="phone"
    />
    <div class="h-6" />

    <password-input
      label="Password"
      placeholder="Password"
      v-model="password"
      :error="passwordError"
    />
    <div class="h-6" />

    <password-input
      label="Confirm Password"
      placeholder="Confirm Password"
      v-model="confirmPassword"
      :error="confirmPasswordError"
    />
    <div class="h-6" />

    <collapse-view>
      <template #header="{ handleContentClick }">
        <div
          class="flex space-x-3 items-center mb-2 cursor-pointer"
          @click="handleContentClick"
        >
          <p class="font-bold text-sm">Referral code (Optional)</p>
          <ChevronDownIcon class="w-5 h-5 stroke-2" />
        </div>
      </template>
      <template #content>
        <text-edit
          type="text"
          :filled="true"
          rounded="xl"
          label=""
          placeholder="Referral code"
          v-model="refferal"
        />
      </template>
    </collapse-view>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { validatePassword } from "../../validation.js";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";
import PhoneInput from "@/components/PhoneInput/PhoneInput.vue";

const phone = ref("");
const password = ref("");
const confirmPassword = ref("");
const refferal = ref("");
const emit = defineEmits([
  "changePhone",
  "changePassword",
  "changeConfirmPassword",
  "changeRefferal",
]);

const passwordError = computed(() => validatePassword(password.value));
const confirmPasswordError = computed(() => {
  const isValid = validatePassword(confirmPassword.value);
  return isValid.every((item) => item === true)
    ? confirmPassword.value === password.value
      ? ""
      : "Confirm password is not matched"
    : isValid;
});

watch(phone, (newPhone) => {
  emit("changePhone", newPhone);
});

watch(password, (newPassword) => {
  emit("changePassword", newPassword);
});

watch(confirmPassword, (newConfirmPassword) => {
  emit("changeConfirmPassword", newConfirmPassword);
});

watch(refferal, (newRefferal) => {
  emit("changeRefferal", newRefferal);
});
</script>
