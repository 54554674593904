import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "vuex";
import { h, ref } from "vue";
import HomeView from "../views/Home/HomeView.vue";
import AuthLayout from "@/layout/AuthLayout/AuthLayout.vue";
import MainLayout from "@/layout/MainLayout/MainLayout.vue";
import NonExisting from "@/views/Error/NonExisting.vue";
import SignIn from "@/views/Auth/Signin/SignIn.vue";
import VerificationCode from "@/views/Auth/VerificationCode/VerificationCode.vue";
import Signup from "@/views/Auth/Signup/SignUp.vue";
import sessionManager from "@/utils/SessionManager";
import TradingView from "@/views/Trading/TradingView.vue";
import SidebarLayout from "@/layout/SidebarLayout/SidebarLayout.vue";
import {
  announcementMenuItems,
  profileSidebarItems,
} from "@/constants/constants";
import { assetMenuItems } from "@/views/Asset/assetSidebar";
import ConvertView from "@/views/Convert/ConvertView.vue";
import MarketView from "@/views/Market/MarketView.vue";
import ForgetPassword from "@/views/Auth/ForgetPassword/ForgetPassword.vue";
import RecoveryPassword from "@/views/Auth/Recovery/RecoveryPassword.vue";
import HelpCenter from "@/views/HelpCenter/HelpCenter.vue";
import HelpCenterArticleView from "@/views/HelpCenter/HelpCenterArticleView.vue";
import AnnouncementTop from "@/views/Announcement/AnnouncementTop.vue";
import AnnouncementView from "@/views/Announcement/AnnouncementView.vue";
import CareerHomeView from "@/views/Career/CareerHomeView.vue";
import CareerDetailView from "@/views/Career/CareerDetailView.vue";
import LaucnpadHomeView from "@/views/Launchpad/LaucnpadHomeView.vue";
import LaunchpadSubscriptionHistoryView from "@/views/Launchpad/LaunchpadSubscriptionHistoryView.vue";
import LaunchpadProjectDetailView from "@/views/Launchpad/LaunchpadProjectDetailView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: (
      <MainLayout>
        <HomeView />
      </MainLayout>
    ),
    meta: {
      authPage: false,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
    meta: {
      authPage: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: (
      <AuthLayout>
        <Signup />
      </AuthLayout>
    ),
    meta: {
      authPage: true,
    },
  },
  {
    path: "/verification-code",
    name: "verification-code",
    component: (
      <AuthLayout>
        <VerificationCode />
      </AuthLayout>
    ),
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: (
      <AuthLayout>
        <ForgetPassword />
      </AuthLayout>
    ),
  },
  {
    path: "/recovery/:key?",
    name: "recovery",
    component: (
      <AuthLayout>
        <RecoveryPassword />
      </AuthLayout>
    ),
  },
  {
    path: "/trading/:pair?",
    name: "trading",
    component: (
      <MainLayout is-trading>
        <TradingView />
      </MainLayout>
    ),
  },
  {
    path: "/profile",
    children: profileSidebarItems.map((item) => ({
      path: item.path,
      name: item.name,
      component: {
        render() {
          return h(
            MainLayout,
            {},
            {
              default: () =>
                h(
                  SidebarLayout,
                  { menuData: profileSidebarItems },
                  {
                    content: () => h(item.content),
                  }
                ),
            }
          );
        },
      },
    })),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset",
    children: assetMenuItems.map((item) => ({
      path: item.path,
      name: item.name,
      component: {
        render() {
          return h(
            MainLayout,
            {},
            {
              default: () =>
                h(
                  SidebarLayout,
                  { menuData: assetMenuItems },
                  {
                    top: () => <div class="h-10" />,
                    content: () => h(item.content),
                  }
                ),
            }
          );
        },
      },
    })),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/convert",
    name: "convert",
    component: (
      <MainLayout>
        <ConvertView />
      </MainLayout>
    ),
  },
  {
    path: "/markets",
    name: "markets",
    component: (
      <MainLayout>
        <MarketView />
      </MainLayout>
    ),
  },
  {
    path: "/help-center",
    children: [
      {
        path: "",
        name: "help-center",
        component: (
          <MainLayout>
            <HelpCenter />
          </MainLayout>
        ),
      },
      {
        path: ":url",
        name: "help-center-article",
        component: (
          <MainLayout>
            <HelpCenterArticleView />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "/announcement",
    children: announcementMenuItems.map((item) => ({
      path: item.path,
      name: item.name,
      component: {
        setup() {
          const searchText = ref("");
          const handleSearchTextChange = (newText) => {
            searchText.value = newText;
          };
          return {
            searchText,
            handleSearchTextChange,
          };
        },
        render() {
          return h(
            MainLayout,
            {},
            {
              default: () =>
                h(
                  SidebarLayout,
                  { menuData: announcementMenuItems },
                  {
                    top: () =>
                      h(AnnouncementTop, {
                        handleChange: this.handleSearchTextChange,
                      }),
                    content: () =>
                      h(AnnouncementView, {
                        searchText: this.searchText,
                      }),
                  }
                ),
            }
          );
        },
      },
    })),
  },
  {
    path: "/career",
    children: [
      {
        path: "",
        name: "career",
        component: (
          <MainLayout>
            <CareerHomeView />
          </MainLayout>
        ),
      },
      {
        path: ":url",
        name: "career-detail",
        component: (
          <MainLayout>
            <CareerDetailView />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "/launchpad",
    children: [
      {
        path: "",
        name: "launchpad",
        component: (
          <MainLayout>
            <LaucnpadHomeView />
          </MainLayout>
        ),
      },
      {
        path: "subscription-history",
        name: "launchpad-subscription-history",
        component: (
          <MainLayout>
            <LaunchpadSubscriptionHistoryView />
          </MainLayout>
        ),
      },
      {
        path: "project/:url",
        name: "launchpad-project-detail",
        component: (
          <MainLayout>
            <LaunchpadProjectDetailView />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: <NonExisting />,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  if (store.getters.code.required && to.name !== "verification-code") {
    next({ name: "verification-code" });
  } else if (!store.getters.code.required && to.name === "verification-code") {
    next(from);
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!sessionManager.isLoggedIn()) {
        next({ name: "signin" });
      } else {
        next();
      }
    } else if (to.matched.some((record) => record.meta.authPage)) {
      if (sessionManager.isLoggedIn()) {
        next({ name: "home" });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
