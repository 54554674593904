<template>
  <svg
    width="34"
    height="36"
    viewBox="0 0 34 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.7507 10.744L5.9424 0.674632C4.96501 0.151397 3.9052 0.124656 2.98792 0.459087L19.0117 16.4829L24.7507 10.744Z"
      fill="currentColor"
    />
    <path
      d="M31.7646 14.4991L26.3469 11.5986L20.2372 17.7083L26.3469 23.818L31.7645 20.9175C34.3274 19.5454 34.3274 15.8711 31.7646 14.4991Z"
      fill="currentColor"
    />
    <path
      d="M1.52088 1.44287C0.946213 2.07448 0.583984 2.91768 0.583984 3.88379V31.5327C0.583984 32.4988 0.946213 33.3421 1.52081 33.9737L17.7863 17.7083L1.52088 1.44287Z"
      fill="currentColor"
    />
    <path
      d="M2.98792 34.9574C3.90513 35.2918 4.96501 35.2652 5.9424 34.7419L24.7507 24.6725L19.0118 18.9336L2.98792 34.9574Z"
      fill="currentColor"
    />
  </svg>
</template>
