<template>
  <button
    :class="[
      widthClass,
      sizeClass,
      disabled
        ? 'dark:bg-navy-400 bg-navy-10 text-navy-200 cursor-not-allowed'
        : colorClass,
      radiusClass,
      'flex items-center gap-2 justify-center',
    ]"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
const props = defineProps({
  width: String,
  size: String,
  color: String,
  rounded: {
    type: String,
    default: "full",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["click"]);

const widthClass = computed(() => {
  switch (props.width) {
    case "full":
      return "w-full";
    case "fit":
      return "w-fit";
    default:
      return "w-fit";
  }
});

const sizeClass = computed(() => {
  switch (props.size) {
    case "lg":
      return "px-12 py-4 text-base font-semibold";
    case "md":
      return "px-6 py-3 text-sm font-semibold";
    case "sm":
      return "px-4 py-2 text-sm font-semibold";
    case "xs":
      return "px-3 py-2.5 text-sm font-medium";
    default:
      return "px-12 py-4";
  }
});

const colorClass = computed(() => {
  switch (props.color) {
    case "green":
      return "bg-green-400 text-white hover:bg-green-500";
    case "red":
      return "bg-red-400 text-white hover:bg-red-500";
    case "navy":
      return "dark:bg-navy-600 dark:hover:bg-navy-500 bg-navy-10 hover:bg-navy-50 dark:text-white text-navy-800";
    case "primary":
    default:
      return "bg-primary-700 text-white hover:bg-primary-800";
  }
});

const radiusClass = computed(() => {
  if (props.rounded) {
    return `rounded-${props.rounded}`;
  } else {
    return "rounded-full";
  }
});

const handleClick = () => {
  emits("click");
};
</script>
