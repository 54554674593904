const state = {
  isLoading: false,
  pendingRequest: 0,
};

const mutations = {
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_PENDING_REQUEST(state, value) {
    state.pendingRequest = value;
  },
  INCREASE_PENDING_REQUEST(state) {
    state.pendingRequest++;
  },
  DECREASE_PENDING_REQUEST(state) {
    state.pendingRequest--;
  },
};

const actions = {
  setIsLoading({ commit }, isLoading) {
    commit("SET_IS_LOADING", isLoading);
  },
  setPendingRequest({ commit }, value) {
    commit("SET_PENDING_REQUEST", value);
  },
  increasePendingRequest({ commit }) {
    commit("INCREASE_PENDING_REQUEST");
  },
  decreasePendingRequest({ commit }) {
    commit("DECREASE_PENDING_REQUEST");
  },
};

const getters = {
  isLoading: (state) => state.pendingRequest !== 0,
  pendingRequest: (state) => state.pendingRequest,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
