<template>
  <div
    class="flex flex-row items-center gap-2 text-xs font-medium text-navy-100"
  >
    <template v-for="(item, index) in props.items" :key="item.id">
      <div
        :class="[
          item.active === true ? 'text-navy-200 dark:text-white' : '',
          'cursor-pointer',
        ]"
        @click="handleClick(item)"
      >
        {{ item.label }}
      </div>
      <ChevronRightIcon
        v-if="index < items.length - 1"
        class="w-4 h-4 stroke-2"
      />
    </template>
  </div>
</template>
<script setup>
import { isEmpty } from "@/utils/utils";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const props = defineProps(["items"]);
const router = useRouter();

const handleClick = (item) => {
  if (isEmpty(item.pathName)) {
    return true;
  }
  router.push({ name: item.pathName });
};
</script>
