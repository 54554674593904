<template>
  <div>
    <p v-if="label" class="font-semibold mb-2 text-sm">
      {{ props.label }}
    </p>
    <div
      :class="[
        `flex justify-between border-2 w-full ${sizeClass} ${roundedClass}`,
        !filled
          ? `bg-white ${!props.error && 'border-navy-50'} dark:bg-navy-700 ${
              !props.error && 'dark:border-navy-400'
            }`
          : `bg-navy-10 ${!props.error && 'border-navy-10'} dark:bg-navy-500 ${
              !props.error && 'dark:border-navy-500'
            }`,
        props.error ? 'border-red-500' : 'focus-within:border-primary-700',
      ]"
    >
      <slot name="prefix" />
      <input
        :class="[
          'w-full focus:outline-none placeholder:text-navy-200 appearance-none text-sm custom-scrollbar',
          !filled
            ? currentTheme === 'light'
              ? 'bg-white input-light-mode'
              : 'bg-navy-700 input-dark-mode'
            : currentTheme === 'light'
            ? 'bg-navy-10'
            : 'bg-navy-500',
        ]"
        v-model="inputValue"
        :disabled="disabled"
        :placeholder="props.placeholder"
        :type="props.type"
        @focus="handleFocus"
      />
      <slot name="suffix" />
    </div>
    <p v-if="props.error" class="text-red-500 mt-2 text-xs">
      {{ props.error }}
    </p>
  </div>
</template>

<script setup>
import { computed, defineModel, defineProps, defineEmits } from "vue";

const props = defineProps([
  "value",
  "placeholder",
  "label",
  "error",
  "type",
  "filled",
  "size",
  "rounded",
  "disabled",
]);

const emit = defineEmits(["focus"]);

const inputValue = defineModel();
const sizeClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "px-3 py-2";
    default:
      return "p-4";
  }
});
const roundedClass = computed(() => `rounded-${props.rounded}`);

const handleFocus = () => {
  emit("focus");
};
</script>
