<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 10.7638V10.5C24 8.6725 22.6463 7.05625 20.1863 5.95C18.1088 5.015 15.375 4.5 12.5 4.5C9.625 4.5 6.89125 5.015 4.81375 5.95C2.35375 7.05625 1 8.6725 1 10.5V15.5C1 17.3275 2.35375 18.9437 4.81375 20.05C6.15058 20.6285 7.55829 21.027 9 21.235V21.5C9 23.3275 10.3538 24.9437 12.8138 26.05C14.8913 26.985 17.625 27.5 20.5 27.5C23.375 27.5 26.1088 26.985 28.1863 26.05C30.6463 24.9437 32 23.3275 32 21.5V16.5C32 13.7325 28.8387 11.51 24 10.7638ZM29 16.5C29 17.4688 26.2788 19.31 21.2738 19.485C23.0413 18.425 24 17.0375 24 15.5V13.805C27.235 14.3938 29 15.685 29 16.5ZM13.9213 18.4538C13.4675 18.4838 12.9937 18.5 12.5 18.5C11.865 18.5 11.2638 18.4725 10.6962 18.4237C10.649 18.4175 10.6014 18.4138 10.5538 18.4125C10.365 18.395 10.1788 18.375 10 18.3525V16.3662C10.8303 16.4555 11.6649 16.5001 12.5 16.5C13.3351 16.5001 14.1697 16.4555 15 16.3662V18.3525C14.6888 18.39 14.3663 18.4225 14.0312 18.4463C14 18.4475 13.9575 18.45 13.9213 18.4538ZM21 14.6425V15.5C21 16.0975 19.965 17.0262 18 17.6925V15.8175C18.7481 15.6212 19.4792 15.3649 20.1863 15.0513C20.4729 14.9221 20.7442 14.7858 21 14.6425ZM12.5 7.5C18 7.5 21 9.48125 21 10.5C21 11.5188 18 13.5 12.5 13.5C7 13.5 4 11.5188 4 10.5C4 9.48125 7 7.5 12.5 7.5ZM4 15.5V14.6425C4.25667 14.785 4.52792 14.9208 4.81375 15.05C5.52076 15.3637 6.25187 15.6199 7 15.8162V17.6912C5.035 17.0262 4 16.0975 4 15.5ZM12 21.5C12.1663 21.5 12.3325 21.5 12.5 21.5C12.9533 21.5 13.4021 21.4867 13.8462 21.46C14.2171 21.5908 14.6017 21.71 15 21.8175V23.6925C13.035 23.0262 12 22.0975 12 21.5ZM18 24.3525V22.3675C18.8304 22.4564 19.6649 22.5007 20.5 22.5C21.3351 22.5001 22.1697 22.4555 23 22.3662V24.3525C21.3391 24.5492 19.6609 24.5492 18 24.3525ZM26 23.6925V21.8175C26.7481 21.6211 27.4792 21.3649 28.1863 21.0513C28.4729 20.9229 28.7442 20.7871 29 20.6437V21.5C29 22.0975 27.965 23.0262 26 23.6925Z"
      fill="currentColor"
    />
  </svg>
</template>
