<template>
  <div
    :class="[
      'rounded-full flex flex-row w-fit items-center cursor-pointer',
      props.size === 'md' ? 'py-1.5 px-3' : 'py-1 px-2',
      props.class,
    ]"
  >
    <span
      v-if="props.icon || $slots.default"
      :class="[props.size === 'md' ? 'w-5 h-5 mr-2' : 'w-4 h-4 mr-0.5']"
    >
      <component
        v-if="props.icon"
        :is="props.icon"
        :class="[props.size === 'md' ? 'w-5 h-5' : 'w-4 h-4']"
      />
      <slot />
    </span>
    <span>{{ props.label }}</span>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["label", "class", "size", "icon"]);
</script>
