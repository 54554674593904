<template>
  <tr class="text-xs cursor-pointer relative" @click="handleClick">
    <td :class="['text-left', isBuy ? 'text-green-400' : 'text-red-500']">
      {{ item.rate_f }}
      <div
        v-if="showLines"
        ref="orderBookItem"
        :class="[
          'absolute h-[90%] dark:opacity-20 opacity-10 rounded-sm top-0 right-0 transition-width duration-300 ease-out',
          isBuy ? 'bg-green-400' : 'bg-red-500',
        ]"
      ></div>
    </td>
    <td class="text-center">{{ item.volume_f }}</td>
    <td class="text-right w-[100px]">{{ item.price_f }}</td>
  </tr>
</template>

<script setup>
import { ref, computed, watchEffect, defineProps } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  width: Number,
  item: Object,
  isBuy: Boolean,
  showLines: Boolean,
});

const store = useStore();
const orderBookItem = ref(null);
const percent = computed(() => props.item.percent_f);

watchEffect(() => {
  if (orderBookItem.value)
    orderBookItem.value.style.width = percent.value * props.width + "px";
});

const handleClick = () => {
  store.dispatch("setCurrentSelectedPrice", props.item.rate_f_original);
};
</script>
