<template>
  <div class="dark:bg-navy-800 bg-navy-10 rounded-full w-fit p-1">
    <button
      v-for="item in items"
      :key="item.id"
      :class="[
        'rounded-full dark:hover:bg-navy-600 hover:bg-navy-50 px-3 py-1',
        selectedTab.id === item.id ? 'dark:bg-navy-600 bg-navy-50' : '',
      ]"
      @click="handleSelectTab(item)"
    >
      <img class="w-4 h-4" :src="item.content" alt="Selector icon" />
    </button>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
defineProps({
  selectedTab: Object,
  items: Object,
});

const emits = defineEmits(["selectTab"]);

const handleSelectTab = (item) => {
  emits("selectTab", item);
};
</script>
