<template>
  <div
    :class="['relative', props.padding === true ? 'px-2' : '']"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      class="h-16 rounded-lg flex flex-row p-3 gap-3 cursor-pointer dark:hover:bg-navy-500 hover:bg-primary-100"
      @click="handleClick"
    >
      <div
        class="w-10 h-10 rounded-full p-2 bg-primary-700 bg-opacity-[18%] dark:text-primary-500 text-primary-700"
      >
        <component :is="props.icon" class="w-6 h-6" />
      </div>
      <div class="flex flex-col gap-1">
        <div class="text-sm font-medium dark:text-white text-navy-800">
          {{ props.title }}
        </div>
        <div class="text-xs font-medium dark:text-navy-100 text-navy-200">
          {{ props.label }}
        </div>
      </div>
    </div>
    <div v-if="showExtraContent" class="absolute left-full ml-0 -top-[9px]">
      <slot />
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, ref } from "vue";
import { useRouter } from "vue-router";
import { isEmpty } from "@/utils/utils";

const router = useRouter();
const showSlot = ref(false);

const props = defineProps([
  "title",
  "label",
  "padding",
  "icon",
  "pathName",
  "showExtraContent",
]);
const emit = defineEmits(["click", "mouseEnter", "mouseLeave"]);

const handleClick = () => {
  if (!isEmpty(props.pathName)) router.push({ name: props.pathName });
  emit("click");
};

const handleMouseEnter = () => {
  emit("mouseEnter");
  showSlot.value = true;
};

const handleMouseLeave = () => {
  emit("mouseLeave");
  showSlot.value = false;
};
</script>
