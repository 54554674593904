<template>
  <div>
    <p class="font-semibold mb-6">Balance Details</p>
    <div
      class="w-80 border-2 dark:border-navy-500 border-navy-50 rounded-2xl p-6"
    >
      <canvas id="pieChart" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import Chart from "chart.js/auto";
import { isEmpty } from "@/utils/utils";

const store = useStore();
const balances = computed(() =>
  Object.values(store.getters.balancesVolumeModified).filter((item) =>
    item.balances.USDT != 0 ? item.balances.USDT : item.balances.USDC
  )
);

const currentTheme = computed(() => store.getters.currentTheme);
const chart = ref(null);

watch([balances, currentTheme], () => {
  if (!isEmpty(balances.value)) {
    generateChart();
  }
});

onMounted(() => {
  if (!isEmpty(balances.value)) {
    generateChart();
  }
});

const generateChart = () => {
  if (chart.value) {
    chart.value.destroy();
  }
  const ctx = document.getElementById("pieChart").getContext("2d");
  const balancesValue = balances.value.map((item) =>
    (
      (item.balances.USDT != 0 ? item.balances.USDT : item.balances.USDC) /
      Math.pow(10, item.currency.decimal)
    ).toFixed(3)
  );
  const labels = balances.value.map(
    (item, index) => item.currency.iso3 + " " + balancesValue[index]
  );
  // const randomColors = balancesValue.map(() => getRandomColor());

  chart.value = new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: labels,
      datasets: [
        {
          label: "",
          data: balancesValue,
          // backgroundColor: randomColors,
          borderWidth: 0,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      cutout: "85%",
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
            boxWidth: 8,
            boxHeight: 8,
            font: {
              size: 14,
            },
            color: currentTheme.value === "light" ? "#000000" : "#FFFFFF",
            padding: 10,
          },
        },
      },
    },
  });
};
</script>
