import bs58 from "bs58";
import { isEmpty } from "@/utils/utils";
import { DEFAULT_RESEND_CODE_DURATION } from "@/constants/constants";

const getAuthData = () => {
  try {
    const privateKey = localStorage.getItem("privateKey") || "";

    if (!privateKey) {
      return {};
    }

    // Get auth data
    const decoder = new TextDecoder();
    const data = decoder.decode(bs58.decode(privateKey));

    return JSON.parse(data);
  } catch (error) {
    return "";
  }
};

const session_manager = {
  setTokenParsed(token) {
    localStorage.setItem("user-token", JSON.stringify(token));
  },
  getTokenParsed() {
    const tokenData = getAuthData();
    if (!isEmpty(tokenData)) return tokenData;
    const jsonToken = localStorage.getItem("user-token");
    if (jsonToken) return JSON.parse(jsonToken);
    return {
      token: "",
      secret: "",
      email: "",
      phone: "",
    };
  },
  getToken() {
    return getAuthData().token || null;
  },
  getPrivateKey() {
    return localStorage.getItem("privateKey");
  },
  removeToken() {
    localStorage.removeItem("privateKey");
    localStorage.removeItem("user-token");
    localStorage.removeItem("session");
    localStorage.removeItem("user-mail");
    localStorage.removeItem("two_f_need");
    localStorage.removeItem("user-chat");
  },
  set2FactorCodeInfo(codeInfo) {
    localStorage.setItem("two_f_need", JSON.stringify(codeInfo));
  },
  get2FactorCodeInfo() {
    const info = localStorage.getItem("two_f_need");
    return info
      ? JSON.parse(info)
      : {
          required: false,
          requiredType: false,
          codeSetnTo: "",
          timeBeforeNewCode: DEFAULT_RESEND_CODE_DURATION,
        };
  },
  remove2FactorRequirement() {
    localStorage.removeItem("two_f_need");
  },
  isLoggedIn() {
    const authData = getAuthData();

    return !!(authData.token && authData.secret);
    //return !!(authData.token && authData.secret && !localStorage.getItem("two_f_need"));
  },
  getPublicStr() {
    return getAuthData().secret || null;
  },
  setCurrentPairId(id) {
    localStorage.setItem("pair-id", id);
  },
  getCurrentPairId() {
    localStorage.getItem("pair-id");
  },
  setDemoFlag(str) {
    localStorage.setItem("demo-mode", str);
  },
  getDemoFlag() {
    return localStorage.getItem("demo-mode");
  },
  removeDemoFlag() {
    localStorage.removeItem("demo-mode");
  },
  setTheme(mode) {
    localStorage.setItem("current-theme", mode);
  },
  getTheme() {
    const currentTheme = localStorage.getItem("current-theme");
    return currentTheme === "light" ? "light" : "dark";
  },
  removeTheme() {
    localStorage.removeItem("current-theme");
  },
};

export default session_manager;
