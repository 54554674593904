<template>
  <OnClickOutside @trigger="isShowingMenu = false">
    <div
      class="relative"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseOut"
    >
      <slot
        name="header"
        :handleContentClick="handleContentClick"
        :isShowingMenu="isShowingMenu"
      />
      <div
        v-if="isShowingMenu"
        :class="[
          'absolute z-50',
          computedPosition,
          props.width === 'full' ? 'w-full' : 'w-fit',
          props.mobileAdapted
            ? 'max-sm:fixed max-sm:left-0 max-sm:right-0 max-sm:self-center max-sm:transform-none max-sm:w-full'
            : '',
        ]"
      >
        <slot name="menu" :handleMenuItemClick="handleMenuItemClick" />
      </div>
    </div>
  </OnClickOutside>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed } from "vue";
import { OnClickOutside } from "@vueuse/components";

const props = defineProps([
  "alignment",
  "width",
  "isHoverTrigger",
  "mobileAdapted",
]);
const computedPosition = computed(() => {
  switch (props.alignment) {
    case "start":
      return `left-0`;
    case "center":
      return `right-0 translate-x-1/2`;
    case "end":
      return `right-0`;
    default:
      return props.alignment;
  }
});
const isShowingMenu = ref(false);
const emit = defineEmits(["select"]);
const handleContentClick = () => {
  isShowingMenu.value = !isShowingMenu.value;
};
const handleMouseEnter = () => {
  if (props.isHoverTrigger === true) {
    isShowingMenu.value = true;
  }
};
const handleMouseOut = () => {
  if (props.isHoverTrigger === true) {
    isShowingMenu.value = false;
  }
};
const handleMenuItemClick = (item) => {
  isShowingMenu.value = false;
  emit("select", item);
};
</script>
