<template>
  <div
    class="flex flex-row px-4 py-3 gap-4 rounded-xl cursor-pointer dark:hover:bg-navy-500 bg-primary-200 dark:bg-transparent"
  >
    <component
      :is="props.icon"
      class="w-10 h-10 dark:text-white text-primary-700"
    />
    <div class="flex flex-col justify-between">
      <div class="text-sm font-medium dark:text-navy-50 text-navy-300">
        {{ props.topLabel }}
      </div>
      <div class="text-base font-semibold dark:text-white text-navy-800">
        {{ props.mainlabel }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["topLabel", "mainlabel", "icon"]);
</script>
