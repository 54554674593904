import DashboardIcon from "@/assets/icons/profile-sidebar/DashboardIcon.vue";
import IdentityVerificationIcon from "@/assets/icons/profile-sidebar/IdentityVerificationIcon.vue";
import SecurityIcon from "@/assets/icons/profile-sidebar/SecurityIcon.vue";
import SettingsIcon from "@/assets/icons/profile-sidebar/SettingsIcon.vue";
import AddressBookIcon from "@/assets/icons/profile-sidebar/AddressBookIcon.vue";
import LoginHistoryIcon from "@/assets/icons/profile-sidebar/LoginHistoryIcon.vue";

import ProfileDashboard from "@/views/Profile/ProfileDashboard.vue";
import ProfileIdentityVerification from "@/views/Profile/ProfileIdentityVerification.vue";
import ProfileLoginHistory from "@/views/Profile/ProfileLoginHistory.vue";
import ProfileSecurity from "@/views/Profile/ProfileSecurity.vue";
import ProfileSettings from "@/views/Profile/ProfileSettings.vue";
import ProfileAddressBook from "@/views/Profile/ProfileAddressBook.vue";
import AnnouncementIcon from "@/assets/icons/announcement/AnnouncementIcon.vue";
import AnnouncementView from "@/views/Announcement/AnnouncementView.vue";
import CalendarBlankIcon from "@/assets/icons/announcement/CalendarBlankIcon.vue";
import ListIcon from "@/assets/icons/announcement/ListIcon.vue";
import ExziLogoIcon from "@/assets/icons/announcement/ExziLogoIcon.vue";
import PackageIcon from "@/assets/icons/announcement/PackageIcon.vue";
import CirclesFourIcon from "@/assets/icons/announcement/CirclesFourIcon.vue";

export const TOAST_DURATION = 5000;
export const DEFAULT_RESEND_CODE_DURATION = 300;
export const COMPANY_NAME = "EXZi";

export const DEFAULT_PAIR_TO_SHOW = "BTCUSDT";

export const API_URL = "https://dev4-api.exzi.com/";
export const SOCKET_URL = "https://dev4-socket.exzi.com";
export const MARKET_CAP_API_KEY = "e6da2c31-6ffc-499d-8630-f36c94a0d7d0";

export const TRADING_PAIRS = [
  { id: 1, name: "BTC/USDC", value: "BTCUSDC" },
  { id: 2, name: "ETH/USDC", value: "ETHUSDC" },
  { id: 3, name: "XRP/BTC", value: "XRPBTC" },
  { id: 4, name: "SOL/USDC", value: "SOLUSDC" },
];

export const languageList = [
  {
    id: 0,
    label: "English",
  },
  {
    id: 1,
    label: "简体中文",
  },
  {
    id: 2,
    label: "Русский",
  },
  {
    id: 3,
    label: "Deutsch",
  },
  {
    id: 4,
    label: "Tiếng Việt",
  },
  {
    id: 5,
    label: "Português (Portugal)",
  },
  {
    id: 6,
    label: "Türkçe",
  },
  {
    id: 7,
    label: "Українська",
  },
];

export const TRADE_TYPES = ["Limit", "Market", "Stop Limit"];
export const TRADE_ACTION_TYPES = ["Buy", "Sell"];

export const profileSidebarItems = [
  {
    id: 1,
    name: "dashboard",
    path: "dashboard",
    label: "Dashboard",
    icon: DashboardIcon,
    content: ProfileDashboard,
  },
  {
    id: 2,
    name: "identity-verification",
    path: "identity-verification",
    label: "Identity Verification",
    icon: IdentityVerificationIcon,
    content: ProfileIdentityVerification,
  },
  {
    id: 3,
    name: "security",
    path: "security",
    label: "Security",
    icon: SecurityIcon,
    content: ProfileSecurity,
  },
  {
    id: 4,
    name: "settings",
    path: "settings",
    label: "Settings",
    icon: SettingsIcon,
    content: ProfileSettings,
  },
  {
    id: 5,
    name: "address-book",
    path: "address-book",
    label: "Address Book",
    icon: AddressBookIcon,
    content: ProfileAddressBook,
  },
  {
    id: 6,
    name: "login-history",
    path: "login-history",
    label: "Login History",
    icon: LoginHistoryIcon,
    content: ProfileLoginHistory,
  },
];

export const announcementMenuItems = [
  {
    id: 1,
    name: "announcement",
    path: "latest-announcements/:url?",
    label: "Latest Announcements",
    icon: AnnouncementIcon,
    content: AnnouncementView,
  },
  {
    id: 2,
    name: "latest-events",
    path: "latest-events/:url?",
    label: "Latest Events",
    icon: CalendarBlankIcon,
    content: AnnouncementView,
  },
  {
    id: 3,
    name: "new-listing",
    path: "new-listing/:url?",
    label: "New Listing",
    icon: ListIcon,
    content: AnnouncementView,
  },
  {
    id: 4,
    name: "latest-exzi-news",
    path: "latest-exzi-news/:url?",
    label: "Latest EXZi News",
    icon: ExziLogoIcon,
    content: AnnouncementView,
  },
  {
    id: 5,
    name: "product-updates",
    path: "product-updates/:url?",
    label: "Product Updates",
    icon: PackageIcon,
    content: AnnouncementView,
  },
  {
    id: 6,
    name: "others",
    path: "others/:url?",
    label: "Others",
    icon: CirclesFourIcon,
    content: AnnouncementView,
  },
];

export const verificationStep = [
  { id: 1, name: "choose" },
  { id: 2, name: "information" },
  { id: 3, name: "questionnaire" },
  { id: 4, name: "upload-start" },
  { id: 5, name: "upload-id" },
  { id: 5, name: "upload-passport" },
  { id: 5, name: "upload-dl" },
  { id: 5, name: "upload-face" },
];

export const BTC_ID = 2;
export const USDC_ID = 581;
export const USDT_ID = 451;
