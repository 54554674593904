const state = {
  launchpads: [],
  fake_data: {},
  project_detail_view: {},
};

const mutations = {
  SET_LAUNCHPADS(state, value) {
    state.launchpads = value;
  },
  SET_FAKE_DATA(state, value) {
    state.fake_data = value;
  },
  SET_DETAIL_VIEW(state, value) {
    state.project_detail_view = value;
  },
};

const actions = {
  async fetchLaunchpads({ commit }) {
    try {
      const response = await fetch("/mock/launchpad/live_project.json");
      const data = await response.json();
      commit("SET_LAUNCHPADS", data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchFakeData({ commit }) {
    try {
      const response = await fetch("/mock/launchpad/fake_data.json");
      const data = await response.json();
      commit("SET_FAKE_DATA", data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchProjectDetailView({ commit }) {
    try {
      const response = await fetch("/mock/launchpad/detail_view.json");
      const data = await response.json();
      commit("SET_DETAIL_VIEW", data);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  launchpads: (store) => store.launchpads,
  fakeData: (store) => store.fake_data,
  launchpadProjectDetail: (store) => store.project_detail_view,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
