<template>
  <div
    v-if="!isEmpty(allPairItems)"
    :class="['w-[410px] h-[576px] p-4 flex flex-col bg-white dark:bg-navy-600']"
  >
    <!-- Search Input -->
    <text-edit
      type="text"
      size="sm"
      rounded="full"
      placeholder="Search"
      :filled="true"
      v-model="pairInput"
    >
      <template #prefix>
        <MagnifyingGlassIcon class="w-6 h-6 mr-2 text-navy-100" />
      </template>
    </text-edit>
    <div class="h-4" />

    <UnderlineTab
      :default-selected="searchItems[1]"
      :items="searchItems"
      :bottom-line="true"
      :show-arrows="true"
      @change="handleChangeSelectedTab"
    />
    <div class="border dark:border-navy-500 border-navy-10" />
    <div class="h-4" />

    <div class="w-full h-full custom-scrollbar">
      <table class="w-full">
        <thead>
          <tr>
            <th
              v-for="item in props.displayOnlyPairs === true
                ? searchTableHeaderDisplayOnlyPairItems
                : searchTableHeaderItems"
              :key="item.id"
              class="text-xs font-medium text-navy-100 py-2 px-1"
            >
              <div
                :class="[
                  'flex space-x-1 cursor-pointer',
                  item.align === 'start' ? 'justify-start' : 'justify-end',
                ]"
                @click="handleSortWith(item.name)"
              >
                <label>{{ item.name }}</label>
                <img src="@/assets/icons/vector.svg" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in filteredPairItems"
            :key="item.name"
            class="cursor-pointer hover:bg-navy-10 dark:hover:bg-navy-500"
            @click="handleClickMenuItem(item.name)"
          >
            <td
              class="px-1 py-2 text-sm font-semibold flex items-center space-x-3"
            >
              <StarIcon
                :class="[
                  'w-4 h-4',
                  item.favorite
                    ? 'text-orange-400'
                    : 'text-navy-50 dark:text-navy-300',
                ]"
                @click="
                  (e) => {
                    e.stopPropagation();
                    if (loggedIn === false) {
                      return;
                    }
                    if (item.favorite) {
                      store.dispatch('removeFavoritePair', item.name);
                    } else {
                      store.dispatch('addFavoritePair', item.name);
                    }
                  }
                "
              />
              <img
                class="w-6 h-6 rounded-full"
                :src="item.main?.icon"
                alt="Token icon"
              />
              <p class="flex space-x-1">
                <span>{{ item.main?.iso3 }}</span>
                <span class="text-navy-100">{{ item.second?.iso3 }}</span>
              </p>
            </td>
            <td v-if="!displayOnlyPairs" class="font-medium text-sm text-right">
              ${{ item.price }}
            </td>
            <td
              v-if="!displayOnlyPairs"
              :class="[
                'font-medium text-sm text-right',
                item.isMinus ? 'text-red-500' : 'text-green-500',
              ]"
            >
              {{ item.percent }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import { StarIcon } from "@heroicons/vue/24/solid";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";

const store = useStore();

const searchItems = [
  { id: 1, name: "Favorites" },
  { id: 2, name: "All" },
  { id: 3, name: "USDC" },
];

const searchTableHeaderItems = [
  { id: 1, name: "Pair", align: "start" },
  { id: 2, name: "Price", align: "end" },
  { id: 3, name: "Change", align: "end" },
];

const searchTableHeaderDisplayOnlyPairItems = [
  { id: 1, name: "Pair", align: "start" },
];

const pairInput = ref("");
const sortInput = ref({
  name: "Price",
  desc: true,
});
const groupInput = ref("All");
const props = defineProps(["allPairItems", "displayOnlyPairs", "class"]);
const emit = defineEmits(["clickItem"]);

const loggedIn = computed(() => store.getters.isLoggedIn);

const filteredPairItems = computed(() => {
  let filteredItems = props.allPairItems;
  switch (groupInput.value) {
    case "Favorites":
      filteredItems = filteredItems.filter((item) => item.favorite);
      break;
    case "All":
      break;
    default:
      filteredItems = filteredItems.filter(
        (item) => item.second.iso3 === groupInput.value
      );
      break;
  }
  return filteredItems
    ?.filter(
      (item) =>
        item.main.iso3.includes(pairInput.value?.toUpperCase()) ||
        item.second.iso3.includes(pairInput.value?.toUpperCase())
    )
    .sort((itemA, itemB) => {
      switch (sortInput.value.name) {
        case "Pair":
          return sortInput.value.desc
            ? itemA.main.iso3.localeCompare(itemB.main.iso3)
            : itemB.main.iso3.localeCompare(itemA.main.iso3);
        case "Price":
          return sortInput.value.desc
            ? itemA.actualPrice - itemB.actualPrice
            : itemB.actualPrice - itemA.actualPrice;
        case "Change":
          return sortInput.value.desc
            ? itemA.actualPercent - itemB.actualPercent
            : itemB.actualPercent - itemA.actualPercent;
      }
    });
});

const handleChangeSelectedTab = (item) => {
  groupInput.value = item.name;
};
const handleSortWith = (name) => {
  if (name === sortInput.value.name) {
    sortInput.value.desc = !sortInput.value.desc;
  } else {
    sortInput.value = {
      name,
      desc: false,
    };
  }
};
const handleClickMenuItem = (name) => {
  emit("clickItem", name);
};
</script>
