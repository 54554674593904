<template>
  <div class="w-full relative">
    <div class="absolute w-full -z-50 left-0 top-0">
      <img
        alt=""
        class="w-full"
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/announcement/announcement_background_dark.svg'
            : '/assets/images/announcement/announcement_background_light.svg',
        ]"
      />
    </div>
    <div class="flex flex-col container w-full mx-auto px-2 sm:px-4">
      <div
        class="flex flex-col md:flex-row gap-8 w-full justify-between mb-6 sm:mb-24 mt-10 sm:mt-16"
      >
        <div class="flex flex-col max-w-[769px]">
          <div
            class="dark:text-white text-navy-800 text-[40px] font-bold sm:text-[56px] max-sm:text-center"
          >
            Announcements
          </div>
          <div
            class="mt-3 sm:mt-6 text-lg font-medium sm:text-xl sm:font-normal dark:text-navy-50 text-navy-400 max-sm:text-center"
          >
            Stay tuned on EXZi's latest promotions, including exclusive token
            listings and innovative product features for a next-level trading
            advantage.
          </div>
        </div>
        <div class="sm:w-72 w-full shrink-0">
          <text-edit
            type="text"
            size="sm"
            rounded="full"
            placeholder="Search"
            :filled="true"
            v-model="searchText"
          >
            <template #prefix>
              <div class="mr-2">
                <MagnifyingGlassIcon
                  :class="[
                    searchText.length > 0
                      ? 'dark:text-white text-navy-800'
                      : 'text-navy-200',
                    'w-6 h-6',
                  ]"
                />
              </div>
            </template>
            <template #suffix v-if="searchText.length > 0">
              <div
                class="ml-2"
                @click="
                  () => {
                    searchText = '';
                  }
                "
              >
                <XCircleIcon class="text-navy-200 stroke-2 w-6 h-6" />
              </div>
            </template>
          </text-edit>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, defineProps } from "vue";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/vue/24/outline";
const searchText = ref("");
const props = defineProps(["handleChange"]);
watch(
  () => searchText.value,
  (newValue) => {
    props.handleChange(newValue);
  }
);
</script>
