<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6488 19.8736C20.221 17.4052 18.0207 15.6352 15.4529 14.7961C16.723 14.04 17.7099 12.8878 18.2618 11.5166C18.8138 10.1453 18.9004 8.63077 18.5083 7.20554C18.1161 5.78031 17.267 4.5232 16.0913 3.62726C14.9156 2.73132 13.4783 2.24609 12.0001 2.24609C10.5219 2.24609 9.08457 2.73132 7.90884 3.62726C6.73312 4.5232 5.884 5.78031 5.49189 7.20554C5.09978 8.63077 5.18635 10.1453 5.73831 11.5166C6.29027 12.8878 7.2771 14.04 8.54726 14.7961C5.97945 15.6343 3.77913 17.4043 2.35132 19.8736C2.29896 19.959 2.26423 20.054 2.24918 20.153C2.23413 20.2521 2.23906 20.3531 2.26369 20.4502C2.28831 20.5472 2.33213 20.6384 2.39256 20.7183C2.45299 20.7981 2.52881 20.8651 2.61553 20.9152C2.70226 20.9653 2.79814 20.9975 2.89752 21.0099C2.99689 21.0224 3.09776 21.0148 3.19415 20.9876C3.29055 20.9604 3.38053 20.9142 3.45878 20.8517C3.53703 20.7892 3.60197 20.7117 3.64976 20.6236C5.41601 17.5711 8.53788 15.7486 12.0001 15.7486C15.4623 15.7486 18.5841 17.5711 20.3504 20.6236C20.3982 20.7117 20.4631 20.7892 20.5414 20.8517C20.6196 20.9142 20.7096 20.9604 20.806 20.9876C20.9024 21.0148 21.0032 21.0224 21.1026 21.0099C21.202 20.9975 21.2979 20.9653 21.3846 20.9152C21.4713 20.8651 21.5471 20.7981 21.6076 20.7183C21.668 20.6384 21.7118 20.5472 21.7365 20.4502C21.7611 20.3531 21.766 20.2521 21.751 20.153C21.7359 20.054 21.7012 19.959 21.6488 19.8736ZM6.75007 8.99864C6.75007 7.96029 7.05798 6.94526 7.63486 6.0819C8.21173 5.21854 9.03167 4.54564 9.99098 4.14828C10.9503 3.75092 12.0059 3.64695 13.0243 3.84952C14.0427 4.05209 14.9782 4.55211 15.7124 5.28633C16.4466 6.02056 16.9466 6.95602 17.1492 7.97442C17.3518 8.99282 17.2478 10.0484 16.8504 11.0077C16.4531 11.967 15.7802 12.787 14.9168 13.3639C14.0535 13.9407 13.0384 14.2486 12.0001 14.2486C10.6081 14.2472 9.27365 13.6936 8.2894 12.7093C7.30516 11.7251 6.75156 10.3906 6.75007 8.99864Z"
      fill="currentColor"
    />
  </svg>
</template>
