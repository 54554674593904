const state = {
  currentSelectedPrice: 0,
};

const mutations = {
  SET_CURRENT_SELECTED_PRICE(state, price) {
    state.currentSelectedPrice = price;
  },
};

const actions = {
  setCurrentSelectedPrice({ commit }, price) {
    commit("SET_CURRENT_SELECTED_PRICE", price);
  },
};

const getters = {
  currentSelectedPrice: (state) => state.currentSelectedPrice,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
