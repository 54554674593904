<template>
  <div class="flex flex-row items-center gap-4">
    <div
      v-for="item in items"
      :key="item.id"
      :class="[
        'px-4 py-2.5 text-sm font-semibold rounded-full cursor-pointer shrink-0',
        item.id === selectedItemId
          ? 'bg-navy-50 text-navy-800 dark:bg-navy-400 dark:text-white'
          : 'dark:bg-navy-600 dark:text-navy-50 bg-navy-10 text-navy-300',
      ]"
      @click="handleClick(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref } from "vue";
const props = defineProps(["items"]);
const emit = defineEmits(["change"]);

const selectedItemId = ref(props.items[0].id);

const handleClick = (item) => {
  selectedItemId.value = item.id;
  emit("change", item.id);
};
</script>
