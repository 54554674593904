<template>
  <div
    :class="[
      isChecked
        ? 'bg-primary-700 text-white'
        : 'bg-transparent border border-navy-100',
      'rounded-[5px] cursor-pointer w-full h-full flex justify-center items-center',
    ]"
    @click="handleClick"
  >
    <CheckIcon v-if="isChecked" class="w-4 h-4 stroke-2" />
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { CheckIcon } from "@heroicons/vue/24/outline";

const props = defineProps(["defaultValue"]);
const emit = defineEmits(["change"]);
const isChecked = ref(props.defaultValue);

const handleClick = () => {
  isChecked.value = !isChecked.value;
  emit("change", isChecked.value);
};
</script>
