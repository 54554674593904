<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 3.75V7.5C17.5 7.66576 17.4342 7.82473 17.3169 7.94194C17.1997 8.05915 17.0408 8.125 16.875 8.125H13.125C12.9592 8.125 12.8003 8.05915 12.6831 7.94194C12.5658 7.82473 12.5 7.66576 12.5 7.5C12.5 7.33424 12.5658 7.17526 12.6831 7.05805C12.8003 6.94084 12.9592 6.875 13.125 6.875H15.3664L14.2234 5.73203C13.0619 4.56516 11.4847 3.90683 9.83828 3.90156H9.80313C8.17045 3.89773 6.60189 4.53677 5.43672 5.68046C5.31736 5.79189 5.15914 5.85218 4.99589 5.84843C4.83265 5.84469 4.67736 5.77721 4.56323 5.66043C4.44911 5.54365 4.38522 5.38685 4.38523 5.22356C4.38524 5.06028 4.44914 4.90348 4.56328 4.78672C5.97541 3.40656 7.87486 2.63896 9.8494 2.65052C11.8239 2.66207 13.7143 3.45184 15.1102 4.84843L16.25 5.9914V3.75C16.25 3.58424 16.3158 3.42526 16.4331 3.30805C16.5503 3.19084 16.7092 3.125 16.875 3.125C17.0408 3.125 17.1997 3.19084 17.3169 3.30805C17.4342 3.42526 17.5 3.58424 17.5 3.75ZM14.5633 14.3195C13.3866 15.4689 11.8042 16.1081 10.1593 16.0985C8.51445 16.0888 6.93968 15.4311 5.77656 14.268L4.63359 13.125H6.875C7.04076 13.125 7.19973 13.0591 7.31694 12.9419C7.43415 12.8247 7.5 12.6658 7.5 12.5C7.5 12.3342 7.43415 12.1753 7.31694 12.0581C7.19973 11.9408 7.04076 11.875 6.875 11.875H3.125C2.95924 11.875 2.80027 11.9408 2.68306 12.0581C2.56585 12.1753 2.5 12.3342 2.5 12.5V16.25C2.5 16.4158 2.56585 16.5747 2.68306 16.6919C2.80027 16.8091 2.95924 16.875 3.125 16.875C3.29076 16.875 3.44973 16.8091 3.56694 16.6919C3.68415 16.5747 3.75 16.4158 3.75 16.25V14.0086L4.89297 15.1516C6.28688 16.5525 8.18 17.3427 10.1562 17.3484H10.1977C12.1571 17.3535 14.0396 16.5864 15.4375 15.2133C15.5516 15.0965 15.6155 14.9397 15.6156 14.7764C15.6156 14.6131 15.5517 14.4563 15.4375 14.3396C15.3234 14.2228 15.1681 14.1553 15.0049 14.1516C14.8416 14.1478 14.6834 14.2081 14.5641 14.3195H14.5633Z"
      fill="currentColor"
    />
    <rect
      x="10.1516"
      y="7.5"
      width="3.75"
      height="3.75"
      rx="0.625"
      transform="rotate(45 10.1516 7.5)"
      fill="currentColor"
    />
  </svg>
</template>
