<template>
  <div>
    <div
      class="bg-navy-10 dark:bg-white max-w-[256px] max-h-[256px] p-5 rounded-3xl m-auto sm:m-0"
    >
      <img
        class="m-auto"
        src="@/assets/images/auth/qr_code.svg"
        alt="QR Code"
      />
    </div>

    <div class="sm:h-6 h-4" />

    <div class="sm:justify-start flex justify-center">
      <p
        class="text-black dark:text-navy-100 sm:max-w-[400px] max-w-[300px] sm:text-left text-center"
      >
        Scan the code to log in Open EXZi App and use the scan function on the
        upper right corner.
      </p>
    </div>
  </div>
</template>
