<template>
  <div class="flex flex-col w-full container mx-auto">
    <div class="relative mt-8 sm:mt-14 mb-14 sm:mb-0">
      <div class="absolute w-full -z-50">
        <img
          :src="[
            currentTheme === 'dark'
              ? 'assets/images/convert/back_texture.svg'
              : 'assets/images/convert/back_texture_light.svg',
          ]"
          class="mx-auto"
        />
      </div>
      <div class="absolute w-full -z-50">
        <img
          :src="[
            currentTheme === 'dark'
              ? 'assets/images/convert/ellipse.png'
              : 'assets/images/convert/ellipse_light.svg',
          ]"
          class="mx-auto"
        />
      </div>
      <div class="w-full">
        <div class="mt-6 sm:mt-14 w-full">
          <div class="flex flex-col items-center mx-auto max-w-[640px]">
            <div
              class="text-[40px] font-bold sm:text-[56px] dark:text-white text-navy-800 mb-8 sm:mb-12"
            >
              Convert
            </div>
            <div class="flex flex-col items-center w-full px-4">
              <div class="w-full">
                <TokenSelect
                  v-model="mainTokenAmount"
                  :currencies="fromTickerSearchableCurrency"
                  :disabled="false"
                  :selected-token="fromTicker"
                  label="From"
                  v-on:token-change="handleFromTokenChange"
                />
              </div>
              <div class="my-2 relative">
                <div
                  class="absolute left-[50%] -translate-x-1/2 -translate-y-1/2 z-10"
                >
                  <div
                    class="w-14 h-14 flex items-center justify-center bg-primary-500 rounded-full cursor-pointer"
                    @click="handleSwapToken"
                  >
                    <img
                      class="w-6 h-6"
                      src="/assets/icons/transfer_icon.svg"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full">
                <TokenSelect
                  v-model="secondTokenAmount"
                  :currencies="toTickerSearchableCurrency"
                  :disabled="true"
                  :selected-token="toTicker"
                  label="To"
                  @token-change="handleToTokenChange"
                />
              </div>
            </div>
            <div class="mt-3.5 sm:mt-4">
              <div
                class="dark:bg-navy-700 bg-primary-300 rounded-full px-3 py-1.5 font-semibold w-fit text-primary-800 dark:text-white text-sm"
              >
                {{
                  `1 ${toTicker?.iso3} = ${formatNumberWithGroupedDecimals(
                    tokenPrice
                  )} ${fromTicker?.iso3}`
                }}
              </div>
            </div>
            <div class="mt-8 w-full px-4">
              <fill-button size="lg" width="full" @click="handlePreviewClick"
                >See preview
              </fill-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto w-full mb-16 sm:mb-44 sm:mt-52 mt-16">
      <div class="flex flex-col mb-10 px-4 w-full">
        <div
          class="dark:text-white font-bold text-[32px] sm:text-5xl mb-4 text-navy-800"
        >
          FAQ
        </div>
        <FAQView :faq-data="convertPageFqaData" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { formatNumberWithGroupedDecimals, isEmpty } from "@/utils/utils";
import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import FAQView from "../../common/FAQ/FAQComponent.vue";
import TokenSelect from "./TokenSelect.vue";
import { useModal } from "vue-final-modal";
import ConfirmConvertModal from "./PreviewConvertModal.vue";
import ConvertSuccessModal from "./ConvertSuccessModal.vue";
import axios from "@/api/axios";
import { BTC_ID, USDT_ID } from "@/constants/constants";

const store = useStore();
const selectedFromTicker = ref(USDT_ID);
const selectedToTicker = ref(BTC_ID);
const mainTokenAmount = ref(0);
const secondTokenAmount = ref(0);

const activeCurrencies = computed(() => store.getters.activeCurrencies);
const balancesVolume = computed(() => store.getters.balancesVolume);
const allPairs = computed(() => store.getters.allPairs);

const getBalanceForToken = (token) => {
  if (isEmpty(balancesVolume?.value?.list) || isEmpty(activeCurrencies.value))
    return 0;
  let balance = balancesVolume.value.list.find(
    ({ currency, deposit_type }) =>
      currency.id === token && deposit_type === "spot"
  );
  return (
    balance?.balance_available /
    Math.pow(10, activeCurrencies.value[token].decimal)
  );
};

const searchableCurrencies = computed(() => {
  if (isEmpty(activeCurrencies.value)) {
    return [];
  }

  return Object.values(activeCurrencies.value).map((item) => ({
    icon: item.icon,
    iso3: item.iso3,
    name: item.name,
    id: item.id,
    balance: getBalanceForToken(item.id),
  }));
});

const getTokenPrice = (first, second) => {
  if (isEmpty(first) || isEmpty(second)) {
    return 0;
  }
  const pair = allPairs.value.find(
    (pair) =>
      pair.name === `${first.iso3}${second.iso3}` ||
      pair.name === `${second.iso3}${first.iso3}`
  );
  if (isEmpty(pair)) return 0;
  if (pair.main.iso3 === second.iso3) {
    return pair.rate_f;
  } else {
    return 1.0 / pair.rate_f;
  }
};

const getPairRate = (first, second) => {
  if (isEmpty(first) || isEmpty(second)) {
    return 0;
  }
  const pair = allPairs.value.find(
    (pair) =>
      pair.name === `${first.iso3}${second.iso3}` ||
      pair.name === `${second.iso3}${first.iso3}`
  );
  if (isEmpty(pair)) return 0;
  return pair.rate_f;
};

const getPair = (first, second) => {
  if (isEmpty(first) || isEmpty(second)) {
    return 0;
  }
  const pair = allPairs.value.find(
    (pair) =>
      pair.name === `${first.iso3}${second.iso3}` ||
      pair.name === `${second.iso3}${first.iso3}`
  );
  if (isEmpty(pair)) return null;
  if (pair.name === `${first.iso3}${second.iso3}`) {
    return { pair, type: 1 };
  }
  return { pair, type: 0 };
};

const tokenPrice = computed(() => {
  return getTokenPrice(fromTicker.value, toTicker.value);
});

const fromTicker = computed(() => {
  return searchableCurrencies.value.find(
    (item) => item.id == selectedFromTicker.value
  );
});

const toTicker = computed(() => {
  return searchableCurrencies.value.find(
    (item) => item.id === selectedToTicker.value
  );
});

const fromTickerSearchableCurrency = computed(() =>
  searchableCurrencies.value.filter(
    (item) => item.id !== selectedToTicker.value
  )
);

const toTickerSearchableCurrency = computed(() =>
  searchableCurrencies.value.filter(
    (item) => item.id !== selectedFromTicker.value
  )
);

const handleSwapToken = () => {
  [selectedFromTicker.value, selectedToTicker.value] = [
    selectedToTicker.value,
    selectedFromTicker.value,
  ];
};

const handleFromTokenChange = (tokenId) => {
  selectedFromTicker.value = tokenId;
};

const handleToTokenChange = (tokenId) => {
  selectedToTicker.value = tokenId;
};

const handlePreviewClick = () => {
  open();
};

watchEffect(() => {
  secondTokenAmount.value = mainTokenAmount.value * (1 / tokenPrice.value);
});

const nextToSuccess = () => {
  performConvertAction();
};

const performConvertAction = async () => {
  try {
    const { pair: currentPair, type } = getPair(
      fromTicker.value,
      toTicker.value
    );
    const { data } = await axios.post("/api/p2p/create", {
      type_trade: 1,
      type,
      currency_pair_id: currentPair.id,
      volume: `${(type === 1
        ? parseFloat(mainTokenAmount.value)
        : mainTokenAmount.value / tokenPrice.value
      ).toFixed(4)}0000`,
      stop_rate: (0).toFixed(8),
      rate: parseFloat(getPairRate.value).toFixed(8),
      total: parseFloat(mainTokenAmount.value).toFixed(8),
      max: (type === 1
        ? fromTicker.value.balance * tokenPrice.value
        : fromTicker.value.balance / tokenPrice.value
      ).toFixed(8),
      cost: parseFloat(mainTokenAmount.value).toFixed(8),
    });
    if (data.status) {
      successOpen();
    } else {
      store.dispatch("setToast", {
        type: "error",
        message: `${Object.keys(data.errors)[0]} : ${
          data.errors[Object.keys(data.errors)[0]]
        }`,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const { open: successOpen, close: successClose } = useModal({
  component: ConvertSuccessModal,
  attrs: {
    onConfirm() {
      successClose();
    },
    fromToken: fromTicker,
    toToken: toTicker,
    fromTokenAmount: mainTokenAmount,
    price: tokenPrice,
    onViewStatusClicked() {},
    onGoTradeClicked() {},
  },
});

const { open, close } = useModal({
  component: ConfirmConvertModal,
  attrs: {
    fromToken: fromTicker,
    toToken: toTicker,
    fromTokenAmount: mainTokenAmount,
    price: tokenPrice,
    onClose() {
      close();
    },
    onConvertClicked() {
      close();
      nextToSuccess();
    },
  },
});

const convertPageFqaData = [
  {
    id: 0,
    number: "01",
    title: "Will I be charged a trading fee?",
    content:
      "Lorem ipsum odor amet, consectetuer adipiscing elit. Ultricies maecenas risus eu sodales fermentum quam neque. Ultrices ridiculus vel, etiam ad ornare imperdiet. Commodo accumsan praesent penatibus potenti curae est vestibulum.",
  },
  {
    id: 1,
    number: "02",
    title: "What advantages does Convert have compared to spot trading?",
    content:
      "Lorem ipsum odor amet, consectetuer adipiscing elit. Ultricies maecenas risus eu sodales fermentum quam neque. Ultrices ridiculus vel, etiam ad ornare imperdiet. Commodo accumsan praesent penatibus potenti curae est vestibulum.",
  },
  {
    id: 2,
    number: "03",
    title: "Are there any restrictions to the use of Convert?",
    content:
      "Lorem ipsum odor amet, consectetuer adipiscing elit. Ultricies maecenas risus eu sodales fermentum quam neque. Ultrices ridiculus vel, etiam ad ornare imperdiet. Commodo accumsan praesent penatibus potenti curae est vestibulum.",
  },
  {
    id: 3,
    number: "04",
    title: "How are transactions settled?",
    content:
      "Lorem ipsum odor amet, consectetuer adipiscing elit. Ultricies maecenas risus eu sodales fermentum quam neque. Ultrices ridiculus vel, etiam ad ornare imperdiet. Commodo accumsan praesent penatibus potenti curae est vestibulum.",
  },
];
</script>
