<template>
  <div
    class="w-full rounded-xl border dark:border-navy-400 border-navy-50 flex flex-row items-center justify-stretch px-3 py-2.5"
  >
    <CalendarIcon class="w-5 h-5 dark:text-navy-100 text-navy-800 mr-2" />
    <div class="text-sm font-medium dark:text-navy-100 text-navy-800">
      {{ formatDate(selectedDate) }}
    </div>
    <div class="grow" />
    <div class="shrink-0">
      <VueDatePicker
        v-model="selectedDate"
        :dark="currentTheme === 'dark'"
        @open="pickerOpened = true"
        @closed="pickerOpened = false"
      >
        <template #trigger>
          <div class="cursor-pointer">
            <ChevronUpIcon
              v-if="pickerOpened"
              class="w-4 h-4 dark:text-white text-navy-800 stroke-2"
            />
            <ChevronDownIcon
              v-else
              class="w-4 h-4 dark:text-white text-navy-800 stroke-2"
            />
          </div>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>
<script setup>
import CalendarIcon from "@/assets/icons/common/CalendarIcon.vue";
import { formatDate } from "@/utils/utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import { defineEmits, ref, defineProps, watch } from "vue";

const props = defineProps(["defaultDate"]);
const emit = defineEmits(["change"]);
const selectedDate = ref(props.defaultDate ?? new Date());
const pickerOpened = ref(false);

watch(
  () => selectedDate.value,
  (newValue) => {
    emit("change", newValue);
  }
);
</script>
