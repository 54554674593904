<template>
  <div class="mt-10 sm:mt-16 flex flex-col sm:px-16 self-center px-4">
    <div
      class="text-lg font-medium mb-8 text-center dark:text-navy-100 text-navy-200"
    >
      Partnered with trusted names in digital assets
    </div>
    <div
      class="flex flex-row flex-wrap gap-x-[52px] gap-y-6 items-center justify-center sm:px-28"
    >
      <div
        v-for="item in currentTheme === 'dark'
          ? homepageLogoUrls
          : homepageLogoLightUrls"
        :key="item.id"
      >
        <img :src="item.url" />
      </div>
    </div>
  </div>
</template>
<script setup>
const homepageLogoUrls = [
  {
    id: 0,
    url: "/assets/images/home/fireblocks_logo.svg",
  },
  {
    id: 1,
    url: "/assets/images/home/aquanow_logo.svg",
  },
  {
    id: 2,
    url: "/assets/images/home/ondato_logo.svg",
  },
  {
    id: 3,
    url: "/assets/images/home/onesignal_logo.svg",
  },
  {
    id: 4,
    url: "/assets/images/home/hawk_logo.svg",
  },
  {
    id: 5,
    url: "/assets/images/home/scorechain_logo.svg",
  },
  {
    id: 6,
    url: "/assets/images/home/twilio_logo.svg",
  },
  {
    id: 7,
    url: "/assets/images/home/tradingview_logo.svg",
  },
];

const homepageLogoLightUrls = [
  {
    id: 0,
    url: "/assets/images/home/fireblocks_logo_light.svg",
  },
  {
    id: 1,
    url: "/assets/images/home/aquanow_logo_light.svg",
  },
  {
    id: 2,
    url: "/assets/images/home/ondato_logo_light.svg",
  },
  {
    id: 3,
    url: "/assets/images/home/onesignal_logo_light.svg",
  },
  {
    id: 4,
    url: "/assets/images/home/hawk_logo_light.svg",
  },
  {
    id: 5,
    url: "/assets/images/home/scorechain_logo_light.svg",
  },
  {
    id: 6,
    url: "/assets/images/home/twilio_logo_light.svg",
  },
  {
    id: 7,
    url: "/assets/images/home/tradingview_logo_light.svg",
  },
];
</script>
