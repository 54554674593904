<template>
  <div
    class="relative flex flex-row p-6 dark:bg-navy-700 bg-white dark:border-none border border-primary-300 rounded-2xl items-center justify-between min-h-[138px] cursor-pointer w-full"
    @click="handleClick"
  >
    <div
      v-if="props.selected === true"
      class="absolute top-3.5 right-3.5 w-6 h-6 rounded-full bg-primary-700 flex items-center justify-center"
    >
      <CheckIcon class="w-4 h-4 stroke-2 text-white" />
    </div>
    <div class="flex flex-col">
      <div class="flex flex-row items-center justify-start">
        <img :src="props.imgUrl" class="w-8 h-8 mr-4" />
        <div class="text-navy-800 font-bold text-base dark:text-white mr-1">
          {{ props.token1 }}
        </div>
        <div class="dark:text-navy-200 text-navy-300 text-sm font-medium">
          /{{ props.token2 }}
        </div>
      </div>
      <div class="mt-4 text-green-400 text-base font-bold">
        {{ addCommasToNumber(props.price, -2) }}
      </div>
      <div class="dark:text-navy-200 text-navy-300 text-xs font-medium">
        {{
          `${props.percent > 0 ? "+" : ""}${addCommasToNumber(
            props.percent,
            -2
          )}%`
        }}
      </div>
    </div>
    <div class="w-[104px] h-[90px]" />
  </div>
</template>
<script setup>
import { addCommasToNumber } from "@/utils/NumberFormating";
import { CheckIcon } from "@heroicons/vue/24/outline";
import { defineProps, defineEmits } from "vue";
const props = defineProps([
  "imgUrl",
  "token1",
  "token2",
  "price",
  "percent",
  "selected",
  "id",
]);
const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click", props.id);
};
</script>
