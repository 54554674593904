import chartSettingsManager from "@/utils/ChartSettingsManager";

const state = {
  chart_confirmation: chartSettingsManager.getOrderConfirmation(),
  chart_basis: chartSettingsManager.getChartBasis(),
  chart_alerts: chartSettingsManager.getChartAlerts(),
  chart_notification: chartSettingsManager.getChartNotification(),
  chart_trading_panel: chartSettingsManager.getChartTradingPanel(),
};

const mutations = {
  SET_CHART_TRADING_PANEL(state, panel) {
    state.chart_trading_panel = panel;
  },
  SET_CHART_BASIS(state, basis) {
    state.chart_basis = basis;
  },
};

const actions = {
  setChartTradingPanel({ commit }, panel) {
    commit("SET_CHART_TRADING_PANEL", panel);
    chartSettingsManager.setChartTradingPanel(panel);
  },
  setChartBasis({ commit }, basis) {
    commit("SET_CHART_BASIS", basis);
    chartSettingsManager.setChartBasis(basis);
  },
};

const getters = {
  chartTradingPanel: (state) => state.chart_trading_panel,
  chartBasis: (state) => state.chart_basis,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
