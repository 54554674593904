import session_manager from "@/utils/SessionManager";

const state = {
  theme: session_manager.getTheme(),
};

const setBackgroundStyle = (theme) => {
  if (theme === "light") {
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.add("light");
  } else {
    document.documentElement.classList.remove("light");
    document.documentElement.classList.add("dark");
  }
};

setBackgroundStyle(state.theme);

const mutations = {
  SET_THEME(state, theme) {
    state.theme = theme;
  },
};

const actions = {
  toggleTheme({ commit }, theme) {
    const currentTheme = theme === "dark" ? "light" : "dark";
    commit("SET_THEME", currentTheme);
    session_manager.setTheme(currentTheme);
    setBackgroundStyle(currentTheme);
  },
};

const getters = {
  currentTheme: (state) => state.theme,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
