<template>
  <VueFinalModal
    class="confirm-modal"
    content-class="rounded-3xl dark:border-navy-400 dark:border max-w-[584px] w-full bg-white dark:bg-navy-800 mx-4"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    :click-to-close="false"
    :esc-to-close="false"
  >
    <div class="w-full">
      <div class="flex flex-col">
        <div
          class="flex flex-col pt-3 pb-4 sm:py-4 sm:px-6 w-full px-4 rounded-t-3xl"
          :style="[
            currentTheme === 'light'
              ? `background-image: url('assets/images/convert/modal_green_gradient.svg');`
              : `background-image: url('assets/images/convert/modal_green_gradient_dark.svg');`,
          ]"
        >
          <div class="flex flex-row justify-center items-center w-full mb-4">
            <div class="flex-grow"></div>
            <div
              class="dark:text-navy-100 text-navy-200"
              @click="
                () => {
                  emit('confirm');
                }
              "
            >
              <XMarkIcon class="stroke-2 sm:w-6 sm:h-6 w-5 h-5" />
            </div>
          </div>
          <div class="flex flex-col sm:mx-[70px] mx-4 max-sm:py-2 items-center">
            <div
              class="relative sm:w-[120px] sm:h-[120px] w-24 h-24 dark:bg-green-800 bg-[#40B591] rounded-full"
            >
              <div
                class="absolute sm:w-[100px] sm:h-[100px] w-20 h-20 dark:bg-green-700 bg-[#009C6C] rounded-full sm:top-[10px] sm:left-[10px] top-2 left-2"
              >
                <div
                  class="sm:w-[80px] sm:h-[80px] w-16 h-16 absolute dark:bg-green-500 bg-[#009C6C] rounded-full sm:top-[10px] sm:left-[10px] top-2 left-2"
                >
                  <CheckIcon
                    class="stroke-2 w-14 h-14 absolute top-0 left-0 bottom-0 right-0 mx-auto my-auto text-white rounded-full"
                  />
                </div>
              </div>
            </div>
            <div
              class="dark:text-white text-green-900 text-base font-semibold text-center mt-4"
            >
              Conversion Successful
            </div>
            <div
              class="sm:mt-4 text-sm font-medium dark:text-navy-50 text-green-100"
            >
              Your transaction price
            </div>
            <div class="dark:text-white text-xl font-semibold text-green-900">
              {{
                `${formatNumber(props.fromTokenAmount)} ${props.fromToken.iso3}`
              }}
            </div>
          </div>
        </div>
        <div class="flex flex-col dark:bg-navy-500 p-6 bg-white rounded-b-3xl">
          <div class="flex flex-col gap-y-3 sm:mb-12 mb-9">
            <div class="flex flex-row items-center justify-between">
              <div class="text-sm font-medium text-navy-100">Price</div>
              <div class="text-sm font-medium dark:text-white text-navy-800">
                {{
                  `1 ${props.fromToken.iso3} = ${formatNumber(
                    1 / props.price
                  )} ${props.toToken.iso3}`
                }}
              </div>
            </div>
            <div class="flex flex-row items-center justify-between">
              <div class="text-sm font-medium text-navy-100">Pay from</div>
              <div class="text-sm font-medium dark:text-white text-navy-800">
                Trading wallet
              </div>
            </div>
            <div class="flex flex-row items-center justify-between">
              <div class="text-sm font-medium text-navy-100">
                You will receive
              </div>
              <div class="text-sm font-medium dark:text-white text-navy-800">
                {{ `${formatNumber(toTokenAmount)} ${props.toToken.iso3}` }}
              </div>
            </div>
          </div>
          <div class="flex flex-row w-full gap-x-6">
            <outline-button size="lg" width="full">Go Trade</outline-button>
            <fill-button size="lg" width="full">View Status</fill-button>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>
<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { VueFinalModal } from "vue-final-modal";
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { formatNumber } from "@/utils/utils";

const props = defineProps(["fromToken", "fromTokenAmount", "toToken", "price"]);
const emit = defineEmits(["viewStatusClicked", "goTradeClicked", "confirm"]);

const toTokenAmount = computed(() => {
  return props.fromTokenAmount * (1 / props.price);
});
</script>
<style>
.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
