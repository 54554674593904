<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6431 7.17741L11.8306 9.60429L12.6875 13.2337C12.7347 13.4307 12.7226 13.6373 12.6525 13.8274C12.5824 14.0175 12.4575 14.1826 12.2937 14.3018C12.1298 14.4209 11.9343 14.4889 11.7319 14.497C11.5294 14.5051 11.3291 14.4531 11.1562 14.3474L7.99996 12.4049L4.84184 14.3474C4.66898 14.4525 4.4689 14.504 4.2668 14.4956C4.06469 14.4872 3.8696 14.4191 3.70609 14.3001C3.54257 14.181 3.41795 14.0162 3.3479 13.8264C3.27786 13.6367 3.26553 13.4304 3.31246 13.2337L4.17246 9.60429L1.35996 7.17741C1.20702 7.04523 1.09641 6.87092 1.04195 6.67625C0.987486 6.48159 0.99158 6.27519 1.05372 6.08283C1.11586 5.89048 1.23329 5.72069 1.39135 5.59468C1.54941 5.46867 1.7411 5.39201 1.94246 5.37429L5.62996 5.07679L7.05246 1.63429C7.12946 1.44667 7.26051 1.28619 7.42894 1.17325C7.59738 1.06031 7.7956 1 7.9984 1C8.2012 1 8.39942 1.06031 8.56785 1.17325C8.73629 1.28619 8.86734 1.44667 8.94434 1.63429L10.3662 5.07679L14.0537 5.37429C14.2555 5.39135 14.4477 5.46758 14.6064 5.59342C14.765 5.71925 14.883 5.88911 14.9455 6.0817C15.008 6.27429 15.0123 6.48104 14.9579 6.67607C14.9034 6.87109 14.7926 7.04571 14.6393 7.17804L14.6431 7.17741Z"
      fill="currentColor"
    />
  </svg>
</template>
