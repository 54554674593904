<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 3.47059V20.5294C22.5 20.9194 22.345 21.2935 22.0692 21.5693C21.7935 21.8451 21.4194 22 21.0294 22H3.97059C3.58056 22 3.20651 21.8451 2.93072 21.5693C2.65494 21.2935 2.5 20.9194 2.5 20.5294V3.47059C2.5 3.08056 2.65494 2.70651 2.93072 2.43073C3.20651 2.15494 3.58056 2 3.97059 2H21.0294C21.4194 2 21.7935 2.15494 22.0692 2.43073C22.345 2.70651 22.5 3.08056 22.5 3.47059ZM8.38235 9.64706H5.44118V19.0588H8.38235V9.64706ZM8.64706 6.41176C8.64861 6.18929 8.60632 5.96869 8.52261 5.76255C8.43891 5.55642 8.31542 5.36879 8.15919 5.21039C8.00297 5.05198 7.81708 4.92589 7.61213 4.83933C7.40718 4.75277 7.18718 4.70742 6.96471 4.70588H6.91176C6.45934 4.70588 6.02544 4.88561 5.70552 5.20552C5.38561 5.52544 5.20588 5.95934 5.20588 6.41176C5.20588 6.86419 5.38561 7.29809 5.70552 7.61801C6.02544 7.93792 6.45934 8.11765 6.91176 8.11765C7.13426 8.12312 7.35565 8.0847 7.56328 8.00458C7.77092 7.92447 7.96074 7.80422 8.12189 7.65072C8.28304 7.49722 8.41237 7.31346 8.50248 7.10996C8.59259 6.90646 8.64172 6.6872 8.64706 6.46471V6.41176ZM19.5588 13.3412C19.5588 10.5118 17.7588 9.41177 15.9706 9.41177C15.3851 9.38245 14.8021 9.50715 14.2798 9.77345C13.7576 10.0397 13.3142 10.4383 12.9941 10.9294H12.9117V9.64706H10.1471V19.0588H13.0882V14.0529C13.0457 13.5403 13.2072 13.0315 13.5376 12.6372C13.868 12.2429 14.3407 11.9949 14.8529 11.9471H14.9647C15.9 11.9471 16.5941 12.5353 16.5941 14.0176V19.0588H19.5353L19.5588 13.3412Z"
      fill="currentColor"
    />
  </svg>
</template>
