<template>
  <div>
    <AlertContainer :label="alert" />
    <div class="py-4"></div>
    <text-edit
      type="email"
      :filled="false"
      rounded="xl"
      label="Email address"
      placeholder="Email address"
      v-model="email"
      :error="emailError"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { validateEmail } from "@/views/Auth/validation";
import AlertContainer from "./AlertContainer.vue";

const email = ref("");
const emit = defineEmits(["changeEmail"]);
const alert = `For your security, after a successful password change
    via the "Forgot Password?" feature, all withdrawals, P2P transactions, and'
    Red Packet sending will be paused for 24 hours.`;
const emailError = computed(() =>
  email.value && !validateEmail(email.value) ? "Invalid email address" : ""
);
watch(email, (newEmail) => {
  emit("changeEmail", newEmail);
});
</script>
