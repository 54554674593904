<template>
  <svg
    v-if="currentTheme === 'dark'"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3616_79499)">
      <path
        d="M46.8783 18.8242C40.3561 14.6787 32.0097 14.0706 32.0097 14.0706C32.0097 14.0706 23.6633 14.6787 17.1411 18.8242C17.1411 18.8242 8.83169 41.6338 32.0097 53.315C55.1877 41.6338 46.8783 18.8242 46.8783 18.8242Z"
        stroke="white"
        stroke-width="0.711964"
        stroke-miterlimit="10"
      />
      <path
        d="M44.7368 20.5108C39.4997 17.6188 33.3079 16.849 32.0119 16.715C32.0111 16.715 32.0103 16.715 32.0095 16.715C32.0087 16.715 32.0079 16.715 32.0071 16.715C30.7113 16.849 24.5193 17.6188 19.2822 20.5108C18.7853 22.4214 17.9249 26.6669 18.502 31.4984C19.4818 39.703 24.0246 46.1479 32.0092 50.6674C39.9939 46.1479 44.5369 39.7033 45.5165 31.4984C46.0933 26.6667 45.2331 22.4211 44.7363 20.5108H44.7368Z"
        fill="#333C57"
      />
      <path
        d="M30.7154 30.7251C31.4036 27.3486 30.0213 24.2159 27.6279 23.7281C25.2345 23.2403 22.7364 25.5821 22.0483 28.9586C21.3601 32.3351 22.7424 35.4677 25.1358 35.9555C27.5291 36.4433 30.0272 34.1016 30.7154 30.7251Z"
        fill="#333C57"
      />
      <path
        d="M29.1193 23.696C33.6064 21.9842 38.6316 24.2343 40.3435 28.7215C42.0553 33.2087 39.8051 38.2339 35.318 39.9457C30.8308 41.6575 25.8056 39.4074 24.0938 34.9202"
        stroke="white"
        stroke-width="1.50794"
        stroke-miterlimit="10"
      />
      <path
        d="M22.6535 31.6634C21.8415 27.6987 24.4065 23.8128 28.3714 23.0007C29.5666 22.7559 29.939 24.5649 28.742 24.81C25.7748 25.4176 23.8551 28.3259 24.4627 31.2928C24.7075 32.488 22.8986 32.8604 22.6535 31.6634Z"
        fill="#6F7DAA"
      />
      <path
        d="M24.3185 35.6206C23.175 35.8547 22.8191 34.1241 23.964 33.8898C25.1075 33.6557 25.4633 35.3862 24.3185 35.6206Z"
        fill="#6F7DAA"
      />
    </g>
    <defs>
      <clipPath id="clip0_3616_79499">
        <rect
          width="33.3475"
          height="40"
          fill="white"
          transform="translate(15.336 13.7144)"
        />
      </clipPath>
    </defs>
  </svg>
  <svg
    v-else
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7166_115446)">
      <path
        d="M46.8783 18.8243C40.3561 14.6788 32.0097 14.0706 32.0097 14.0706C32.0097 14.0706 23.6633 14.6788 17.1411 18.8243C17.1411 18.8243 8.83169 41.6339 32.0097 53.315C55.1877 41.6339 46.8783 18.8243 46.8783 18.8243Z"
        stroke="#6F7DAA"
        stroke-width="0.711964"
        stroke-miterlimit="10"
      />
      <path
        d="M44.7368 20.5108C39.4997 17.6188 33.3079 16.849 32.0119 16.715C32.0111 16.715 32.0103 16.715 32.0095 16.715C32.0087 16.715 32.0079 16.715 32.0071 16.715C30.7113 16.849 24.5193 17.6188 19.2822 20.5108C18.7853 22.4214 17.9249 26.6669 18.502 31.4984C19.4818 39.703 24.0246 46.1479 32.0092 50.6674C39.9939 46.1479 44.5369 39.7033 45.5165 31.4984C46.0933 26.6667 45.2331 22.4211 44.7363 20.5108H44.7368Z"
        fill="#6F7DAA"
      />
      <path
        d="M30.7154 30.7252C31.4036 27.3487 30.0213 24.2161 27.6279 23.7283C25.2345 23.2405 22.7364 25.5822 22.0483 28.9587C21.3601 32.3352 22.7424 35.4678 25.1358 35.9556C27.5291 36.4434 30.0272 34.1017 30.7154 30.7252Z"
        fill="#6F7DAA"
      />
      <path
        d="M29.1193 23.6961C33.6064 21.9843 38.6316 24.2344 40.3435 28.7216C42.0553 33.2087 39.8051 38.2339 35.318 39.9458C30.8308 41.6576 25.8056 39.4074 24.0938 34.9203"
        stroke="white"
        stroke-width="1.50794"
        stroke-miterlimit="10"
      />
      <path
        d="M22.6535 31.6634C21.8415 27.6987 24.4065 23.8128 28.3714 23.0007C29.5666 22.7559 29.939 24.5649 28.742 24.81C25.7748 25.4176 23.8551 28.3259 24.4627 31.2928C24.7075 32.488 22.8986 32.8604 22.6535 31.6634Z"
        fill="#0E111B"
      />
      <path
        d="M24.3185 35.6206C23.175 35.8547 22.8191 34.1242 23.964 33.8898C25.1075 33.6557 25.4633 35.3863 24.3185 35.6206Z"
        fill="#0E111B"
      />
    </g>
    <defs>
      <clipPath id="clip0_7166_115446">
        <rect
          width="33.3475"
          height="40"
          fill="white"
          transform="translate(15.336 13.7144)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup></script>
