<template>
  <div class="h-12 sm:px-6 px-4 flex flex-row justify-between items-center">
    <img
      :src="
        currentTheme === 'dark'
          ? require('@/assets/images/navbar/navbar_logo_dark.svg')
          : require('@/assets/images/navbar/navbar_logo_light.svg')
      "
      alt="Exzi Logo"
      class="mr-12 cursor-pointer"
      @click="handleLogoClick"
    />
    <div class="lg:flex flex-row items-center hidden gap-8">
      <NavbarMenuItem
        v-for="item in navbarData"
        :key="item.id"
        :alignment="item.alignment"
        :isSubMenu="item.isSubMenu"
        :label="item.label"
        :pathName="item.pathName"
        :sub-menu="item.subMenu"
        :sub-menu-props="item.subMenuProps"
      />
    </div>
    <div class="max-2xl:hidden ml-8">
      <FilledButton
        class="bg-primary-700 bg-opacity-[18%] px-3 h-8 dark:text-primary-500 text-primary-800 text-sm hover:bg-opacity-100 hover:text-white"
        label="Rewards Hub"
        type="iconLabel"
      >
        <template #icon>
          <GiftIcon class="stroke-2" />
        </template>
      </FilledButton>
    </div>
    <div class="max-xl:hidden ml-8">
      <NavbarMenuItem :isSubMenu="false" label="EXZi TR" />
    </div>
    <div class="flex-grow" />
    <div class="mr-2" v-if="!showSubMenu">
      <NavbarMenuItem
        :is-sub-menu="true"
        :mobile-adapted="true"
        :sub-menu="SearchDropdown"
        alignment="center"
      >
        <template #icon="{ activated }">
          <IconButton :activated="activated">
            <MagnifyingGlassIcon class="stroke-2 w-6 h-6" />
          </IconButton>
        </template>
      </NavbarMenuItem>
    </div>
    <div v-if="!isLoggedIn" class="max-sm:hidden mr-2 px-4">
      <NavbarMenuItem :is-sub-menu="false" label="Login" pathName="signin" />
    </div>
    <div v-if="!isLoggedIn && !showSubMenu" class="mr-3">
      <FilledButton
        class="px-6 py-1.5 bg-primary-700 text-white"
        label="Sign up"
        type="roundedTextButton"
        @click="handleSignUpClick"
      />
    </div>
    <div v-if="isLoggedIn" class="max-xl:hidden mr-7">
      <FilledButton
        class="bg-primary-700 text-white h-8 px-3 text-sm"
        label="Deposit"
        type="iconLabel"
      >
        <template #icon>
          <ArrowDownTrayIcon class="stroke-2"></ArrowDownTrayIcon>
        </template>
      </FilledButton>
    </div>
    <div v-if="isLoggedIn" class="max-sm:hidden mr-4">
      <NavbarMenuItem
        :isSubMenu="true"
        :sub-menu="AssetsDropdown"
        alignment="center"
        label="Assets"
      />
    </div>
    <div v-if="isLoggedIn && !showSubMenu" class="mr-6">
      <NavbarMenuItem
        :isSubMenu="true"
        :mobile-adapted="true"
        :sub-menu="ProfileDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <span
            :class="[
              'flex items-center justify-center cursor-pointer rounded-full w-8 h-8 my-2 text-center align-middle text-sm font-medium border border-navy-300 hover:bg-navy-300  hover:text-white',
              activated
                ? 'bg-navy-300 text-white'
                : 'dark:bg-navy-500 dark:text-white bg-navy-50 text-navy-800',
            ]"
            @click="handleContentClick"
          >
            SA
          </span>
        </template>
      </NavbarMenuItem>
    </div>
    <div v-if="isLoggedIn && !showSubMenu" class="mr-2">
      <NavbarMenuItem
        :is-sub-menu="true"
        :mobile-adapted="true"
        :sub-menu="NotificationDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <IconButton :activated="activated">
            <BellIcon class="w-6 h-6 stroke-2" />
          </IconButton>
        </template>
      </NavbarMenuItem>
    </div>
    <div class="max-sm:hidden pr-2">
      <NavbarMenuItem
        :is-sub-menu="true"
        :sub-menu="DownloadDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <IconButton :activated="activated">
            <ArrowDownTrayIcon class="w-6 h-6 stroke-2" />
          </IconButton>
        </template>
      </NavbarMenuItem>
    </div>
    <div class="max-sm:hidden pr-2">
      <NavbarMenuItem
        :is-sub-menu="true"
        :sub-menu="LanguageDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <IconButton :activated="activated">
            <GlobeAltIcon class="w-6 h-6 stroke-2" />
          </IconButton>
        </template>
      </NavbarMenuItem>
    </div>
    <div class="max-sm:hidden">
      <IconButton @click="onThemeChange">
        <SunIcon class="w-6 h-6 stroke-2" />
      </IconButton>
    </div>
    <div class="sm:hidden">
      <IconButton @click="showSubMenu = !showSubMenu">
        <Bars3Icon v-if="!showSubMenu" class="w-6 h-6 stroke-2" />
        <XMarkIcon v-else class="w-6 h-6 stroke-2" />
      </IconButton>
    </div>
    <AppNavbarMobile
      :is-show="showSubMenu"
      @close-click="showSubMenu = false"
    />
  </div>
</template>
<script setup>
import {
  ArrowDownTrayIcon,
  Bars3Icon,
  BellIcon,
  GiftIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  SunIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import IconButton from "@/components/Button/IconButton.vue";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { allPairItemsFromData } from "@/utils/utils";
import ProfileDropdown from "./dropdown/ProfileDropdown.vue";
import NotificationDropdown from "./dropdown/NotificationDropdown.vue";
import DownloadDropdown from "./dropdown/DownloadDropdown.vue";
import AssetsDropdown from "./dropdown/AssetsDropdown.vue";
import LanguageDropdown from "./dropdown/LanguageDropdown.vue";
import SearchDropdown from "./dropdown/SearchDropdown.vue";
import NavbarMenuItem from "./item/NavbarMenuItem.vue";
import TradeDropdown from "./dropdown/TradeDropdown.vue";
import MoreDropdown from "./dropdown/MoreDropdown.vue";
import FilledButton from "@/components/Button/FilledButton.vue";
import { useRouter } from "vue-router";
import AppNavbarMobile from "./AppNavbarMobile.vue";

const store = useStore();
const router = useRouter();
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const showSubMenu = ref(false);

const onThemeChange = () => {
  store.dispatch("toggleTheme", store.getters.currentTheme);
};

const allPairsModified = computed(() => store.getters.allPairsModified);
const activeCurrencies = computed(() => store.getters.activeCurrencies);

const allPairItems = computed(() =>
  allPairItemsFromData(allPairsModified.value, activeCurrencies.value)
);

const handleLogoClick = () => {
  router.push({ name: "home" });
};

const handleSignUpClick = () => {
  router.push({ name: "signup" });
};

const navbarData = computed(() => [
  {
    id: 0,
    label: "Buy Crypto",
    isSubMenu: false,
  },
  {
    id: 1,
    label: "Markets",
    pathName: "markets",
    isSubMenu: false,
  },
  {
    id: 2,
    label: "Trade",
    isSubMenu: true,
    subMenu: TradeDropdown,
    subMenuProps: { allPairItems },
    alignment: "center",
  },
  {
    id: 3,
    label: "Referral",
    isSubMenu: false,
  },
  {
    id: 4,
    label: "More",
    isSubMenu: true,
    subMenu: MoreDropdown,
    alignment: "center",
  },
]);
</script>
