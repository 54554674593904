<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.788 8.08746L12.538 1.83746C12.4069 1.70624 12.2399 1.61685 12.058 1.58059C11.8761 1.54433 11.6876 1.56283 11.5162 1.63376C11.3448 1.70468 11.1984 1.82483 11.0953 1.97902C10.9922 2.1332 10.9372 2.3145 10.9372 2.49996V5.37027C8.9247 5.61402 6.74501 6.61167 4.94111 8.14136C2.66298 10.0742 1.24423 12.5703 0.945795 15.1726C0.915562 15.4338 0.968432 15.6978 1.09689 15.9272C1.22534 16.1565 1.42284 16.3396 1.66131 16.4502C1.89977 16.5609 2.16705 16.5935 2.42514 16.5436C2.68324 16.4936 2.91901 16.3635 3.09892 16.1718C3.91611 15.3015 6.77079 12.5984 10.9372 12.2328V15C10.9374 15.1853 10.9924 15.3664 11.0955 15.5204C11.1985 15.6744 11.3448 15.7944 11.516 15.8653C11.6873 15.9362 11.8756 15.9548 12.0574 15.9187C12.2391 15.8826 12.4061 15.7934 12.5372 15.6625L18.7872 9.41246C18.9629 9.23678 19.0616 8.99856 19.0618 8.75012C19.0619 8.50168 18.9634 8.26334 18.788 8.08746ZM12.8122 12.7343V11.25C12.8122 11.0013 12.7134 10.7629 12.5376 10.587C12.3618 10.4112 12.1233 10.3125 11.8747 10.3125C8.04658 10.3125 5.11142 11.9968 3.29736 13.446C3.85439 12.0273 4.83095 10.6945 6.15439 9.57183C7.91376 8.07886 10.052 7.18746 11.8747 7.18746C12.1233 7.18746 12.3618 7.08868 12.5376 6.91287C12.7134 6.73705 12.8122 6.4986 12.8122 6.24996V4.76558L16.7966 8.74996L12.8122 12.7343Z"
      fill="currentColor"
    />
  </svg>
</template>
