<template>
  <div ref="container" class="">
    <table class="w-full">
      <thead :class="hideHeader && 'hidden'">
        <tr>
          <th
            v-for="item in headerItems"
            :key="item.id"
            :class="`text-navy-200 text-[10px] text-${item.align} pb-2`"
          >
            {{ item.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in contextItems" :key="item.id">
          <order-book-list-context-item
            :item="item"
            :isBuy="isBuy"
            :show-lines="showLines"
            :width="container?.offsetWidth"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import OrderBookListContextItem from "@/views/Trading/OrderBook/OrderBookList/OrderBookListContextItem.vue";

defineProps({
  isBuy: {
    type: Boolean,
    default: false,
  },
  hideHeader: {
    type: Boolean,
    default: false,
  },
  headerItems: Object,
  contextItems: Object,
  showLines: Boolean,
});

const container = ref(null);
</script>
