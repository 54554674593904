<template>
  <div
    @click="handleClick"
    class="flex flex-row items-center h-10 w-full cursor-pointer dark:hover:bg-navy-500 hover:rounded-md dark:hover:text-white dark:text-navy-100 hover:bg-navy-10 text-navy-300 hover:text-navy-800"
  >
    <div v-if="props.icon" :class="['ml-3 w-5 h-5']">
      <component :is="props.icon" class="w-5 h-5" />
    </div>
    <div class="ml-3 text-sm font-medium dark:text-white text-navy-800">
      {{ props.label }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { isEmpty } from "@/utils/utils";
const router = useRouter();
const props = defineProps(["label", "pathName", "icon"]);
const emit = defineEmits(["click"]);
const handleClick = () => {
  emit("click");
  if (!isEmpty(props.pathName)) router.push({ name: props.pathName });
};
</script>
