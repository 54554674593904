<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.5 12V36C43.5 36.7956 43.1839 37.5587 42.6213 38.1213C42.0587 38.6839 41.2956 39 40.5 39H15.5625L9.44999 44.28L9.43312 44.2931C8.89318 44.7509 8.20788 45.0015 7.49999 45C7.0593 44.999 6.62415 44.9018 6.22499 44.715C5.70724 44.4757 5.26929 44.0925 4.96342 43.6111C4.65754 43.1296 4.49667 42.5704 4.49999 42V12C4.49999 11.2044 4.81606 10.4413 5.37867 9.87868C5.94128 9.31607 6.70434 9 7.49999 9H40.5C41.2956 9 42.0587 9.31607 42.6213 9.87868C43.1839 10.4413 43.5 11.2044 43.5 12ZM40.5 12H7.49999V42L14.0194 36.375C14.2904 36.136 14.6386 36.0028 15 36H40.5V12Z"
      fill="currentColor"
    />
    <path
      d="M22.1292 22.75C21.882 23.12 21.75 23.555 21.75 24C21.75 24.5967 21.9871 25.169 22.409 25.591C22.831 26.0129 23.4033 26.25 24 26.25C24.445 26.25 24.88 26.118 25.25 25.8708C25.62 25.6236 25.9084 25.2722 26.0787 24.861C26.249 24.4499 26.2936 23.9975 26.2068 23.561C26.12 23.1246 25.9057 22.7237 25.591 22.409C25.2763 22.0943 24.8754 21.8801 24.439 21.7932C24.0025 21.7064 23.5501 21.751 23.139 21.9213C22.7278 22.0916 22.3764 22.38 22.1292 22.75Z"
      fill="currentColor"
    />
    <path
      d="M17 25.8708C16.63 26.118 16.195 26.25 15.75 26.25C15.1533 26.25 14.581 26.0129 14.159 25.591C13.7371 25.169 13.5 24.5967 13.5 24C13.5 23.555 13.632 23.12 13.8792 22.75C14.1264 22.38 14.4778 22.0916 14.889 21.9213C15.3001 21.751 15.7525 21.7064 16.189 21.7932C16.6254 21.8801 17.0263 22.0943 17.341 22.409C17.6557 22.7237 17.87 23.1246 17.9568 23.561C18.0436 23.9975 17.999 24.4499 17.8287 24.861C17.6584 25.2722 17.37 25.6236 17 25.8708Z"
      fill="currentColor"
    />
    <path
      d="M33.5 25.8708C33.13 26.118 32.695 26.25 32.25 26.25C31.6533 26.25 31.081 26.0129 30.659 25.591C30.2371 25.169 30 24.5967 30 24C30 23.555 30.132 23.12 30.3792 22.75C30.6264 22.38 30.9778 22.0916 31.389 21.9213C31.8001 21.751 32.2525 21.7064 32.689 21.7932C33.1254 21.8801 33.5263 22.0943 33.841 22.409C34.1557 22.7237 34.37 23.1246 34.4568 23.561C34.5436 23.9975 34.499 24.4499 34.3287 24.861C34.1584 25.2722 33.87 25.6236 33.5 25.8708Z"
      fill="currentColor"
    />
  </svg>
</template>
