<template>
  <div class="">
    <div
      v-for="item in items"
      :key="item.id"
      :class="[
        'p-2 rounded-md cursor-pointer',
        item.id === selectedItem.id
          ? 'bg-navy-10 dark:bg-navy-400'
          : 'hover:bg-navy-10 dark:hover:bg-navy-400',
      ]"
      @click="handleClick(item)"
    >
      <div v-if="item.icon" class="w-6 h-6">
        <img :src="item.icon" />
      </div>
      <div class="w-2" />
      <div>
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
defineProps(["items"]);
const emit = defineEmits(["onSelect"]);
const selectedItem = ref({});
const handleClick = (item) => {
  selectedItem.value = item;
  emit("onSelect", item.value);
};
</script>
