<template>
  <div
    class="flex flex-row min-h-10 justify-between items-center cursor-pointer"
  >
    <div class="mr-3">
      <StarIcon class="w-4 h-4" />
    </div>
    <div class="mr-3">
      <BtcIcon class="w-6 h-6" />
    </div>
    <div class="mr-1 text-sm font-semibold dark:text-white text-navy-800">
      {{ props.tokenA }}
    </div>
    <div class="font-semibold text-sm text-navy-100">{{ props.tokenB }}</div>
    <div class="flex-grow" />
    <div class="text-sm font-medium dark:text-white text-navy-800">
      {{ props.price }}
    </div>
    <div class="flex-grow" />
    <div class="text-sm font-medium text-red-500 pr-2">{{ props.ratio }}</div>
  </div>
</template>
<script setup>
import StarIcon from "@/assets/icons/common/StarIcon.vue";
import BtcIcon from "@/assets/icons/coins/BtcIcon.vue";
import { defineProps } from "vue";
const props = defineProps(["tokenA", "tokenB", "price", "ratio"]);
</script>
