<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2116 6.35375L9.21157 11.3538C9.16513 11.4002 9.10998 11.4371 9.04929 11.4623C8.98859 11.4874 8.92352 11.5004 8.85782 11.5004C8.79211 11.5004 8.72704 11.4874 8.66635 11.4623C8.60565 11.4371 8.5505 11.4002 8.50407 11.3538L3.50407 6.35375C3.43406 6.28382 3.38638 6.1947 3.36705 6.09765C3.34773 6.00061 3.35763 5.90002 3.3955 5.8086C3.43338 5.71719 3.49753 5.63908 3.57982 5.58414C3.66212 5.5292 3.75887 5.49992 3.85782 5.5H13.8578C13.9568 5.49992 14.0535 5.5292 14.1358 5.58414C14.2181 5.63908 14.2823 5.71719 14.3201 5.8086C14.358 5.90002 14.3679 6.00061 14.3486 6.09765C14.3293 6.1947 14.2816 6.28382 14.2116 6.35375Z"
      fill="currentColor"
    />
  </svg>
</template>
