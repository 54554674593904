<template>
  <svg
    v-if="currentTheme === 'dark'"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3616_79525)">
      <path
        d="M27.7494 40.7443C37.2945 40.7443 45.0323 34.3096 45.0323 26.3721C45.0323 18.4346 37.2945 12 27.7494 12C18.2043 12 10.4665 18.4346 10.4665 26.3721C10.4665 34.3096 18.2043 40.7443 27.7494 40.7443Z"
        fill="#333C57"
      />
      <path
        d="M26.2505 43.7281C25.9867 43.3424 26.0867 42.813 26.4724 42.551C26.8581 42.2872 27.3875 42.3873 27.6495 42.773C28.7192 44.3357 30.3038 45.6492 32.2213 46.5643C34.0915 47.4575 36.2782 47.9688 38.6159 47.9688C39.229 47.9688 39.8239 47.9342 40.397 47.8687C40.9901 47.8014 41.5704 47.6995 42.1344 47.5685C42.3309 47.523 42.5273 47.5503 42.6965 47.6322L47.6576 49.6698L46.5752 46.4297C46.4533 46.0658 46.5934 45.6783 46.8917 45.4673C48.2143 44.5322 49.2877 43.3769 50.019 42.0744C50.7122 40.8409 51.0979 39.4692 51.0979 38.0265C51.0979 36.5838 50.7067 35.1903 50.0027 33.9514C49.2586 32.6397 48.1689 31.4772 46.8281 30.5421C46.446 30.2747 46.3514 29.7471 46.6189 29.365C46.8863 28.983 47.4139 28.8884 47.7959 29.1558C49.3459 30.2365 50.6085 31.5863 51.4763 33.1163C52.324 34.6118 52.797 36.2764 52.797 38.0283C52.797 39.7803 52.3313 41.4194 51.4981 42.9039C50.7395 44.2538 49.6752 45.4655 48.3781 46.4751L49.8517 50.8868C49.9135 51.0724 49.9117 51.2816 49.8317 51.4781C49.6534 51.9111 49.1585 52.1167 48.7274 51.9384L42.2581 49.2804C41.716 49.3987 41.1593 49.4915 40.5935 49.5551C39.9404 49.6297 39.28 49.6679 38.6196 49.6679C36.0235 49.6679 33.5875 49.0949 31.4936 48.0961C29.3086 47.0537 27.493 45.5419 26.2505 43.7281Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.1201 24.5551C22.1571 24.5551 22.9976 25.3956 22.9976 26.4325C22.9976 27.4695 22.1571 28.31 21.1201 28.31C20.0831 28.31 19.2426 27.4695 19.2426 26.4325C19.2426 25.3956 20.0831 24.5551 21.1201 24.5551Z"
        fill="#C5CBDD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.6931 24.5551C28.7301 24.5551 29.5706 25.3956 29.5706 26.4325C29.5706 27.4695 28.7301 28.31 27.6931 28.31C26.6561 28.31 25.8156 27.4695 25.8156 26.4325C25.8156 25.3956 26.6561 24.5551 27.6931 24.5551Z"
        fill="#C5CBDD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.2643 24.5551C35.3012 24.5551 36.1417 25.3956 36.1417 26.4325C36.1417 27.4695 35.3012 28.31 34.2643 28.31C33.2273 28.31 32.3868 27.4695 32.3868 26.4325C32.3868 25.3956 33.2273 24.5551 34.2643 24.5551Z"
        fill="#C5CBDD"
      />
    </g>
    <defs>
      <clipPath id="clip0_3616_79525">
        <rect
          width="42.3323"
          height="40"
          fill="white"
          transform="translate(10.4665 12)"
        />
      </clipPath>
    </defs>
  </svg>
  <svg
    v-else
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7166_115461)">
      <path
        d="M27.7494 40.7443C37.2945 40.7443 45.0323 34.3096 45.0323 26.3721C45.0323 18.4346 37.2945 12 27.7494 12C18.2043 12 10.4665 18.4346 10.4665 26.3721C10.4665 34.3096 18.2043 40.7443 27.7494 40.7443Z"
        fill="#9CA5C4"
      />
      <path
        d="M26.2505 43.7281C25.9867 43.3424 26.0867 42.813 26.4724 42.551C26.8581 42.2872 27.3875 42.3873 27.6495 42.773C28.7192 44.3357 30.3038 45.6492 32.2213 46.5643C34.0915 47.4575 36.2782 47.9688 38.6159 47.9688C39.229 47.9688 39.8239 47.9342 40.397 47.8687C40.9901 47.8014 41.5704 47.6995 42.1344 47.5685C42.3309 47.523 42.5273 47.5503 42.6965 47.6322L47.6576 49.6698L46.5752 46.4297C46.4533 46.0658 46.5934 45.6783 46.8917 45.4673C48.2143 44.5322 49.2877 43.3769 50.019 42.0744C50.7122 40.8409 51.0979 39.4692 51.0979 38.0265C51.0979 36.5838 50.7067 35.1903 50.0027 33.9514C49.2586 32.6397 48.1689 31.4772 46.8281 30.5421C46.446 30.2747 46.3514 29.7471 46.6189 29.365C46.8863 28.983 47.4139 28.8884 47.7959 29.1558C49.3459 30.2365 50.6085 31.5863 51.4763 33.1163C52.324 34.6118 52.797 36.2764 52.797 38.0283C52.797 39.7803 52.3313 41.4194 51.4981 42.9039C50.7395 44.2538 49.6752 45.4655 48.3781 46.4751L49.8517 50.8868C49.9135 51.0724 49.9117 51.2816 49.8317 51.4781C49.6534 51.9111 49.1585 52.1167 48.7274 51.9384L42.2581 49.2804C41.716 49.3987 41.1593 49.4915 40.5935 49.5551C39.9404 49.6297 39.28 49.6679 38.6196 49.6679C36.0235 49.6679 33.5875 49.0949 31.4936 48.0961C29.3086 47.0537 27.493 45.5419 26.2505 43.7281Z"
        fill="#6F7DAA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.1201 24.555C22.1571 24.555 22.9976 25.3955 22.9976 26.4325C22.9976 27.4694 22.1571 28.3099 21.1201 28.3099C20.0831 28.3099 19.2426 27.4694 19.2426 26.4325C19.2426 25.3955 20.0831 24.555 21.1201 24.555Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.6931 24.555C28.7301 24.555 29.5706 25.3955 29.5706 26.4325C29.5706 27.4694 28.7301 28.3099 27.6931 28.3099C26.6561 28.3099 25.8156 27.4694 25.8156 26.4325C25.8156 25.3955 26.6561 24.555 27.6931 24.555Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.2643 24.555C35.3012 24.555 36.1417 25.3955 36.1417 26.4325C36.1417 27.4694 35.3012 28.3099 34.2643 28.3099C33.2273 28.3099 32.3868 27.4694 32.3868 26.4325C32.3868 25.3955 33.2273 24.555 34.2643 24.555Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7166_115461">
        <rect
          width="42.3323"
          height="40"
          fill="white"
          transform="translate(10.4665 12)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup></script>
