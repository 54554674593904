<template>
  <div
    class="w-full dark:bg-navy-800 bg-white group flex flex-col p-2 sm:p-4 rounded-2xl hover:dark:bg-navy-600 hover:bg-navy-10 cursor-pointer flex-1"
    @click="handleClick"
  >
    <div
      :class="[
        'dark:text-white text-navy-800',
        props.size == 'sm' ? 'text-lg font-medium' : 'text-xl font-semibold',
      ]"
    >
      {{ props.heading }}
    </div>
    <div
      v-if="props.content"
      class="mt-2 mb-6 text-base font-normal dark:text-navy-50 text-navy-300 line-clamp-3"
    >
      {{ props.content }}
    </div>
    <div class="grow"></div>
    <div
      :class="[
        'text-xs font-medium text-navy-100',
        props.siz == 'sm' ? 'mt-3' : 'mt-6',
      ]"
    >
      {{ props.updateTime }}
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps } from "vue";
const props = defineProps(["heading", "content", "updateTime", "size"]);
const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>
