<template>
  <div class="px-4 py-6 h-80 custom-scrollbar flex flex-col">
    <table class="w-full">
      <thead>
        <tr class="">
          <th
            :class="[
              'text-xs font-medium border-b dark:border-navy-500 border-navy-10 p-2',
              `text-${item.align}`,
              item.action
                ? 'text-primary-700 cursor-pointer'
                : 'dark:text-navy-100 text-navy-200',
            ]"
            v-for="item in tableHeaderItems"
            :key="item.id"
            @click="item.action ? handleClick() : null"
          >
            {{ item.name }}
          </th>
        </tr>
      </thead>
      <tbody class="text-xs">
        <tr
          v-for="item in openOrderHistory"
          :key="`open_order_history_${item.id}`"
        >
          <td
            v-for="headerItem in tableHeaderItems.filter(
              (item) => !item.action
            )"
            :key="headerItem.id"
            class="p-2 border-b dark:border-navy-500"
          >
            <span
              v-if="!item.action"
              :class="
                headerItem.color
                  ? item[headerItem.baseField]
                    ? 'text-red-500'
                    : 'text-green-400'
                  : ''
              "
              >{{ item[headerItem.field] }}</span
            >
          </td>
          <td class="p-2 border-b dark:border-navy-500 cursor-pointer">
            <trash-icon
              class="w-4 h-4 text-navy-100 m-auto"
              @click="handleTrash(item.id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-sm text-navy-100 text-center my-auto">
      <template v-if="!isLoggedIn">
        <p>
          <link-button label="Login" @click="handleLogin" /> or
          <link-button label="Sign up" @click="handleSignUp" />
        </p>
      </template>
      <template v-else-if="isEmpty(openOrderHistory)">
        <div>
          <no-open-orders-icon class="mx-auto" />
          <p class="mt-3 font-semibold">No open orders</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { isEmpty } from "@/utils/utils.js";
import NoOpenOrdersIcon from "@/assets/icons/common/NoOpenOrders.vue";
import { formatOrderHistory } from "@/utils/utils";
import { TrashIcon } from "@heroicons/vue/24/outline";
import { postHttpRequest } from "@/api/api";

const tableHeaderItems = [
  {
    id: 1,
    name: "Time",
    value: "time",
    align: "left",
    field: "time_create_modified",
  },
  {
    id: 2,
    name: "Pair",
    value: "pair",
    align: "left",
    field: "pair_name_modified",
  },
  {
    id: 3,
    name: "Type",
    value: "type",
    align: "left",
    field: "type_trade_modified",
  },
  {
    id: 4,
    name: "Side",
    value: "side",
    align: "left",
    field: "type_modified",
    baseField: "type",
    color: true,
  },
  {
    id: 5,
    name: "Price",
    value: "price",
    align: "left",
    field: "rate_f_modified",
  },
  {
    id: 6,
    name: "Amount",
    value: "amount",
    align: "left",
    field: "volume_f_modified",
  },
  {
    id: 7,
    name: "Filled",
    value: "filled",
    align: "left",
    field: "filled_modified",
  },
  {
    id: 8,
    name: "Order Price",
    value: "orderPrice",
    align: "left",
    field: "rate_avg_done_f_modified",
  },
  {
    id: 9,
    name: "Total",
    value: "total",
    align: "left",
    field: "price_done_f_modified",
  },
  {
    id: 10,
    name: "Cancel All",
    value: "cancelAll",
    align: "center",
    field: "",
    action: true,
  },
];

const store = useStore();
const router = useRouter();

const isLoggedIn = computed(() => store.getters.isLoggedIn);
const openOrderHistory = computed(() => {
  const list = store.getters.openOrderHistory?.list ?? [];
  return formatOrderHistory(list);
});

const handleLogin = () => {
  router.push({ name: "signin" });
};

const handleSignUp = () => {
  router.push({ name: "signup" });
};

const handleTrash = async (id) => {
  try {
    const res = await postHttpRequest(store)("/api/p2p/delete", {
      id,
    });
    if (res.status) {
      store.dispatch("fetchOpenOrderHistory");
    }
  } catch (error) {
    console.log(error);
  }
};

const handleClick = () => {
  console.log("remove all");
};
</script>
