const state = {
  careerList: [],
};

const mutations = {
  SET_CAREER_LIST(state, list) {
    state.careerList = list;
  },
};

const actions = {
  async fetchCareerList({ commit }) {
    try {
      const response = await fetch("/mock/career/career_list.json");
      const data = await response.json();
      commit("SET_CAREER_LIST", data);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  careerList: (state) => state.careerList,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
