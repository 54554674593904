<template>
  <div class="max-w-[416px]">
    <p class="text-title font-semibold">Forget Password</p>
    <div class="sm:h-12 h-6" />
    <UnderlineTab :items="tabHeaderItems" @change="handleChangeSelectedTab" />
    <div class="sm:h-10 h-6" />
    <EmailAddress
      v-if="selectedTab == tabHeaderItems[0].id"
      @change-email="handleChangeEmail"
    />

    <PhoneNumber
      v-if="selectedTab == tabHeaderItems[1].id"
      @change-phone="handleChangePhone"
    />

    <QRCode v-if="selectedTab === tabHeaderItems[2].id" />

    <template v-if="selectedTab !== tabHeaderItems[2].id">
      <div class="sm:h-10 h-6" />
      <filled-button
        :label="
          selectedTab === tabHeaderItems[1].id
            ? 'Send verification code'
            : 'Reset Password'
        "
        :disabled="isDisabled"
        :isLoading="isLoading"
        @click="handleResetClick()"
      />
      <GeeTest
        v-if="captchaData.showCaptcha && captchaData.data.gt"
        :gt="captchaData.data.gt"
        :challenge="captchaData.data.challenge"
        :success="captchaData.data.success"
        :onSuccess="handleCaptchaSuccess"
      />
    </template>
  </div>
</template>
<script setup>
import GeeTest from "@/components/GeeTest/GeeTest.vue";
import EmailAddress from "@/views/Auth/ForgetPassword/RecoverWith/EmailAddress.vue";
import PhoneNumber from "@/views/Auth/ForgetPassword/RecoverWith/PhoneNumber.vue";
import QRCode from "@/views/Auth/ForgetPassword/RecoverWith/QRCode.vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import { validateEmail, validatePhoneNumber } from "@/views/Auth/validation";
import { ref, computed } from "vue";
import axios from "@/api/axios";
import { useStore } from "vuex";

const selectedTab = ref(1);
const isLoading = ref(false);
const email = ref("");
const phone = ref("");
const store = useStore();

const tabHeaderItems = [
  { id: 1, name: "Email address" },
  { id: 2, name: "Phone Number" },
  { id: 3, name: "QR Code" },
];

const captchaData = ref({
  showCaptcha: false,
  data: {
    gt: "",
    challenge: "",
    success: 0,
  },
});

const isDisabled = computed(() => {
  if (selectedTab.value === tabHeaderItems[0].id) {
    return !validateEmail(email.value);
  } else if (selectedTab.value === tabHeaderItems[1].id) {
    return !validatePhoneNumber(phone.value);
  }
  return false;
});

const handleChangeSelectedTab = async (item) => {
  selectedTab.value = item.id;
};

const handleResetClick = async () => {
  try {
    isLoading.value = true;
    const res = await axios.get("/api/captcha?p=5");
    isLoading.value = false;
    const { challenge, geettest_h, gt, success } = res.data.data;
    captchaData.value.data = {
      challenge,
      gt,
      success,
      geettest_h,
    };
    captchaData.value.showCaptcha = true;
  } catch (error) {
    console.log(error);
  }
};

const handleChangeEmail = (newEmail) => {
  email.value = newEmail;
};

const handleChangePhone = (newPhone) => {
  phone.value = newPhone;
};

const handleCaptchaSuccess = async (result) => {
  try {
    let recyverData;
    if (selectedTab.value === tabHeaderItems[0].id) {
      recyverData = {
        email: email.value,
        geetestChallenge: result.geetest_challenge,
        geetestSecCode: result.geetest_seccode,
        geetestValidate: result.geetest_validate,
        geettest_h: captchaData.value.data.geettest_h,
      };
    } else if (selectedTab.value === tabHeaderItems[1].id) {
      recyverData = {
        phone: +phone.value,
        geetestChallenge: result.geetest_challenge,
        geetestSecCode: result.geetest_seccode,
        geetestValidate: result.geetest_validate,
        geettest_h: captchaData.value.data.geettest_h,
      };
    }
    isLoading.value = true;
    const res = await axios.post("/api/user/send-recovery", recyverData);
    isLoading.value = false;
    captchaData.value.showCaptcha = false;
    if (res.data.status) {
      store.dispatch("setToast", {
        type: "success",
        message: res.data.message,
      });
    } else {
      store.dispatch("setToast", {
        type: "error",
        message: res.data.errors.join(", "),
      });
    }
  } catch (error) {
    console.log(error);
  }
};
</script>
