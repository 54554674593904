<template>
  <div>
    <div class="flex sm:space-x-4 space-x-3" @paste="handlePaste">
      <input
        v-for="(digit, index) in code"
        :key="index"
        :class="[
          'bg-navy-10 border-navy-50 dark:bg-navy-700 dark:border-navy-400',
          'sm:w-16 w-12 sm:h-16 h-12 text-center text-3xl sm:rounded-2xl rounded-xl border-2 focus:outline-none focus:border-primary-700',
          error ? 'border-red-500' : '',
        ]"
        v-model="code[index]"
        maxlength="1"
        @input="focusNext($event, index)"
        @keydown.backspace="focusPrevious($event, index)"
      />
    </div>
    <label v-if="error" class="mt-4 text-red-500">{{ error }}</label>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from "vue";

const emit = defineEmits(["onComplete"]);
const code = ref(["", "", "", "", "", ""]);
defineProps(["error"]);

const focusNext = (event, index) => {
  if (event.target.value && index < code.value.length - 1) {
    event.target.nextElementSibling.focus();
  }

  if (code.value.every((digit) => digit !== "")) {
    emit("onComplete", code.value.join(""));
  }
};

const focusPrevious = (event, index) => {
  if (!event.target.value && index > 0) {
    event.target.previousElementSibling.focus();
  }
};

const handlePaste = (event) => {
  const pasteData = event.clipboardData.getData("text").slice(0, 6);
  if (/^\d{6}$/.test(pasteData)) {
    for (let i = 0; i < pasteData.length; i++) {
      code.value[i] = pasteData[i];
    }
    event.preventDefault();
  }

  if (code.value.every((digit) => digit !== "")) {
    emit("onComplete", code.value.join(""));
  }
};
</script>
