<template>
  <div>
    <p class="font-semibold mb-6">Asset movements</p>
    <div
      class="w-80 h-96 border-2 dark:border-navy-500 border-navy-50 rounded-2xl p-6"
    ></div>
  </div>
</template>

<script setup></script>
