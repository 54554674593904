<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39 7.5H9C8.20435 7.5 7.44129 7.81607 6.87868 8.37868C6.31607 8.94129 6 9.70435 6 10.5V21C6 30.885 10.785 36.8756 14.7994 40.1606C19.1231 43.6969 23.4244 44.8969 23.6119 44.9475C23.8697 45.0176 24.1416 45.0176 24.3994 44.9475C24.5869 44.8969 28.8825 43.6969 33.2119 40.1606C37.215 36.8756 42 30.885 42 21V10.5C42 9.70435 41.6839 8.94129 41.1213 8.37868C40.5587 7.81607 39.7957 7.5 39 7.5ZM39 21C39 27.9506 36.4388 33.5925 31.3875 37.7663C29.1887 39.577 26.6879 40.986 24 41.9288C21.3473 41.0024 18.8774 39.6184 16.7025 37.8394C11.5913 33.6581 9 27.9938 9 21V10.5H39V21Z"
      fill="currentColor"
    />
    <path
      d="M14.9991 25.4999C14.9991 25.8979 15.1573 26.2796 15.4387 26.5611L19.9387 31.0611C20.078 31.2006 20.2435 31.3112 20.4256 31.3867C20.6077 31.4622 20.8029 31.501 21 31.501C21.1971 31.501 21.3923 31.4622 21.5744 31.3867C21.7565 31.3112 21.9219 31.2006 22.0612 31.0611L32.5612 20.5611C32.7006 20.4217 32.8111 20.2563 32.8866 20.0742C32.962 19.8921 33.0008 19.697 33.0008 19.4999C33.0008 19.3028 32.962 19.1076 32.8866 18.9255C32.8111 18.7434 32.7006 18.578 32.5612 18.4386C32.4219 18.2992 32.2564 18.1887 32.0743 18.1133C31.8922 18.0378 31.6971 17.999 31.5 17.999C31.3029 17.999 31.1077 18.0378 30.9256 18.1133C30.7435 18.1887 30.5781 18.2992 30.4387 18.4386L21 27.8792L17.5612 24.4386C17.2798 24.1571 16.898 23.999 16.5 23.999C16.1019 23.999 15.7202 24.1571 15.4387 24.4386C15.1573 24.7201 14.9991 25.1018 14.9991 25.4999Z"
      fill="currentColor"
    />
  </svg>
</template>
