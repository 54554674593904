<template>
  <div
    class="w-[330px] rounded-2xl p-4 flex flex-col shadow-xl dark:bg-navy-600 border dark:border-navy-400 dark:shadow-black/[0.32] bg-white shadow-black/[0.16]"
  >
    <AssetBalance />
    <div class="mt-6">
      <div class="flex flex-row px-4 justify-between">
        <FilledButton label="Deposit" type="iconTextButton">
          <ArrowDownTrayIcon class="stroke-2 w-6 h-6" />
        </FilledButton>
        <FilledButton label="Withraw" type="iconTextButton">
          <ArrowUpTrayIcon class="stroke-2 w-6 h-6" />
        </FilledButton>
        <FilledButton label="Transfer" type="iconTextButton">
          <ArrowsUpDownIcon class="stroke-2 w-6 h-6" />
        </FilledButton>
      </div>
    </div>
    <div class="my-4 border dark:border-navy-500 border-navy-10"></div>
    <div class="flex flex-col gap-2">
      <ProfileMenuItem
        v-for="item in assetMenuData"
        :label="item.label"
        :key="item.id"
        :path-name="item.url"
      />
    </div>
  </div>
</template>
<script setup>
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  ArrowsUpDownIcon,
} from "@heroicons/vue/24/outline";
import FilledButton from "@/components/Button/FilledButton.vue";
import ProfileMenuItem from "../item/ProfileMenuItem.vue";
import AssetBalance from "@/common/AssetsBalance/AssetBalance.vue";

const assetMenuData = [
  {
    id: 0,
    label: "Asset overview",
    url: "asset-overview",
  },
  {
    id: 1,
    label: "Trading account",
  },
  {
    id: 2,
    label: "Funding account",
  },
  {
    id: 3,
    label: "Buy crypto",
  },
];
</script>
