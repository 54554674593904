<template>
  <div
    :class="[
      'flex flex-row py-2.5 px-3 cursor-pointer gap-3 rounded-md',
      selected
        ? 'dark:bg-navy-500 bg-primary-200 text-primary-700 dark:text-white'
        : 'text-navy-800 dark:text-white',
    ]"
    @click="handleClick"
  >
    <component
      :is="props.menuItemData.icon"
      :class="['w-5 h-5', selected ? '' : 'text-navy-100']"
    />
    <div class="text-sm font-medium">
      {{ props.menuItemData.label }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  menuItemData: Object,
  selected: Boolean,
});
const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>
