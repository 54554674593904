<template>
  <div
    :class="[
      props.isShow ? 'translate-y-0' : '-translate-y-[100vh]',
      'w-full h-[calc(100vh-48px)] z-[100] flex flex-col dark:bg-navy-700 bg-white left-0 top-12 fixed custom-scrollbar overscroll-y-none transition-transform delay-75 duration-300 ease-in-out origin-top',
    ]"
  >
    <div class="w-full relative mt-4">
      <div class="absolute w-full bottom-0 left-0 right-0 -z-10">
        <img
          src="/assets/images/navbar/navbar_mobile_background.svg"
          class="w-full"
        />
      </div>
      <div class="flex flex-col px-8">
        <div class="mb-4" v-if="!isLoggedIn">
          <outline-button
            size="md"
            width="full"
            color="primary"
            @click="router.push({ name: 'signin' })"
          >
            Login
          </outline-button>
        </div>
        <div v-if="!isLoggedIn">
          <fill-button
            size="md"
            width="full"
            @click="router.push({ name: 'signup' })"
            >Sign up</fill-button
          >
        </div>
        <div v-if="isLoggedIn">
          <CollapseView>
            <template
              #header="{
                handleContentClick: handleButtonClick,
                isShowingContent,
              }"
            >
              <div
                class="flex flex-row py-1.5 cursor-pointer items-center justify-between group mb-4"
              >
                <div
                  class="text-base font-semibold dark:text-white text-navy-800 group-hover:text-primary-700"
                >
                  Assets
                </div>
                <div
                  @click="handleButtonClick"
                  class="group-hover:text-primary-700"
                >
                  <ChevronUpIcon
                    v-if="isShowingContent"
                    class="w-6 h-6 stroke-2"
                  />
                  <ChevronDownIcon v-else class="w-6 h-6 stroke-2" />
                </div>
              </div>
            </template>
            <template #content>
              <fill-button color="primary" width="full" size="md">
                <ArrowDownTrayIcon class="w-5 h-5 stroke-2" />
                <div>Deposit</div>
              </fill-button>
            </template>
          </CollapseView>
        </div>
        <div class="flex flex-col mt-10">
          <template v-for="item in navbarData" :key="item.id">
            <div
              v-if="!item.isSubMenu"
              class="text-base font-semibold dark:text-white text-navy-800 py-1.5 mb-4 cursor-pointer hover:text-primary-700"
              @click="handleMenuClick(item.pathName)"
            >
              {{ item.label }}
            </div>
            <CollapseView v-else>
              <template
                #header="{
                  handleContentClick: handleButtonClick,
                  isShowingContent,
                }"
              >
                <div
                  class="flex flex-row mb-4 py-1.5 cursor-pointer items-center justify-between group"
                >
                  <div
                    class="text-base font-semibold dark:text-white text-navy-800 group-hover:text-primary-700"
                  >
                    {{ item.label }}
                  </div>
                  <div
                    @click="handleButtonClick"
                    class="group-hover:text-primary-700"
                  >
                    <ChevronUpIcon
                      v-if="isShowingContent"
                      class="w-6 h-6 stroke-2"
                    />
                    <ChevronDownIcon v-else class="w-6 h-6 stroke-2" />
                  </div>
                </div>
              </template>
              <template #content>
                <NavbarSubmenuItem
                  :padding="false"
                  class="px-0"
                  v-for="subitem in item.subMenuData"
                  :key="subitem.id"
                  :title="subitem.title"
                  :label="subitem.label"
                  :icon="subitem.icon"
                  :path-name="subitem.pathName"
                  @click="handleSubMenuClick"
                />
              </template>
            </CollapseView>
          </template>
        </div>
        <div class="mb-12 mt-4">
          <filled-button
            class="bg-primary-700 bg-opacity-[18%] py-2 dark:text-primary-500 text-primary-800 text-base hover:bg-primary-800 hover:text-white"
            label="Rewards Hub"
            type="iconLabel"
          >
            <template #icon>
              <GiftIcon class="stroke-2" />
            </template>
          </filled-button>
        </div>
        <div class="flex flex-col dark:text-white text-navy-800 gap-4">
          <template v-for="item in bottomIconData" :key="item.id">
            <div
              class="flex flex-row items-center cursor-pointer py-1.5 group"
              @click="item.click"
            >
              <component
                :is="item.icon"
                class="w-6 h-6 stroke-2 mr-2 group-hover:text-primary-700"
              />
              <div class="text-base font-medium group-hover:text-primary-700">
                {{ item.label }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="fixed max-sm:w-[calc(100%-32px)] mx-4 top-0 bottom-0 flex"
        @click="showLanguage = false"
        v-if="showLanguage"
      >
        <LanguageDropdown />
      </div>
      <div
        class="fixed max-sm:w-[calc(100%-32px)] mx-4 top-0 bottom-0 flex"
        @click="showDownload = false"
        v-if="showDownload"
      >
        <DownloadDropdown />
      </div>
    </div>
  </div>
</template>
<script setup>
import CollapseView from "@/components/Collapse/CollapseView.vue";
import {
  ChevronDownIcon,
  GiftIcon,
  SunIcon,
  GlobeAltIcon,
  ArrowDownTrayIcon,
  ChevronUpIcon,
} from "@heroicons/vue/24/outline";
import { defineEmits, computed, ref, defineProps, watch } from "vue";
import { useStore } from "vuex";
import NavbarSubmenuItem from "@/layout/AppNavbar/item/NavbarSubmenuItem.vue";
import LanguageDropdown from "./dropdown/LanguageDropdown.vue";
import DownloadDropdown from "./dropdown/DownloadDropdown.vue";
import { useRouter } from "vue-router";
import ConvertIcon from "@/assets/icons/common/ConvertIcon.vue";
import SpotTradingIcon from "@/assets/icons/common/SpotTradingIcon.vue";
import BlogIcon from "@/assets/icons/common/BlogIcon.vue";
import LaunchpadIcon from "@/assets/icons/common/LaunchpadIcon.vue";
import HelpIcon from "@/assets/icons/common/HelpIcon.vue";
import RewardsIcon from "@/assets/icons/common/RewardsIcon.vue";
import HandShakeIcon from "@/assets/icons/common/HandShakeIcon.vue";
import AnnouncementIcon from "@/assets/icons/announcement/AnnouncementIcon.vue";
import PeopleIcon from "@/assets/icons/common/PeopleIcon.vue";

const store = useStore();
const router = useRouter();
const showLanguage = ref(false);
const showDownload = ref(false);
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const props = defineProps(["isShow"]);
const emit = defineEmits(["closeClick"]);

watch(
  () => props.isShow,
  (newVal) => {
    if (newVal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }
);

const handleMenuClick = (pathName) => {
  router.push({ name: pathName });
  emit("closeClick");
};

const handleSubMenuClick = () => {
  emit("closeClick");
};

const handleThemeChange = () => {
  store.dispatch("toggleTheme", store.getters.currentTheme);
};

const handleShowLanguage = () => {
  showLanguage.value = !showLanguage.value;
};

const handleDownload = () => {
  showDownload.value = !showDownload.value;
};

const bottomIconData = [
  {
    id: 0,
    icon: SunIcon,
    label: "Dark Mode",
    click: handleThemeChange,
  },
  {
    id: 1,
    icon: GlobeAltIcon,
    label: "Language",
    click: handleShowLanguage,
  },
  {
    id: 2,
    icon: ArrowDownTrayIcon,
    label: "Download App",
    click: handleDownload,
  },
];

const navbarData = [
  {
    id: 0,
    label: "Buy Crypto",
    isSubMenu: false,
  },
  {
    id: 1,
    label: "Markets",
    pathName: "markets",
    isSubMenu: false,
  },
  {
    id: 2,
    label: "Trade",
    isSubMenu: true,
    alignment: "center",
    subMenuData: [
      {
        id: 1,
        title: "Convert",
        label: "Zero fees, no slippage",
        icon: ConvertIcon,
        pathName: "convert",
      },
      {
        id: 0,
        title: "Spot Trading",
        label: "Buy and cell crypto with ease",
        icon: SpotTradingIcon,
        pathName: "trading",
      },
    ],
  },
  {
    id: 3,
    label: "Referral",
    isSubMenu: false,
  },
  {
    id: 4,
    label: "More",
    isSubMenu: true,
    alignment: "center",
    subMenuData: [
      {
        id: 0,
        title: "Blog",
        label: "Blockchain and crypto information",
        icon: BlogIcon,
      },
      {
        id: 1,
        title: "Launchpad",
        label: "Buy and sell crypto with ease",
        icon: LaunchpadIcon,
        pathName: "launchpad",
      },
      {
        id: 2,
        title: "Help Center",
        label: "Zero fees, no slippage",
        icon: HelpIcon,
        pathName: "help-center",
      },
      {
        id: 3,
        title: "Rewards Hub",
        label: "Complete simple tasks to clain rewards deals",
        icon: RewardsIcon,
      },
      {
        id: 4,
        title: "Affiliate Links",
        label: "Refer friends to earn 20% commision",
        icon: HandShakeIcon,
      },
      {
        id: 5,
        title: "Announcements",
        label: "Latest activites and announcements",
        icon: AnnouncementIcon,
        pathName: "announcement",
      },
      {
        id: 6,
        title: "Career",
        label: "Zero fees, no slippage",
        icon: PeopleIcon,
        pathName: "career",
      },
    ],
  },
];
</script>
