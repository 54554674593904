import { socket } from "@/socket/socket";
import { isEmpty } from "@/utils/utils";

const channelToSubscription = new Map();
let firstHistoryBars = [];

const sendHistoryBars = (pair) => {
  if (firstHistoryBars.length > 0) {
    const channelString = `0~${pair}`;
    const subscriptionItem = channelToSubscription.get(channelString);
    if (subscriptionItem === undefined) {
      return;
    }

    //Check the bars have been loaded before
    if (subscriptionItem.lastDailyBar) {
      firstHistoryBars = [];
      return;
    }

    firstHistoryBars.reverse();
    firstHistoryBars.forEach((item) => {
      const lastDailyBar = subscriptionItem.lastDailyBar;
      let bar = {
        ...lastDailyBar,
        time: item.time * 1000,
        low: Number(item.low_f),
        high: Number(item.high_f),
        open: Number(item.open_f),
        close: Number(item.close_f),
        volume: Number(item.volume_f),
      };

      subscriptionItem.lastDailyBar = bar;

      subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
    });

    firstHistoryBars = [];
  }
};

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  // const channelString = `0~${symbolInfo.pro_name}~${'BTC'}~${'USDT'}`;
  const channelString = `0~${symbolInfo.ticker}`;
  socket.emit("subscribe", {
    type: "graph",
    event: `${symbolInfo.ticker}:1`,
  });
  socket.on("message", (message) => {
    if (isEmpty(message)) return;

    const data = Array.isArray(message) ? message[0] : message;

    switch (data.type) {
      case "graph":
        if (data.room) {
          const newBar = {
            time: data.data.time * 1000,
            low: Number(data.data.low_f),
            high: Number(data.data.high_f),
            open: Number(data.data.open_f),
            close: Number(data.data.close_f),
            volume: Number(data.data.volume_f),
          };
          onRealtimeCallback(newBar);
        }
        break;
    }
  });
  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // Already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscriberUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);

  sendHistoryBars(symbolInfo.ticker);

  //console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
}

// function getNextDailyBarTime(barTime) {
//     const date = new Date(barTime * 1000);
//     date.setDate(date.getDate() + 1);
//     return date.getTime() / 1000;
// }

export function unsubscribeFromStream(subscriberUID) {
  socket.emit("unsubscribe", {
    type: "graph",
  });
  // Find a subscription with id === subscriberUID
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // Unsubscribe from the channel if it is the last handler
        //console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
        // socket.emit('SubRemove', { subs: [channelString] });
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}
