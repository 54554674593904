<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4546 5.50781L15.0796 0.507812C14.9918 0.407526 14.8838 0.327086 14.7625 0.271853C14.6413 0.21662 14.5097 0.187864 14.3764 0.1875H5.62643C5.4932 0.187864 5.36158 0.21662 5.24033 0.271853C5.11909 0.327086 5.01101 0.407526 4.9233 0.507812L0.548304 5.50781C0.39537 5.68263 0.312776 5.908 0.316529 6.14024C0.320282 6.37248 0.410114 6.59506 0.568617 6.76484L9.31862 16.1398C9.40633 16.2338 9.51241 16.3086 9.63027 16.3598C9.74814 16.411 9.87527 16.4374 10.0038 16.4374C10.1323 16.4374 10.2594 16.411 10.3773 16.3598C10.4951 16.3086 10.6012 16.2338 10.6889 16.1398L19.4389 6.76484C19.5968 6.59447 19.6858 6.37157 19.6887 6.13933C19.6915 5.9071 19.6081 5.68205 19.4546 5.50781ZM16.685 5.1875H14.2202L11.8764 2.0625H13.9506L16.685 5.1875ZM5.61705 7.0625L7.27252 11.2031L3.40846 7.0625H5.61705ZM12.3671 7.0625L10.0014 12.9758L7.6358 7.0625H12.3671ZM8.12643 5.1875L10.0014 2.6875L11.8764 5.1875H8.12643ZM14.3858 7.0625H16.5944L12.7303 11.2031L14.3858 7.0625ZM6.05221 2.0625H8.12643L5.78268 5.1875H3.31784L6.05221 2.0625Z"
      fill="currentColor"
    />
  </svg>
</template>
