import { TOAST_DURATION } from "@/constants/constants";

const state = {
  isShowing: false,
  type: "error",
  message: "This is an error",
};

const mutations = {
  SET_TOAST(state, toast) {
    state.type = toast.type;
    state.message = toast.message;
    state.isShowing = toast.isShowing;
  },
  SHOW_TOAST(state, isShowing) {
    state.isShowing = isShowing;
  },
};

const actions = {
  setToast({ commit }, toast) {
    if (!toast.type || !toast.message) return;
    setTimeout(() => {
      commit("SHOW_TOAST", false);
    }, TOAST_DURATION);
    commit("SET_TOAST", {
      type: toast.type,
      message: toast.message,
      isShowing: true,
    });
  },
};

const getters = {
  toastInfo: (state) => ({ type: state.type, message: state.message }),
  showingToast: (state) => state.isShowing,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
