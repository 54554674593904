<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1225 7.84588 17.2657 5.78069 15.7425 4.2575C14.2193 2.7343 12.1541 1.87748 10 1.875ZM16.875 10C16.8755 10.634 16.788 11.2651 16.6148 11.875H13.6063C13.7979 10.6323 13.7979 9.36765 13.6063 8.125H16.6148C16.788 8.73493 16.8755 9.36597 16.875 10ZM7.96875 13.125H12.0313C11.631 14.4364 10.9362 15.6389 10 16.6406C9.06413 15.6387 8.36939 14.4362 7.96875 13.125ZM7.66407 11.875C7.44949 10.6342 7.44949 9.36579 7.66407 8.125H12.3422C12.5568 9.36579 12.5568 10.6342 12.3422 11.875H7.66407ZM3.125 10C3.12446 9.36597 3.21202 8.73493 3.38516 8.125H6.39375C6.20208 9.36765 6.20208 10.6323 6.39375 11.875H3.38516C3.21202 11.2651 3.12446 10.634 3.125 10ZM12.0313 6.875H7.96875C8.36898 5.5636 9.06377 4.36108 10 3.35938C10.9359 4.36134 11.6306 5.56378 12.0313 6.875ZM16.1195 6.875H13.3367C12.986 5.58821 12.3948 4.37943 11.5945 3.3125C12.5615 3.54479 13.4668 3.98342 14.2484 4.59832C15.03 5.21323 15.6693 5.98987 16.1227 6.875H16.1195ZM8.40547 3.3125C7.60516 4.37943 7.01403 5.58821 6.66328 6.875H3.87735C4.33068 5.98987 4.97006 5.21323 5.75163 4.59832C6.53321 3.98342 7.43851 3.54479 8.40547 3.3125ZM3.87735 13.125H6.66328C7.01403 14.4118 7.60516 15.6206 8.40547 16.6875C7.43851 16.4552 6.53321 16.0166 5.75163 15.4017C4.97006 14.7868 4.33068 14.0101 3.87735 13.125ZM11.5945 16.6875C12.3948 15.6206 12.986 14.4118 13.3367 13.125H16.1227C15.6693 14.0101 15.03 14.7868 14.2484 15.4017C13.4668 16.0166 12.5615 16.4552 11.5945 16.6875Z"
      fill="currentColor"
    />
  </svg>
</template>
