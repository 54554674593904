<template>
  <div
    class="h-[40px] rounded-xl pl-4 flex items-center overflow-hidden bg-navy-10 dark:bg-navy-700"
  >
    <img src="@/assets/icons/bubble_dark.svg" alt="Bubble icon" />
    <div class="text-xs ml-6" v-for="pair in TRADING_PAIRS" :key="pair.id">
      <label>{{ pair.name }}</label>
      <label
        :class="[
          getPercentage(pair) < 0 ? 'text-red-500' : 'text-green-400',
          'ml-2',
        ]"
      >
        {{ getPercentage(pair) }}%
      </label>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { TRADING_PAIRS } from "@/constants/constants";

const store = useStore();

const allPairs = computed(() => store.getters.allPairs);

const getPercentage = (pair) => {
  const resultPair = allPairs.value.find(
    (samplePair) => samplePair.name === pair.value
  );
  return resultPair ? resultPair.percent.toFixed(2) : "";
};
</script>
