<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.5625C8.33122 1.5625 6.69992 2.05735 5.31238 2.98448C3.92484 3.9116 2.84338 5.22936 2.20477 6.77111C1.56616 8.31286 1.39907 10.0094 1.72463 11.6461C2.05019 13.2828 2.85378 14.7862 4.03379 15.9662C5.2138 17.1462 6.71721 17.9498 8.35393 18.2754C9.99064 18.6009 11.6871 18.4338 13.2289 17.7952C14.7706 17.1566 16.0884 16.0752 17.0155 14.6876C17.9427 13.3001 18.4375 11.6688 18.4375 10C18.435 7.763 17.5453 5.61833 15.9635 4.03653C14.3817 2.45473 12.237 1.56498 10 1.5625ZM13.457 12.1312C13.8528 11.4908 14.0625 10.7529 14.0625 10C14.0625 9.24715 13.8528 8.50917 13.457 7.86875L15.2539 6.07187C16.1034 7.20532 16.5625 8.58358 16.5625 10C16.5625 11.4164 16.1034 12.7947 15.2539 13.9281L13.457 12.1312ZM7.8125 10C7.8125 9.56735 7.9408 9.14442 8.18116 8.78469C8.42153 8.42496 8.76317 8.14458 9.16288 7.97901C9.5626 7.81345 10.0024 7.77013 10.4268 7.85453C10.8511 7.93894 11.2409 8.14728 11.5468 8.4532C11.8527 8.75913 12.0611 9.14891 12.1455 9.57324C12.2299 9.99757 12.1866 10.4374 12.021 10.8371C11.8554 11.2368 11.575 11.5785 11.2153 11.8188C10.8556 12.0592 10.4326 12.1875 10 12.1875C9.41984 12.1875 8.86344 11.957 8.45321 11.5468C8.04297 11.1366 7.8125 10.5802 7.8125 10ZM13.9281 4.74609L12.1313 6.54297C11.4908 6.14717 10.7529 5.93753 10 5.93753C9.24715 5.93753 8.50917 6.14717 7.86875 6.54297L6.07188 4.74609C7.20532 3.89665 8.58358 3.4375 10 3.4375C11.4164 3.4375 12.7947 3.89665 13.9281 4.74609ZM4.7461 6.07422L6.54297 7.87109C6.14718 8.51151 5.93753 9.24949 5.93753 10.0023C5.93753 10.7552 6.14718 11.4932 6.54297 12.1336L4.7461 13.9305C3.89665 12.797 3.4375 11.4188 3.4375 10.0023C3.4375 8.58592 3.89665 7.20766 4.7461 6.07422ZM6.07422 15.2562L7.8711 13.4594C8.51151 13.8552 9.24949 14.0648 10.0023 14.0648C10.7552 14.0648 11.4932 13.8552 12.1336 13.4594L13.9305 15.2562C12.797 16.1057 11.4188 16.5648 10.0023 16.5648C8.58592 16.5648 7.20766 16.1057 6.07422 15.2562Z"
      fill="currentColor"
    />
  </svg>
</template>
