<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.625 4.89354L10.75 1.13026C10.5202 1.00381 10.2623 0.9375 10 0.9375C9.73775 0.9375 9.47975 1.00381 9.25 1.13026L2.375 4.89354C2.129 5.02814 1.92375 5.22646 1.78079 5.4677C1.63784 5.70894 1.56244 5.98421 1.5625 6.26463V13.7349C1.56244 14.0154 1.63784 14.2906 1.78079 14.5319C1.92375 14.7731 2.129 14.9714 2.375 15.106L9.25 18.8693C9.47971 18.9959 9.73772 19.0623 10 19.0623C10.2623 19.0623 10.5203 18.9959 10.75 18.8693L17.625 15.106C17.871 14.9714 18.0762 14.7731 18.2192 14.5319C18.3622 14.2906 18.4376 14.0154 18.4375 13.7349V6.26463C18.4376 5.98421 18.3622 5.70894 18.2192 5.4677C18.0762 5.22646 17.871 5.02814 17.625 4.89354ZM10 2.85682L15.625 5.93729L13.9508 6.85369L8.32578 3.77401L10 2.85682ZM10 9.01541L4.375 5.93729L6.37187 4.84354L11.9969 7.92244L10 9.01541ZM3.4375 7.56151L9.0625 10.6396V16.6295L3.4375 13.5498V7.56151ZM10.9375 16.6295V10.6396L12.8125 9.61385V11.8748C12.8125 12.1234 12.9113 12.3619 13.0871 12.5377C13.2629 12.7135 13.5014 12.8123 13.75 12.8123C13.9986 12.8123 14.2371 12.7135 14.4129 12.5377C14.5887 12.3619 14.6875 12.1234 14.6875 11.8748V8.58729L16.5625 7.56151V13.5498L10.9375 16.6295Z"
      fill="currentColor"
    />
  </svg>
</template>
