<template>
  <svg
    v-if="currentTheme === 'dark'"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3616_79483)">
      <path
        d="M21.2727 47.2733H16.9092C15.7042 47.2733 14.7273 46.2966 14.7273 45.0914V22.5462C14.7273 21.3412 15.7039 20.3643 16.9092 20.3643H21.2727C22.4777 20.3643 23.4546 21.3409 23.4546 22.5462V45.0916C23.4546 46.2966 22.478 47.2735 21.2727 47.2735V47.2733Z"
        fill="#333C57"
      />
      <path
        d="M32.8396 47.2868L28.476 47.2591C27.271 47.2514 26.3003 46.2684 26.3081 45.0634L26.4194 27.6094C26.4272 26.4044 27.4101 25.4337 28.6151 25.4415L32.9787 25.4692C34.1837 25.4769 35.1543 26.4598 35.1466 27.6648L35.0352 45.1189C35.0275 46.3239 34.0445 47.2945 32.8396 47.2868Z"
        fill="#6F7DAA"
      />
      <path
        d="M32.9092 25.4552H28.5456C27.3406 25.4552 26.3637 26.4319 26.3637 27.6371V42.9097C28.3718 42.9097 30.0001 41.2814 30.0001 39.2733V29.7525C30.0001 29.3983 30.2569 29.1001 30.6067 29.0412C32.2816 28.7592 33.5609 27.3271 33.6235 25.5851C33.3988 25.5066 33.1609 25.455 32.9094 25.455L32.9092 25.4552Z"
        fill="#333C57"
      />
      <path
        d="M28.9092 32.3642V28.0006H31.0909"
        stroke="white"
        stroke-width="2.18191"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.5455 47.2732H40.1819C38.9769 47.2732 38 46.2966 38 45.0913V18.1825C38 16.9775 38.9767 16.0006 40.1819 16.0006H44.5455C45.7505 16.0006 46.7274 16.9773 46.7274 18.1825V45.0916C46.7274 46.2966 45.7507 47.2735 44.5455 47.2735V47.2732Z"
        fill="#333C57"
      />
      <path
        d="M47.8183 44.3642H46.7274V34.1823C44.7193 34.1823 43.091 35.8106 43.091 37.8187V43.7031C43.091 44.0572 42.8342 44.3555 42.4844 44.4143C40.8095 44.6964 39.5302 46.2587 39.4675 48.0006C39.4545 48.0006 47.8181 48.0006 47.8181 48.0006C48.8225 48.0006 49.6362 47.1867 49.6362 46.1825C49.6362 45.1781 48.8223 44.3644 47.8181 44.3644L47.8183 44.3642Z"
        fill="#6F7DAA"
      />
    </g>
    <defs>
      <clipPath id="clip0_3616_79483">
        <rect
          width="34.909"
          height="32"
          fill="white"
          transform="translate(14.7273 16)"
        />
      </clipPath>
    </defs>
  </svg>
  <svg
    v-else
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7166_115430)">
      <path
        d="M21.2727 47.2732H16.9092C15.7042 47.2732 14.7273 46.2966 14.7273 45.0913V22.5461C14.7273 21.3411 15.7039 20.3642 16.9092 20.3642H21.2727C22.4777 20.3642 23.4546 21.3409 23.4546 22.5461V45.0916C23.4546 46.2966 22.478 47.2735 21.2727 47.2735V47.2732Z"
        fill="#424D70"
      />
      <path
        d="M32.8396 47.2868L28.476 47.2591C27.271 47.2514 26.3003 46.2684 26.3081 45.0634L26.4194 27.6094C26.4272 26.4044 27.4101 25.4337 28.6151 25.4415L32.9787 25.4692C34.1837 25.4769 35.1543 26.4598 35.1466 27.6648L35.0352 45.1189C35.0275 46.3239 34.0445 47.2945 32.8396 47.2868Z"
        fill="#6F7DAA"
      />
      <path
        d="M32.9092 25.4552H28.5456C27.3406 25.4552 26.3637 26.4319 26.3637 27.6371V42.9097C28.3718 42.9097 30.0001 41.2814 30.0001 39.2733V29.7525C30.0001 29.3983 30.2569 29.1001 30.6067 29.0412C32.2816 28.7592 33.5609 27.3271 33.6235 25.5851C33.3988 25.5066 33.1609 25.455 32.9094 25.455L32.9092 25.4552Z"
        fill="#424D70"
      />
      <path
        d="M28.9092 32.3641V28.0005H31.0909"
        stroke="white"
        stroke-width="2.18191"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.5455 47.2732H40.1819C38.9769 47.2732 38 46.2966 38 45.0913V18.1825C38 16.9775 38.9767 16.0006 40.1819 16.0006H44.5455C45.7505 16.0006 46.7274 16.9773 46.7274 18.1825V45.0916C46.7274 46.2966 45.7507 47.2735 44.5455 47.2735V47.2732Z"
        fill="#424D70"
      />
      <path
        d="M47.8183 44.3641H46.7274V34.1822C44.7193 34.1822 43.091 35.8105 43.091 37.8186V43.703C43.091 44.0572 42.8342 44.3554 42.4844 44.4142C40.8095 44.6963 39.5302 46.2586 39.4675 48.0005C39.4545 48.0005 47.8181 48.0005 47.8181 48.0005C48.8225 48.0005 49.6362 47.1866 49.6362 46.1824C49.6362 45.178 48.8223 44.3643 47.8181 44.3643L47.8183 44.3641Z"
        fill="#6F7DAA"
      />
    </g>
    <defs>
      <clipPath id="clip0_7166_115430">
        <rect
          width="34.909"
          height="32"
          fill="white"
          transform="translate(14.7273 16)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup></script>
