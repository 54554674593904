<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6875 9.37365C19.6862 8.29659 19.2578 7.26399 18.4962 6.5024C17.7346 5.7408 16.702 5.31239 15.625 5.31115H12.525C12.2562 5.29474 8.4156 4.99865 4.75466 1.92834C4.52693 1.73714 4.24937 1.61489 3.95457 1.57596C3.65978 1.53702 3.36 1.58301 3.09044 1.70852C2.82087 1.83403 2.59272 2.03385 2.43277 2.28452C2.27283 2.53519 2.18773 2.8263 2.18747 3.12365V15.6236C2.18559 15.9213 2.26965 16.2133 2.42957 16.4644C2.58949 16.7155 2.81847 16.9151 3.08903 17.0393C3.35844 17.1665 3.65877 17.2134 3.95413 17.1745C4.24949 17.1355 4.5274 17.0124 4.75466 16.8197C6.62852 15.2445 8.85788 14.1494 11.25 13.6291V15.676C11.2499 15.9332 11.3134 16.1864 11.4347 16.4132C11.556 16.6399 11.7314 16.8333 11.9453 16.976C11.98 16.9994 12.0162 17.0202 12.0539 17.0385L13.182 17.5901C13.3905 17.7149 13.6253 17.7893 13.8676 17.8075C14.11 17.8257 14.3532 17.7871 14.578 17.6948C14.8028 17.6025 15.003 17.459 15.1627 17.2758C15.3223 17.0926 15.437 16.8746 15.4976 16.6393L16.364 13.3721C17.2976 13.1983 18.1409 12.7034 18.7479 11.9731C19.3549 11.2428 19.6873 10.3232 19.6875 9.37365ZM4.06247 14.9697V3.7799C6.88825 5.97287 9.70075 6.75646 11.25 7.03459V11.7174C9.70075 11.9924 6.88825 12.776 4.06247 14.9697ZM13.7804 15.7955L13.125 15.4752V13.4361H14.4062L13.7804 15.7955ZM15.625 11.5611H13.125V7.18615H15.625C16.2051 7.18615 16.7615 7.41662 17.1718 7.82685C17.582 8.23709 17.8125 8.79349 17.8125 9.37365C17.8125 9.95381 17.582 10.5102 17.1718 10.9204C16.7615 11.3307 16.2051 11.5611 15.625 11.5611Z"
      fill="currentColor"
    />
  </svg>
</template>
