<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4531 7.50781L15.0781 2.50781C14.9904 2.40753 14.8823 2.32709 14.7611 2.27185C14.6398 2.21662 14.5082 2.18786 14.375 2.1875H5.62496C5.49173 2.18786 5.36011 2.21662 5.23887 2.27185C5.11763 2.32709 5.00955 2.40753 4.92184 2.50781L0.546839 7.50781C0.393905 7.68263 0.311311 7.908 0.315064 8.14024C0.318817 8.37248 0.408649 8.59506 0.567152 8.76484L9.31715 18.1398C9.40486 18.2338 9.51094 18.3086 9.62881 18.3598C9.74667 18.411 9.8738 18.4374 10.0023 18.4374C10.1308 18.4374 10.2579 18.411 10.3758 18.3598C10.4937 18.3086 10.5998 18.2338 10.6875 18.1398L19.4375 8.76484C19.5953 8.59447 19.6843 8.37157 19.6872 8.13933C19.6901 7.9071 19.6067 7.68205 19.4531 7.50781ZM16.6836 7.1875H14.2187L11.875 4.0625H13.9492L16.6836 7.1875ZM5.61559 9.0625L7.27106 13.2031L3.407 9.0625H5.61559ZM12.3656 9.0625L9.99996 14.9758L7.63434 9.0625H12.3656ZM8.12496 7.1875L9.99996 4.6875L11.875 7.1875H8.12496ZM14.3843 9.0625H16.5929L12.7289 13.2031L14.3843 9.0625ZM6.05075 4.0625H8.12496L5.78121 7.1875H3.31637L6.05075 4.0625Z"
      fill="currentColor"
    />
  </svg>
</template>
