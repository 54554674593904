<template>
  <dropdown-select>
    <template #header="{ handleContentClick }">
      <div>
        <liner-pair-detail @click-content="handleContentClick" />
        <Cog6ToothIcon
          class="absolute right-3 top-4 w-6 h-6 dark:text-navy-100 text-navy-200 cursor-pointer"
          @click="handleShowChartSettings"
        />
      </div>
    </template>
    <template #menu>
      <div
        class="border border-navy-10 dark:border-navy-400 rounded-2xl overflow-hidden box-border"
      >
        <pair-dropdown-menu
          :all-pair-items="allPairItems"
          @click-item="handleClickMenuItem"
        />
      </div>
    </template>
  </dropdown-select>
  <ChartSettings
    :is-open="isOpenChartSetting"
    @close="handleCloseChartSetting"
  />
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
import LinerPairDetail from "@/common/PairDropdown/LinerPairDetail.vue";
import PairDropdownMenu from "@/common/PairDropdown/PairDropdownMenu.vue";
import { Cog6ToothIcon } from "@heroicons/vue/24/outline";
import ChartSettings from "@/views/Trading/ChartSettings/ChartSettings.vue";

const router = useRouter();
defineProps(["allPairItems"]);

const isOpenChartSetting = ref(false);

const handleClickMenuItem = (name) => {
  router.push({
    name: "trading",
    params: {
      pair: name,
    },
  });
};

const handleShowChartSettings = () => {
  isOpenChartSetting.value = true;
};

const handleCloseChartSetting = () => {
  isOpenChartSetting.value = false;
};
</script>
