<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8409 17.8274L17.7225 11.4591C17.9947 11.1656 18.2086 10.8184 18.3544 10.4417C18.4905 10.0798 18.5634 9.69828 18.5586 9.31188C18.5537 8.93037 18.4759 8.55375 18.3301 8.20648C18.019 7.47769 17.4163 6.91521 16.6726 6.66576C16.3129 6.54348 15.9288 6.49456 15.5497 6.52391C15.1608 6.55326 14.7865 6.65597 14.4366 6.82716C14.072 7.00814 13.7463 7.25759 13.4741 7.56084L7.6265 14.1248C7.36888 14.4085 7.17445 14.746 7.04806 15.1079C6.9314 15.4503 6.87793 15.8171 6.90224 16.1791C6.94112 16.9177 7.28138 17.6073 7.84038 18.0915C8.12231 18.3361 8.44799 18.522 8.80283 18.6345C9.14795 18.747 9.51252 18.791 9.87222 18.7665C10.6257 18.7176 11.3256 18.3801 11.8409 17.8274Z"
      fill="currentColor"
    />
    <path
      d="M9.74088 1.20312C11.3498 1.20312 12.6525 2.51396 12.6525 4.13294V15.862C12.6525 17.4809 11.3498 18.7918 9.74088 18.7918C8.13193 18.7918 6.82922 17.4809 6.82922 15.862V4.13783C6.82922 2.51885 8.13193 1.20312 9.74088 1.20312Z"
      fill="currentColor"
    />
    <path
      d="M9.55136 1.21919C9.15763 1.24854 8.77848 1.35614 8.4285 1.53712C8.05908 1.72787 7.7334 1.98711 7.46605 2.30014L2.03162 8.72225C1.27332 9.62222 1.11777 10.889 1.63789 11.9455C2.35243 13.3884 4.08776 13.9754 5.52171 13.2564C5.85711 13.0901 6.15848 12.8553 6.40638 12.5716L11.8797 6.34514C12.4193 5.73374 12.7061 4.93159 12.6817 4.11477C12.672 3.71858 12.5797 3.32729 12.4241 2.96534C12.2637 2.59361 12.0255 2.25612 11.7242 1.98221C11.4276 1.70831 11.0777 1.49799 10.6985 1.36593C10.3291 1.23876 9.94023 1.18984 9.55136 1.21919Z"
      fill="currentColor"
    />
  </svg>
</template>
