<template>
  <div class="px-4 pt-2">
    <div id="tv_chart_container" />
  </div>
</template>

<script setup>
import { watch, computed } from "vue";
import { useStore } from "vuex";
import Datafeed from "@/views/Trading/Chart/datafeeds/datafeeds";
import TraderPrepare from "@/utils/TraderPrepare";
import { COMPANY_NAME } from "@/constants/constants";
import { mapUtcToTradingViewTimezone } from "../../../utils/utils";

const store = useStore();
const selectedTimezone = computed(() => store.getters.chartBasis);
const currentTheme = computed(() => store.getters.currentTheme);
const selectedPairName = computed(
  () => store.getters.selectedPair?.pair_name ?? "BTC/USDC"
);

watch([currentTheme, selectedPairName], () => {
  generateChart(selectedPairName.value, currentTheme.value);
});

watch([selectedTimezone], () => {
  if (window.tvWidget) {
    window.tvWidget
      .chart()
      .setTimezone(mapUtcToTradingViewTimezone(selectedTimezone.value));
  }
});

/**
 * Configs
 *
 * @type {boolean}
 */
const toPlainString = (num) => {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
};

const customRound_ = (number) => {
  let precision = TraderPrepare.fitNumLength(number, 7, false);
  const str = toPlainString(number);
  let arr = str.split(".");
  let integerPart = arr[0];
  let decimalPart = arr[1];
  if (+integerPart < 1) {
    const zerosLeft = String(decimalPart).match(/^0+/, "")?.[0] ?? null;
    if (zerosLeft && zerosLeft.length >= precision - 3) {
      precision = 8;
    }
  }
  return precision;
};

const generateChart = (pairName, theme) => {
  if (document.getElementById("tv_chart_container")) {
    // eslint-disable-next-line no-undef
    window.tvWidget = new TradingView.widget({
      // debug: true, // uncomment this line to see Library errors and warnings in the console
      // fullscreen: true,
      symbol: `${COMPANY_NAME}:${pairName}`,
      interval: "1h",
      container: "tv_chart_container",
      width: "100%",
      //	BEWARE: no trailing slash is expected in feed URL
      // eslint-disable-next-line no-undef
      datafeed: Datafeed,
      library_path: "/charting_library-master/charting_library/",
      locale: "en",
      timezone: mapUtcToTradingViewTimezone(selectedTimezone.value),
      hide_top_toolbar: true,
      studies_overrides: {},
      time_frames: [
        { text: "1d", resolution: "1", title: "1m" },
        { text: "5d", resolution: "5", title: "5m" },
        { text: "1M", resolution: "15", title: "15m" },
        { text: "2M", resolution: "30", title: "30m" },
        { text: "3M", resolution: "60", title: "1h" },
        { text: "1Y", resolution: "D", title: "1d" },
        { text: "5Y", resolution: "W", title: "1w" },
      ],

      disabled_features: [
        "header_saveload",
        "header_compare",
        "header_undo_redo",
        "header_settings",
        "left_toolbar",
        "edit_buttons_in_legend",
        "context_menus",
        "control_bar",
        "display_market_status",
        "symbol_info",
        "go_to_date",
        "timezone_menu",
        "header_symbol_search",
        "header_fullscreen_button",
        "header_resolutions",
        "header_interval_dialog_button",
        "header_chart_type",
        "header_indicators",
        "header_screenshot",
        "header_undo_redo",
        "use_localstorage_for_settings",
        "save_chart_properties_to_local_storage",
      ],
      client_id: "exzi.com",
      user_id: "public_user_id",
      custom_css_url: "/charting_library-master/style.css",

      overrides: {
        "paneProperties.background": theme === "light" ? "#FFFFFF" : "#151823",
        "paneProperties.backgroundType": "solid",
      },

      custom_formatters: {
        priceFormatterFactory: (symbolInfo) => {
          if (symbolInfo === null) {
            return null;
          }
          if (symbolInfo.format === "volume") {
            return {
              format: (price) => {
                const absPrice = Math.abs(price);
                const precision = customRound_(absPrice);
                return price.toFixed(precision).replace(/\.?0+$/, "");
              },
            };
          }
          return null; // The default formatter will be used.
        },
      },
      theme: theme,
    });
    window.frames[0].focus();
  }
};

// onMounted(() => {
//   generateChart(selectedPairName.value, currentTheme.value);
// });
</script>
