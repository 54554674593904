<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 19C16.7614 19 19 16.7614 19 14C19 11.2386 16.7614 9 14 9C11.2386 9 9 11.2386 9 14C9 16.7614 11.2386 19 14 19ZM14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0703 6.05344C12.223 5.39326 11.1574 5 10 5C7.23858 5 5 7.23858 5 10C5 11.1574 5.39326 12.223 6.05344 13.0703C6.01814 13.3753 6 13.6855 6 14C6 14.6535 6.07835 15.2886 6.22617 15.8966C4.2858 14.6522 3 12.4763 3 10C3 6.13401 6.13401 3 10 3C12.4763 3 14.6522 4.2858 15.8966 6.22617C15.2886 6.07835 14.6535 6 14 6C13.6855 6 13.3753 6.01814 13.0703 6.05344Z"
      fill="currentColor"
    />
    <path
      d="M22 6.3194V3.59246C22 3.41166 21.9282 3.23826 21.8003 3.1104C21.6725 2.98256 21.4991 2.91073 21.3183 2.91073C21.1375 2.91073 20.9641 2.98256 20.8362 3.1104C20.7084 3.23826 20.6365 3.41166 20.6365 3.59246V4.67188L18.1658 2.20002C18.0377 2.07195 17.864 2 17.6829 2C17.5018 2 17.3281 2.07195 17.2 2.20002C17.072 2.32809 17 2.5018 17 2.68292C17 2.7726 17.0177 2.8614 17.052 2.94426C17.0863 3.02711 17.1366 3.1024 17.2 3.16581L19.6707 5.63767H18.5913C18.4105 5.63767 18.2371 5.70949 18.1093 5.83734C17.9814 5.96519 17.9096 6.13859 17.9096 6.3194C17.9096 6.50021 17.9814 6.67361 18.1093 6.80146C18.2371 6.92931 18.4105 7.00114 18.5913 7.00114H21.3183C21.4991 7.00114 21.6725 6.92931 21.8003 6.80146C21.9282 6.67361 22 6.50021 22 6.3194Z"
      fill="currentColor"
    />
    <path
      d="M2 17.6806V20.4075C2 20.5883 2.07183 20.7617 2.19968 20.8896C2.32753 21.0174 2.50093 21.0893 2.68173 21.0893C2.86254 21.0893 3.03594 21.0174 3.16379 20.8896C3.29164 20.7617 3.36347 20.5883 3.36347 20.4075V19.3281L5.83419 21.8C5.96226 21.9281 6.13596 22 6.31708 22C6.4982 22 6.67191 21.9281 6.79998 21.8C6.92805 21.6719 7 21.4982 7 21.3171C7 21.2274 6.98234 21.1386 6.94802 21.0557C6.9137 20.9729 6.86339 20.8976 6.79998 20.8342L4.32926 18.3623H5.40867C5.58948 18.3623 5.76288 18.2905 5.89073 18.1627C6.01858 18.0348 6.09041 17.8614 6.09041 17.6806C6.09041 17.4998 6.01858 17.3264 5.89073 17.1985C5.76288 17.0707 5.58948 16.9989 5.40867 16.9989H2.68173C2.50093 16.9989 2.32753 17.0707 2.19968 17.1985C2.07183 17.3264 2 17.4998 2 17.6806Z"
      fill="currentColor"
    />
  </svg>
</template>
