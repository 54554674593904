<template>
  <div class="relative">
    <div
      class="absolute left-0 h-full w-36 sm:w-80 bg-gradient-to-r to-transparent -z-40 dark:from-navy-800 from-white"
    ></div>
    <div
      class="absolute right-0 h-full w-36 sm:w-80 bg-gradient-to-l to-transparent -z-40 dark:from-navy-800 from-white"
    ></div>
    <div
      class="sm:block absolute w-full bottom-0 h-[400px] -z-50 overflow-hidden"
    >
      <img
        :src="[
          currentTheme === 'dark'
            ? 'assets/images/home/background_lines.svg'
            : 'assets/images/home/background_lines_light.svg',
        ]"
        class="w-full h-full object-cover absolute top-0 left-0"
      />
    </div>
    <div class="sm:block absolute w-full h-full -z-50 overflow-hidden">
      <img
        :src="[
          currentTheme === 'dark'
            ? 'assets/images/home/background_ellipse_1.svg'
            : 'assets/images/home/background_ellipse_1_light.svg',
        ]"
        class="w-auto h-auto object-cover absolute bottom-0 left-0 top-0 my-auto"
      />
    </div>
    <div
      class="mt-40 sm:mt-52 flex flex-col items-center justify-center mx-auto self-center px-4"
    >
      <div
        class="text-lg font-medium text-center dark:text-navy-100 text-navy-200"
      >
        Why choose EXZi
      </div>
      <div
        class="text-[40px] font-semibold text-center mb-4 sm:text-5xl dark:text-white text-navy-800"
      >
        Trade like a pro
      </div>
      <div
        class="text-lg sm:text-xl font-normal text-center max-w-[864px] leading-8 dark:text-white text-navy-400"
      >
        EXZi's combined focus on clarity, efficiency, and user empowerment
        through its dashboard and trading interface displays the commitment to
        setting industry standards and optimizing the trading journey for its
        users.
      </div>
      <div
        class="sm:flex flex-row gap-6 hidden mt-11 flex-wrap items-center justify-center"
      >
        <DownloadCard
          v-for="item in downlaodCardData"
          :key="item.id"
          :top-label="item.topLabel"
          :mainlabel="item.mainLabel"
          :icon="item.icon"
        />
      </div>
      <div class="hidden sm:block my-12">
        <img
          :src="[
            currentTheme === 'dark'
              ? 'assets/images/home/trade_logo.svg'
              : 'assets/images/home/trade_logo_light.svg',
          ]"
        />
      </div>
      <div class="sm:hidden my-12">
        <img
          :src="[
            currentTheme === 'dark'
              ? 'assets/images/home/phone_img.png'
              : 'assets/images/home/phone_img_light.png',
          ]"
        />
      </div>
      <div class="sm:hidden flex flex-row items-center justify-around gap-11">
        <div
          v-for="item in iconOnlyData"
          :key="item.icon"
          class="w-[72px] h-16 flex items-center justify-center bg-primary-200 dark:bg-transparent"
        >
          <component
            :is="item.icon"
            class="w-10 h-10 dark:text-white text-primary-700"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import AppleStoreIcon from "@/assets/icons/home/AppleStoreIcon.vue";
import DownloadCard from "@/views/Home/Cards/DownloadCard.vue";
import QrCodeIcon from "@/assets/icons/home/QrCodeIcon.vue";
import GooglePlayIcon from "@/assets/icons/home/GooglePlayIcon.vue";

const downlaodCardData = [
  {
    id: 0,
    topLabel: "Download on the",
    mainLabel: "Apple Store",
    icon: AppleStoreIcon,
  },
  {
    id: 1,
    topLabel: "Get it on",
    mainLabel: "Google Play",
    icon: GooglePlayIcon,
  },
  {
    id: 2,
    topLabel: "Scan QR",
    mainLabel: "iOS & Android",
    icon: QrCodeIcon,
  },
];

const iconOnlyData = [
  {
    id: 0,
    icon: AppleStoreIcon,
  },
  {
    id: 1,
    icon: GooglePlayIcon,
  },
  {
    id: 2,
    icon: QrCodeIcon,
  },
];
</script>
