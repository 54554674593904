<template>
  <div
    :class="[
      'flex flex-row items-center justify-start',
      props.value < 0 ? 'text-red-500' : 'text-trade-buy',
    ]"
  >
    <div class="mr-1">{{ addPlus(props.value.toFixed(2)) }}%</div>
    <TriUpIcon v-if="props.value > 0" />
    <TriDownIcon v-else />
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { addPlus } from "@/utils/NumberFormating";
import TriUpIcon from "@/assets/icons/TriUpIcon.vue";
import TriDownIcon from "@/assets/icons/TriDownIcon.vue";
const props = defineProps(["value"]);
</script>
