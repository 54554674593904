import io from "socket.io-client";
import store from "@/store";
import { isEmpty } from "@/utils/utils";
import { SOCKET_URL } from "@/constants/constants";

const socket = io.connect(SOCKET_URL);

socket.on("message", (message) => {
  if (isEmpty(message)) return;

  const data = Array.isArray(message) ? message[0] : message;

  switch (data.type) {
    case "book_snapshot":
      store.dispatch("setOrderBookList", data.data);
      break;
    case "hist":
      if (!isEmpty(message[0]) && !isEmpty(message[0].data)) {
        store.dispatch("addOrderBookHist", message[0].data);
      }
      break;
    case "user":
      switch (data.data.data_type) {
        case "order": {
          const order = data.data.order;
          const status = order.status;
          if (status != -1) {
            store.dispatch("fetchBalancesVolume");
            store.dispatch("fetchOpenOrderHistory");
            store.dispatch("fetchOrderHistory");
          }
          break;
        }
      }

      break;
  }
});

export { socket };
