<template>
  <div class="font-sans font-medium dark:text-white text-navy-800">
    <not-verified />

    <router-view />
    <router-view name="profile" />

    <toast-view
      v-if="isShowingToast"
      :type="toastInfo.type"
      :message="toastInfo.message"
    />
    <LoadingSpinner v-if="isLoading" />

    <ModalsContainer />
  </div>
</template>

<script setup>
import { computed, watchEffect, watch, onMounted } from "vue";
import { useStore } from "vuex";
import NotVerified from "@/views/Error/NotVerified.vue";
import LoadingSpinner from "@/components/Spinner/LoadingSpinner.vue";
import { ModalsContainer } from "vue-final-modal";

const store = useStore();
const isLoading = computed(() => store.getters.isLoading);
const isShowingToast = computed(() => store.getters.showingToast);
const toastInfo = computed(() => store.getters.toastInfo);
const isLoggedIn = computed(() => store.getters.isLoggedIn);

onMounted(() => {
  if (isLoggedIn.value) {
    store.dispatch("fetchVerificationInfo");
    store.dispatch("fetchAccountInfo");
    store.dispatch("fetchBalancesVolume");
    store.dispatch("fetchConfig");
  }
  store.dispatch("fetchActiveCurrencies");
  store.dispatch("fetchPairData");
});

watchEffect(() => {
  const activeCurrencies = store.getters.activeCurrencies;
  store.dispatch(
    "setBalancesVolumeModified",
    (store.getters.balancesVolume.list ?? [])
      .filter((item) => item.deposit_type === "spot")
      .map((item) => {
        const currency = activeCurrencies[item.currency.id];
        return {
          ...item,
          icon: currency?.icon,
          decimal: currency?.decimal,
          balance_available_f:
            item.balance_available / Math.pow(10, currency?.decimal),
          total:
            item.balances.USDT == 0 ? item.balances.BTC : item.balances.USDT,
        };
      })
      .reduce((acc, cur) => ({ ...acc, [cur.currency.id]: cur }), {})
  );
  const favoritePairs = store.getters.favoritePairs;
  store.dispatch(
    "setAllPairsModified",
    (store.getters.allPairs ?? []).map((item) => ({
      ...item,
      favorite: favoritePairs.includes(item.name),
    }))
  );
});

watch(isLoggedIn, async (nextValue) => {
  if (nextValue) {
    store.dispatch("fetchVerificationInfo");
    store.dispatch("fetchAccountInfo");
    store.dispatch("fetchBalancesVolume");
    store.dispatch("fetchConfig");
  }
});
</script>
