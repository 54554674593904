<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2542_56524)">
      <path
        d="M23.0344 9.84882C19.4344 9.84882 15.8344 9.84882 12.2297 9.84882C12.2297 11.3441 12.2297 12.8348 12.2297 14.3301C14.3157 14.3301 16.4016 14.3301 18.4876 14.3301C18.2438 15.7598 17.3954 17.0723 16.1907 17.8738C15.4313 18.3848 14.5594 18.7129 13.6641 18.8723C12.7594 19.0269 11.8266 19.0457 10.9266 18.8629C10.0079 18.6801 9.13599 18.2957 8.37193 17.7613C7.15318 16.9035 6.22037 15.6473 5.74224 14.2363C5.25474 12.8019 5.25006 11.1988 5.74224 9.76444C6.08443 8.75194 6.65631 7.81913 7.40631 7.05975C8.33443 6.11288 9.53912 5.43319 10.8329 5.15663C11.9438 4.91756 13.1157 4.96444 14.2032 5.29256C15.1266 5.57381 15.9797 6.08006 16.6782 6.74569C17.386 6.04256 18.0844 5.33944 18.7922 4.63631C19.1626 4.25663 19.5516 3.89569 19.9079 3.50663C18.8391 2.51756 17.5829 1.72069 16.2141 1.21913C13.7485 0.309752 10.9641 0.291002 8.47974 1.1535C5.67662 2.10975 3.29537 4.20038 1.97349 6.84882C1.51412 7.75819 1.17662 8.73319 0.975056 9.73631C0.468806 12.2254 0.820369 14.8832 1.97349 17.1566C2.71881 18.6379 3.79224 19.9551 5.09068 20.991C6.31881 21.9707 7.74849 22.6926 9.26256 23.0957C11.1751 23.6066 13.2094 23.5973 15.136 23.1566C16.8751 22.7582 18.5204 21.9238 19.8376 20.7098C21.2251 19.4348 22.2188 17.7519 22.7438 15.9426C23.311 13.9738 23.3907 11.8691 23.0344 9.84882Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2542_56524">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
