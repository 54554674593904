<template>
  <div
    class="relative sm:w-[120px] sm:h-[120px] w-24 h-24 dark:bg-navy-200 bg-primary-300 rounded-full"
  >
    <div
      class="absolute sm:w-[100px] sm:h-[100px] w-20 h-20 dark:bg-navy-50 bg-primary-400 rounded-full sm:top-[10px] sm:left-[10px] top-2 left-2"
    >
      <div
        class="sm:w-[80px] sm:h-[80px] w-16 h-16 absolute dark:bg-white bg-navy-10 rounded-full sm:top-[10px] sm:left-[10px] top-2 left-2"
      >
        <img
          :src="props.imgUrl"
          class="rounded-full w-[53px] h-[53px] absolute my-auto mx-auto top-0 bottom-0 left-0 right-0"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["imgUrl"]);
</script>
