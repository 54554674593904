<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8125 11.2507V16.2507C17.8125 16.4994 17.7137 16.7378 17.5379 16.9137C17.3621 17.0895 17.1236 17.1882 16.875 17.1882H3.125C2.87636 17.1882 2.6379 17.0895 2.46209 16.9137C2.28627 16.7378 2.1875 16.4994 2.1875 16.2507V11.2507C2.1875 11.0021 2.28627 10.7636 2.46209 10.5878C2.6379 10.412 2.87636 10.3132 3.125 10.3132C3.37364 10.3132 3.6121 10.412 3.78791 10.5878C3.96373 10.7636 4.0625 11.0021 4.0625 11.2507V15.3132H15.9375V11.2507C15.9375 11.0021 16.0363 10.7636 16.2121 10.5878C16.3879 10.412 16.6264 10.3132 16.875 10.3132C17.1236 10.3132 17.3621 10.412 17.5379 10.5878C17.7137 10.7636 17.8125 11.0021 17.8125 11.2507ZM7.53828 6.28902L9.0625 4.76636V11.2507C9.0625 11.4994 9.16127 11.7378 9.33709 11.9137C9.5129 12.0895 9.75136 12.1882 10 12.1882C10.2486 12.1882 10.4871 12.0895 10.6629 11.9137C10.8387 11.7378 10.9375 11.4994 10.9375 11.2507V4.76636L12.4617 6.29136C12.5489 6.37857 12.6525 6.44775 12.7664 6.49494C12.8803 6.54214 13.0025 6.56643 13.1258 6.56643C13.2491 6.56643 13.3712 6.54214 13.4852 6.49494C13.5991 6.44775 13.7026 6.37857 13.7898 6.29136C13.877 6.20416 13.9462 6.10063 13.9934 5.98669C14.0406 5.87275 14.0649 5.75063 14.0649 5.6273C14.0649 5.50397 14.0406 5.38185 13.9934 5.26791C13.9462 5.15397 13.877 5.05045 13.7898 4.96324L10.6648 1.83824C10.5777 1.75084 10.4743 1.68149 10.3603 1.63417C10.2463 1.58686 10.1242 1.5625 10.0008 1.5625C9.87739 1.5625 9.75522 1.58686 9.64126 1.63417C9.52731 1.68149 9.42382 1.75084 9.33672 1.83824L6.21172 4.96324C6.12451 5.05045 6.05534 5.15397 6.00814 5.26791C5.96095 5.38185 5.93665 5.50397 5.93665 5.6273C5.93665 5.87637 6.0356 6.11524 6.21172 6.29136C6.38784 6.46748 6.62671 6.56643 6.87578 6.56643C7.12485 6.56643 7.36372 6.46748 7.53984 6.29136L7.53828 6.28902Z"
      fill="currentColor"
    />
  </svg>
</template>
