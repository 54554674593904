<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.125 10.125C10.125 9.82663 10.2435 9.54048 10.4545 9.3295C10.6655 9.11853 10.9516 9 11.25 9H16.5C16.7984 9 17.0845 9.11853 17.2955 9.3295C17.5065 9.54048 17.625 9.82663 17.625 10.125C17.625 10.4234 17.5065 10.7095 17.2955 10.9205C17.0845 11.1315 16.7984 11.25 16.5 11.25H11.25C10.9516 11.25 10.6655 11.1315 10.4545 10.9205C10.2435 10.7095 10.125 10.4234 10.125 10.125ZM16.5 12.75H11.25C10.9516 12.75 10.6655 12.8685 10.4545 13.0795C10.2435 13.2905 10.125 13.5766 10.125 13.875C10.125 14.1734 10.2435 14.4595 10.4545 14.6705C10.6655 14.8815 10.9516 15 11.25 15H16.5C16.7984 15 17.0845 14.8815 17.2955 14.6705C17.5065 14.4595 17.625 14.1734 17.625 13.875C17.625 13.5766 17.5065 13.2905 17.2955 13.0795C17.0845 12.8685 16.7984 12.75 16.5 12.75ZM21.375 4.5V19.5C21.375 19.9973 21.1775 20.4742 20.8258 20.8258C20.4742 21.1775 19.9973 21.375 19.5 21.375H4.5C4.00272 21.375 3.52581 21.1775 3.17417 20.8258C2.82254 20.4742 2.625 19.9973 2.625 19.5V4.5C2.625 4.00272 2.82254 3.52581 3.17417 3.17417C3.52581 2.82254 4.00272 2.625 4.5 2.625H19.5C19.9973 2.625 20.4742 2.82254 20.8258 3.17417C21.1775 3.52581 21.375 4.00272 21.375 4.5ZM4.875 19.125H6.375V4.875H4.875V19.125ZM19.125 4.875H8.625V19.125H19.125V4.875Z"
      fill="currentColor"
    />
  </svg>
</template>
