import { isEmpty } from "./utils";

const chartSettingsManager = {
  setOrderConfirmation(confirmation) {
    localStorage.setItem("chart_confirmation", confirmation);
  },
  setChartBasis(basis) {
    localStorage.setItem("chart_basis", basis);
  },
  setChartAlerts(alerts) {
    localStorage.setItem("chart_alerts", {
      order_partially_filled: alerts?.order_partially_filled,
      order_filled: alerts?.order_filled,
      order_canceled: alerts?.order_canceled,
      liquidation: alerts?.liquidation,
      deposit: alerts?.deposit,
    });
  },
  setChartNotification(notification) {
    localStorage.setItem("chart_notification", {
      order_fulfillment_notification:
        notification?.order_fulfillment_notification,
      order_fulfillment_notification_sound:
        notification?.order_fulfillment_notification_sound,
      price_alert: notification?.price_alert,
      price_alert_sound: notification?.price_alert_sound,
    });
  },
  setChartTradingPanel(panel) {
    localStorage.setItem("chart_trading_panel", panel);
  },
  getOrderConfirmation() {
    return localStorage.getItem("chart_confirmation");
  },
  getChartBasis() {
    return localStorage.getItem("chart_basis");
  },
  getChartAlerts() {
    const alerts = localStorage.getItem("chart_alerts");
    return {
      order_partially_filled: alerts?.order_partially_filled,
      order_filled: alerts?.order_filled,
      order_canceled: alerts?.order_canceled,
      liquidation: alerts?.liquidation,
      deposit: alerts?.deposit,
    };
  },
  getChartNotification() {
    const notification = localStorage.getItem("chart_notification");
    return {
      order_fulfillment_notification:
        notification?.order_fulfillment_notification,
      order_fulfillment_notification_sound:
        notification?.order_fulfillment_notification_sound,
      price_alert: notification?.price_alert,
      price_alert_sound: notification?.price_alert_sound,
    };
  },
  getChartTradingPanel() {
    return isEmpty(localStorage.getItem("chart_trading_panel"))
      ? "right"
      : localStorage.getItem("chart_trading_panel");
  },
};

export default chartSettingsManager;
