<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.8266 12.4172L25.0766 3.66719C24.9024 3.49284 24.6956 3.35453 24.468 3.26015C24.2404 3.16578 23.9964 3.1172 23.75 3.11719H8.75C7.9212 3.11719 7.12634 3.44643 6.54029 4.03248C5.95424 4.61853 5.625 5.41339 5.625 6.24219V33.7422C5.625 34.571 5.95424 35.3658 6.54029 35.9519C7.12634 36.5379 7.9212 36.8672 8.75 36.8672H31.25C32.0788 36.8672 32.8737 36.5379 33.4597 35.9519C34.0458 35.3658 34.375 34.571 34.375 33.7422V13.7422C34.375 13.2453 34.1777 12.7687 33.8266 12.4172ZM25 8.89844L28.5938 12.4922H25V8.89844ZM9.375 33.1172V6.86719H21.25V14.3672C21.25 14.8645 21.4475 15.3414 21.7992 15.693C22.1508 16.0446 22.6277 16.2422 23.125 16.2422H30.625V33.1172H9.375ZM26.875 20.6172C26.875 21.1145 26.6775 21.5914 26.3258 21.943C25.9742 22.2946 25.4973 22.4922 25 22.4922H15C14.5027 22.4922 14.0258 22.2946 13.6742 21.943C13.3225 21.5914 13.125 21.1145 13.125 20.6172C13.125 20.1199 13.3225 19.643 13.6742 19.2914C14.0258 18.9397 14.5027 18.7422 15 18.7422H25C25.4973 18.7422 25.9742 18.9397 26.3258 19.2914C26.6775 19.643 26.875 20.1199 26.875 20.6172ZM26.875 26.8672C26.875 27.3645 26.6775 27.8414 26.3258 28.193C25.9742 28.5446 25.4973 28.7422 25 28.7422H15C14.5027 28.7422 14.0258 28.5446 13.6742 28.193C13.3225 27.8414 13.125 27.3645 13.125 26.8672C13.125 26.3699 13.3225 25.893 13.6742 25.5414C14.0258 25.1897 14.5027 24.9922 15 24.9922H25C25.4973 24.9922 25.9742 25.1897 26.3258 25.5414C26.6775 25.893 26.875 26.3699 26.875 26.8672Z"
      fill="currentColor"
    />
  </svg>
</template>
