<template>
  <div>
    <p class="text-title sm:mb-12 mb-6">Create account</p>
    <UnderlineTab :items="items" @change="handleChangeSelectedTab" />
    <div class="sm:h-10 h-6" />
    <EmailAddress
      v-if="selectedTab == items[0].id"
      @changeEmail="handleChangeEmail"
      @changePassword="handleChangePassword"
      @changeConfirmPassword="handleChangeConfirmPassword"
      @changeRefferal="handleChangeRefferal"
    />

    <PhoneNumber
      v-if="selectedTab == items[1].id"
      @changePhone="handleChangePhone"
      @changePassword="handleChangePassword"
      @changeConfirmPassword="handleChangeConfirmPassword"
      @changeRefferal="handleChangeRefferal"
    />

    <div class="flex space-x-2 items-center mb-4 text-xs mt-6">
      <div class="min-w-5 h-5">
        <check-box @change="handleLegalDocsSigned" />
      </div>
      <p class="text-navy-300 dark:text-navy-200">
        By signing up I agree that I am 18 years old, not subject to
        international sanctions and I am giving my consent to UAB Exzi Markets
        to deal with my personal data based on
        <span class="text-primary-700 dark:text-white cursor-pointer">
          Privacy Policy, Cookie Policy and Terms&Conditions</span
        >
      </p>
    </div>
    <div class="flex space-x-2 items-center text-xs mb-6">
      <div class="min-w-5 h-5">
        <check-box @change="handleMeOnly" />
      </div>
      <p class="text-navy-300 dark:text-navy-200">
        Please confirm if you are acting on behalf of yourself and not acting on
        behalf of another person for legal reasons
      </p>
    </div>

    <filled-button
      label="Create Account"
      :isLoading="isLoading"
      :disabled="isDisabled"
      @click="handleShowCapture"
    />

    <GeeTest
      v-if="captchaData.showCaptcha && captchaData.data.gt"
      :gt="captchaData.data.gt"
      :challenge="captchaData.data.challenge"
      :success="captchaData.data.success"
      :onSuccess="handleCaptchaSuccess"
    />

    <div class="flex justify-center my-6 space-x-3">
      <p class="text-sm text-navy-800 dark:text-navy-100">
        Already have an account?
      </p>
      <link-button label="Log in" @click="handleSignin" />
    </div>

    <div class="flex items-center">
      <div class="w-full h-px bg-navy-500"></div>
      <label class="w-full pl-4 text-navy-200 text-center"
        >or continue with</label
      >
      <div class="w-full h-px bg-navy-500"></div>
    </div>
    <div class="h-4" />
    <div class="flex justify-center space-x-4">
      <div
        class="relative w-10 h-10 bg-navy-10 dark:bg-navy-500 rounded-full cursor-pointer"
        @click="handleSigninWithGoogle"
      >
        <img class="absolute m-auto inset-0" src="@/assets/icons/google.svg" />
      </div>

      <div
        class="relative w-10 h-10 bg-navy-10 dark:bg-navy-500 rounded-full cursor-pointer"
        @click="handleSigninWithMac"
      >
        <img
          v-if="currentTheme === 'light'"
          class="absolute m-auto inset-0"
          src="@/assets/icons/mac_black.svg"
        />
        <img
          v-if="currentTheme === 'dark'"
          class="absolute m-auto inset-0"
          src="@/assets/icons/mac_white.svg"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { postHttpRequest } from "@/api/api";
import axios from "@/api/axios";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import EmailAddress from "@/views/Auth/Signup/SignUpWith/EmailAddress.vue";
import PhoneNumber from "@/views/Auth/Signup/SignUpWith/PhoneNumber.vue";
import GeeTest from "@/components/GeeTest/GeeTest.vue";
import session_manager from "@/utils/SessionManager";
import { validateEmail, validatePassword } from "../validation.js";
const items = [
  { id: 1, name: "Email address" },
  { id: 2, name: "Phone Number" },
];

const captchaData = ref({
  showCaptcha: false,
  data: {
    gt: "",
    challenge: "",
    success: 0,
  },
});

const signupData = ref({
  email: "",
  phone: "",
  password: "",
  verifyPassword: "",
  country_of_residence: "",
  ref_code: "",
  geetestChallenge: "",
  geetestSecCode: "",
  geetestValidate: "",
  geettest_h: "",
  utm_source: "local",
  utm_content: "",
  legal_docs_signed: false,
  me_only: false,
});

const selectedTab = ref(1);
const isLoading = ref(false);

const router = useRouter();
const store = useStore();

onMounted(async () => {
  const response = await postHttpRequest(store)(
    "/api/default/location",
    {},
    false
  );
  if (response.status) {
    store.dispatch("setLocation", response.data);
    signupData.value.country_of_residence = response.data.country;
  }
});

const isDisabled = computed(() => {
  if (selectedTab.value == items[0].id) {
    return !(
      validateEmail(signupData.value.email) &&
      validatePassword(signupData.value.password).every(
        (item) => item === true
      ) &&
      signupData.value.verifyPassword === signupData.value.password
    );
  } else {
    return !(
      validatePassword(signupData.value.password).every(
        (item) => item === true
      ) && signupData.value.verifyPassword === signupData.value.password
    );
  }
});

const handleChangeSelectedTab = (tab) => {
  selectedTab.value = tab.id;
};

const handleShowCapture = async () => {
  try {
    isLoading.value = true;
    const res = await axios.get("/api/captcha?p=5");
    isLoading.value = false;
    const { challenge, geettest_h, gt, success } = res.data.data;
    captchaData.value.data = {
      challenge,
      gt,
      success,
      geettest_h,
    };
    captchaData.value.showCaptcha = true;
  } catch (error) {
    console.log(error);
  }
};

const handleCaptchaSuccess = async (result) => {
  signupData.value.geetestChallenge = result.geetest_challenge;
  signupData.value.geetestSecCode = result.geetest_seccode;
  signupData.value.geetestValidate = result.geetest_validate;
  signupData.value.geettest_h = captchaData.value.data.geettest_h;

  isLoading.value = true;
  const requestData = signupData.value;
  if (selectedTab.value == items[0].id) {
    delete requestData.phone;
  } else {
    delete requestData.email;
    requestData.phone = +requestData.phone;
  }
  const res = await axios.post("/api/user/registration", requestData);
  isLoading.value = false;
  if (res.data.status) {
    const authInfo = {
      token: res.data.data.login.token,
      secret: res.data.data.login.secret,
      email: res.data.data.user.email,
      phone: res.data.data.user.phone,
    };
    const codeInfo = {
      required: res.data.data.login.required,
      requiredType: res.data.data.login.required,
      codeSentTo: res.data.data.login.code_sent_to,
      timeBeforeNewCode: res.data.data.login.time_before_new_code,
    };
    session_manager.setTokenParsed(authInfo);
    session_manager.set2FactorCodeInfo(codeInfo);
    store.dispatch("setCode", codeInfo);
    store.dispatch("setAuthInfo", authInfo);
    router.push({ name: "verification-code", query: { back: "login" } });
  } else {
    store.dispatch("setToast", {
      type: "error",
      message: Object.values(res.data.data.errors).join(", "),
    });
  }
};

// Handle Email Signup
const handleChangeEmail = (newEmail) => {
  signupData.value.email = newEmail;
};

const handleChangePhone = (newPhone) => {
  signupData.value.phone = newPhone;
};

const handleChangePassword = (newPassword) => {
  signupData.value.password = newPassword;
};

const handleChangeConfirmPassword = (newPassword) => {
  signupData.value.verifyPassword = newPassword;
};

const handleChangeRefferal = (newRefferal) => {
  signupData.value.ref_code = newRefferal;
};

const handleLegalDocsSigned = (changed) => {
  signupData.value.legal_docs_signed = changed;
};

const handleMeOnly = (changed) => {
  signupData.value.me_only = changed;
};

const handleSignin = () => {
  router.push({ name: "signin" });
};
const handleSigninWithGoogle = () => {
  // Handle Sign in with google
  console.log("sign in with google");
};
const handleSigninWithMac = () => {
  // Handle Sign in with mac
  console.log("sign in with mac");
};
</script>
