<template>
  <div class="px-4 py-3 custom-scrollbar h-[510px]">
    <market-trades-context
      :header-items="headerItems"
      :context-items="orderBookHist"
    />
  </div>
</template>

<script setup>
import { watch, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { socket } from "@/socket/socket";
import MarketTradesContext from "@/views/Trading/OrderBook/MarketTrades/MarketTradesContext.vue";

const store = useStore();
const selectedPair = computed(() => store.getters.selectedPair);
const orderBookHist = computed(() => store.getters.orderBookHist);
const headerItems = computed(() => [
  {
    id: 1,
    label: `Price (${selectedPair.value.second.iso3})`,
    align: "left",
    value: "rate_f",
  },
  {
    id: 2,
    label: `Amount (${selectedPair.value.main.iso3})`,
    align: "left",
    value: "volume_f",
  },
  {
    id: 3,
    label: "Time",
    align: "right",
    value: "time_create",
  },
]);

onMounted(() => {
  socket.emit("subscribe", `hist_${selectedPair.value.id}`);
  store.dispatch("fetchOrderBookHist", selectedPair.value.id);
});

watch(selectedPair, (currentValue, prevValue) => {
  if (prevValue.id) {
    socket.emit("unsubscribe", `hist_${prevValue.id}`);
  }
  socket.emit("subscribe", `hist_${currentValue.id}`);
});

onUnmounted(() => {
  if (selectedPair.value) {
    socket.emit("unsubscribe", `hist_${selectedPair.value.id}`);
  }
});
</script>
