import DashboardIcon from "@/assets/icons/profile-sidebar/DashboardIcon.vue";
import FundingIcon from "@/assets/icons/asset/FundingIcon.vue";
import TradingIcon from "@/assets/icons/asset/TradingIcon.vue";
import DepositIcon from "@/assets/icons/asset/DepositIcon.vue";
import WithdrawIcon from "@/assets/icons/asset/WithdrawIcon.vue";
import TransactionHistoryIcon from "@/assets/icons/asset/TransactionHistoryIcon.vue";
import InvoicesIcon from "@/assets/icons/asset/InvoicesIcon.vue";
import AssetOverview from "@/views/Asset/Overview/AssetOverview.vue";
import AssetFunding from "@/views/Asset/Funding/AssetFunding.vue";

export const assetMenuItems = [
  {
    id: 1,
    name: "asset-overview",
    path: "overview",
    label: "Asset Overview",
    icon: DashboardIcon,
    content: AssetOverview,
  },
  {
    id: 2,
    name: "asset-funding",
    path: "funding",
    label: "Funding",
    icon: FundingIcon,
    content: AssetFunding,
  },
  {
    id: 3,
    name: "asset-trading",
    path: "trading",
    label: "Trading",
    icon: TradingIcon,
    content: AssetOverview,
  },
  {
    id: 4,
    name: "asset-deposit",
    path: "deposit",
    label: "Deposit",
    icon: DepositIcon,
    content: AssetOverview,
  },
  {
    id: 5,
    name: "asset-withdraw",
    path: "withdraw",
    label: "Withdraw",
    icon: WithdrawIcon,
    content: AssetOverview,
  },
  {
    id: 6,
    name: "asset-transaction-history",
    path: "transaction-history",
    label: "Transaction History",
    icon: TransactionHistoryIcon,
    content: AssetOverview,
  },
  {
    id: 7,
    name: "asset-invoices",
    path: "invoices",
    label: "Invoices",
    icon: InvoicesIcon,
    content: AssetOverview,
  },
];
