import store from "@/store/index";
import { postHttpRequest } from "@/api/api";

const state = {
  verification: {},
};

const mutations = {
  SET_VERIFICATION(state, verification) {
    state.verification = verification;
  },
};

const actions = {
  setVerification({ commit }, verification) {
    commit("SET_VERIFICATION", verification);
  },
  async fetchVerificationInfo({ commit }) {
    try {
      const result = await postHttpRequest(store)("/api/verification");
      if (result.status) {
        commit("SET_VERIFICATION", result.data);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  verificationInfo: (state) => state.verification,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
