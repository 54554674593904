<template>
  <div
    @click="handleClick"
    v-if="isSubMenu === false"
    class="cursor-pointer flex flex-row items-center text-sm font-medium dark:text-white text-navy-800 h-12 flex-shrink-0 hover:text-primary-700"
  >
    {{ label }}
  </div>
  <dropdown-select
    v-if="isSubMenu === true"
    :alignment="props.alignment"
    :isHoverTrigger="true"
    :mobile-adapted="props.mobileAdapted"
  >
    <template #header="{ handleContentClick, isShowingMenu }">
      <div
        v-if="!$slots?.icon"
        @click="handleContentClick"
        :class="[
          'cursor-pointer flex flex-row items-center text-sm font-medium  h-12 hover:text-primary-700',
          isShowingMenu ? 'text-primary-700' : 'dark:text-white text-navy-800',
        ]"
      >
        {{ label }}
        <span class="w-4 ml-2">
          <ChevronUpIcon v-if="isShowingMenu" class="stroke-2" />
          <ChevronDownIcon v-else class="stroke-2" />
        </span>
      </div>
      <div
        v-if="$slots?.icon"
        @click="handleContentClick"
        class="h-12 flex items-center"
      >
        <slot v-if="$slots?.icon" name="icon" :activated="isShowingMenu" />
      </div>
    </template>
    <template #menu="{ handleMenuItemClick }">
      <component
        :is="subMenu"
        v-bind="props.subMenuProps"
        @menuClick="handleMenuItemClick"
      />
    </template>
  </dropdown-select>
</template>
<script setup>
import { defineProps } from "vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";
import { isEmpty } from "@/utils/utils";
const router = useRouter();
const props = defineProps({
  label: {
    type: String,
    default: "",
    required: false,
  },
  isSubMenu: {
    type: Boolean,
    default: false,
  },
  subMenu: {
    type: Object,
    required: false,
  },
  subMenuProps: {
    type: Object,
    required: false,
  },
  alignment: {
    type: String,
    default: "",
    required: false,
  },
  pathName: {
    type: String,
  },
  mobileAdapted: {
    type: Boolean,
    default: false,
  },
});
const handleClick = () => {
  if (!isEmpty(props.pathName)) router.push({ name: props.pathName });
};
</script>
