<template>
  <text-edit
    v-model="value"
    size="sm"
    rounded="xl"
    type="number"
    :placeholder="placeholder"
    :error="error"
  >
    <template #prefix> <div class="w-2" /> </template>
    <template #suffix>
      <minus-icon v-if="value" class="w-5 h-5 storke-2 mr-1 cursor-pointer" />
      <plus-icon v-if="value" class="w-5 h-5 storke-2 cursor-pointer" />
      <div v-else class="text-sm dark:text-navy-100 text-navy-100 m-auto">
        {{ unit }}
      </div>
    </template>
  </text-edit>
</template>

<script setup>
import { defineModel, defineProps } from "vue";
import { MinusIcon, PlusIcon } from "@heroicons/vue/24/outline";

defineProps({
  unit: String,
  placeholder: String,
  error: String,
});

const value = defineModel();
</script>
