<template>
  <div class="relative">
    <slot
      name="header"
      :handleContentClick="handleContentClick"
      :isShowingContent="isShowingContent"
    />
    <div v-show="isShowingContent">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";

const emit = defineEmits(["headerClick"]);
const isShowingContent = ref(false);
const handleContentClick = () => {
  isShowingContent.value = !isShowingContent.value;
  emit("headerClick", isShowingContent.value);
};
</script>
