<template>
  <div
    class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
    v-if="isOpen"
  >
    <div
      class="dark:bg-navy-700 bg-white border border-navy-400 rounded-3xl shadow-xl max-w-xl w-full m-4 overflow-hidden"
    >
      <div
        v-if="containHeader"
        class="flex justify-between items-center px-6 py-4"
      >
        <h3 class="text-xl font-semibold dark:text-white text-navy-800">
          {{ title }}
        </h3>
        <button
          @click="closeDialog"
          class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
        >
          <XMarkIcon class="w-5 h-5" />
        </button>
      </div>
      <div v-else>
        <slot name="header"></slot>
      </div>
      <div class="m-6 mt-0">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
defineProps({
  title: String,
  isOpen: Boolean,
  containHeader: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["changeIsOpen"]);

const closeDialog = () => {
  emits("changeIsOpen");
};
</script>
