import { createStore } from "vuex";
import theme from "@/store/modules/theme";
import toast from "@/store/modules/toast";
import auth from "@/store/modules/auth";
import loading from "@/store/modules/loading";
import location from "@/store/modules/location";
import ticker from "@/store/modules/ticker";
import language from "@/store/modules/language";
import verification from "@/store/modules/verification";
import profile from "@/store/modules/profile";
import config from "@/store/modules/config";
import trade from "@/store/modules/trade";
import help from "@/store/modules/help";
import chartSettings from "@/store/modules/chartSettings";
import announcement from "./modules/announcement";
import career from "./modules/career";
import launchpad from "./modules/launchpad";

export default createStore({
  modules: {
    theme,
    toast,
    auth,
    loading,
    location,
    ticker,
    language,
    verification,
    profile,
    config,
    trade,
    help,
    chartSettings,
    announcement,
    career,
    launchpad,
  },
});
