import axios from "axios";
import session_manager from "@/utils/SessionManager";
import obj_sorter from "@/utils/Sorter";
import encrypt from "@/utils/Encrypt";
import { API_URL } from "@/constants/constants";
// import AutoUpdater from "@/utils/AutoUpdater";

/**
 * @param response
 * @returns {*}
 */
function onFullFilled(response) {
  // console.log("onFullFilled", response);
  // const authorization = response.data.token;

  // router.go('/maintenance');

  if (response.data.data && response.data.data.required) {
    if (!session_manager.isLoggedIn()) {
      session_manager.set2FactorCodeInfo({
        required: response.data.data.required,
        requiredType: response.data.data.required,
        codeSentTo: response.data.data.code_sent_to,
        timeBeforeNewCode: response.data.data.time_before_new_code,
      });
    }

    //const dispatch = useDispatch();
    //dispatch({ type: 'DATA_REQUIRED', payload: response.data.data.required });
  } else {
    // console.log('not required', response.data.data)
    // session_manager.remove2FactorRequirement();
    // vuex.commit('DATA_REQUIRED', null)
  }

  // if (authorization) {
  //   session_manager.setToken(authorization);
  // }

  // AutoUpdater(response.headers["x-app-hash"]);

  return response;
}

/**
 * @param err
 * @returns {Promise<never>}
 */
function onRejected(err) {
  // console.log("onRejected", err);

  // if (err.response && err.response.status === 500) {
  //   // {data:err.response.data, url:err.response.config.url, method:err.response.config.method}
  //   // vuex.commit('INTERNAL_ERROR', true)
  // }

  // if (err.response && err.response.status === 409) {
  //   //console.log('Back-end maintenance...');
  //   // if (router.currentRoute.path !== '/maintenance'){
  //   //     router.push('/maintenance');
  //   // }
  // }
  try {
    if (err.response && err.response.status === 401) {
      if (
        err.response?.data?.message === "Required code confirmation" ||
        err.response?.data?.message ===
          "Required code confirmation from Google" ||
        err.response?.data?.message === "Required code confirmation from Email"
      ) {
        localStorage.setItem("requiredMessage", err.response?.data?.message);

        if (
          window.location.pathname !== "/2fa-link-confirmation-sent" &&
          !window.location.pathname.startsWith("/link-confirmation")
        ) {
          window.location.href = "/2fa-link-confirmation-sent";
        }
        return Promise.reject(err.response);
      }

      if (window.location.pathname === "/2fa-link-confirmation-sent") {
        return Promise.reject(err.response);
      }

      session_manager.removeToken();
      if (window.location.pathname !== "/") {
        window.location.href = "/signin";
      }
    }
  } catch (error) {
    return Promise.reject(err.response);
  }

  // if (err.response && err.response.status === 402) {
  //   alert("402 error");
  // }
  return Promise.reject(err.response);
}

/**
 * @param error
 * @returns {Promise<never>}
 */
function onRequestError(error) {
  return Promise.reject(error);
}

/**
 * @param axios
 * @returns {*}
 */
const configure = (axios) => {
  axios.defaults.baseURL = API_URL;
  // axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  axios.interceptors.request.use((config) => {
    // Check GET request, if not exist query params then add it
    if (config.method === "get") {
      // eslint-disable-next-line no-prototype-builtins
      if (!config.hasOwnProperty("params")) {
        config["params"] = {};
      }

      // config.params["p"] = 5;
    }

    // Check POST / PUT request. if not exist config.data then add it with property
    if (config.method === "post" || config.method === "put") {
      // eslint-disable-next-line no-prototype-builtins
      if (!config.hasOwnProperty("data")) {
        config["data"] = {};
      }

      // Set server type
      // config.data.p = 5;

      // Set timestamp for unique request_id
      const randomInt = Math.floor(Math.random() * (999 - 100)) + 100;
      config.data.request_id =
        new Date().getTime().toString() + randomInt.toString();
    }

    // If user is logged
    if (session_manager.isLoggedIn()) {
      config.headers["Login-Token"] = session_manager.getToken();

      if (config.method === "post" || config.method === "put") {
        if (config.config && config.config.headers) {
          // for upload files req
          config.headers["X-Auth-Sign"] = config.config.headers["X-Auth-Sign"];
        } else {
          config.headers["X-Auth-Sign"] = encrypt(obj_sorter(config.data));
        }
      }
    }

    return config;
  }, onRequestError);

  // Add handler to response
  axios.interceptors.response.use(onFullFilled, onRejected);

  return axios;
};

/**
 * Export axios
 */
export default configure(axios);
