/**
 * Format Exponential Number
 *
 * @param input
 * @returns {*|string}
 */
export function formatExponentialNumber(input) {
  const data = String(input).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z = "",
    sign = this < 0 ? "-" : "",
    str = data[0].replace(".", ""),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + "0.";
    while (mag++) z += "0";
    return z + str.replace(/^-/, "");
  }

  mag -= str.length;
  while (mag--) z += "0";

  return str + z;
}

/**
 * Split number by digit group
 */
export function numberDelimiter(number, group = 3) {
  const parts = String(number).replace(/\s/g, "").trim().split(".");

  const integerValue = parts[0]
    .split("")
    .reverse()
    .map((symbol, index) => {
      if (index % group === 0 && index !== 0) {
        return symbol + " ";
      } else {
        return symbol;
      }
    })
    .reverse()
    .join("");

  return integerValue + (parts[1] ? "." + parts[1] : "");
}

export function addCommasToNumber(n, decimal) {
  const num = parseFloat(n);
  if (decimal < 0) {
    return num.toLocaleString("en-US", {
      minimumFractionDigits: -decimal,
      maximumFractionDigits: -decimal,
    });
  } else {
    const number =
      Math.floor(num / Math.pow(10, decimal)) * Math.pow(10, decimal);
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
}

/**
 * @param num
 * @param decimal
 * @returns {string}
 */
export function numberAddCommas(num, decimal = 2) {
  if (num === 0 || !num) {
    return "0";
  }

  const parts = String(num).split(".");
  parts[1] = parts[1] ? parts[1].replace(/0+$/, "") : "";

  // In integer is 0
  if (parts[0] === "0") {
    if (parts[1] === "") {
      return parts[0];
    }

    let countZeros = 0;

    for (const digit of parts[1].split("")) {
      if (digit === "0") {
        countZeros++;
      } else {
        break;
      }
    }

    if (countZeros > 0) {
      return parts.join(".");
    }

    return Number(num).toFixed(decimal);
  } else {
    const result =
      parts[1] !== "" ? Number(parts.join(".")).toFixed(decimal) : parts[0];

    return result.replace(/(?<!\.\d*)\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

/**
 *
 * @param num
 * @param decimals
 * @returns {string}
 */
export function numberToFinFormat(num, decimals = null) {
  if (num === 0 || !num) {
    return "0";
  }

  if (decimals) {
    num = Number(num).toFixed(decimals);
  }

  const parts = String(num).split(".");

  parts[0] = parts[0].replace(/(?<!\.\d*)\B(?=(\d{3})+(?!\d))/g, ",");

  if (decimals && parts[1] && parts[1].length < decimals) {
    parts[1] = parts[1].padEnd(decimals - parts[1].length, "0");
  }

  return parts.length > 1 ? parts.join(".") : parts[0];
}

/**
 *
 * @param val
 * @returns {string}
 */
export function addPlus(val) {
  let resVal = String(val);

  if (resVal === "-0.00") {
    resVal = "0.00";
  }

  if (resVal >= 0.01 && resVal.charAt(0) !== "+") {
    resVal = `+${resVal}`;
  }

  return resVal;
}

/**
 *
 * @param str
 * @param spanType
 * @returns {*|string}
 */
export function grayZero(str, spanType) {
  const lengs = {
    f: null,
    s: null,
  };
  const tempFixed = +str;
  let arr = [];
  let zCount = null;

  if (!String(tempFixed).includes("e")) {
    arr = String(tempFixed).split(".");
    // console.log(arr);
  } else {
    arr = Number(tempFixed).toFixed(8).split(".");
  }

  if (arr && arr.length > 1) {
    lengs.f = arr[0].length;
    lengs.s = arr[1].length;
    zCount = 9 - (lengs.f + lengs.s);
  }

  if (arr && arr.length === 1) {
    lengs.f = arr[0].length;
    zCount = 9 - lengs.f;
  }

  try {
    arr[2] = "0".repeat(zCount);
  } catch (e) {
    console.log(e);
  }

  if (spanType === 1) {
    if (arr[1]) {
      return `${arr[0]}.${arr[1]}`;
    }

    return `${arr[0]}.`;
  }

  if (spanType === 2) {
    return arr[2];
  }

  if (spanType === 3) {
    if (arr[1]) {
      return `${arr[0]}.${arr[1]}`;
    }

    return `${arr[0]}`;
  }
}

/**
 * @param number
 * @param decimal
 * @param stable
 * @returns {string}
 */
export function fixLengthNum(number, decimal, stable = false) {
  if (isNaN(number) || number === undefined) {
    //console.log('dfdfdf', number)
    return number;
  }

  let strNumber = number.toString();

  // Определяем позицию точки
  let dotIndex = strNumber.indexOf(".");

  // Если точка не найдена, добавляем ее в конец строки
  if (dotIndex === -1) {
    strNumber += ".";
    dotIndex = strNumber.length - 1;
  }

  // Определяем количество нулей после точки
  let zerosCount = strNumber.length - dotIndex - 1;

  // Если stable === true и число знаков после запятой больше 2, и decimal === null, устанавливаем decimal = 2
  if (stable && zerosCount > 2 && decimal === null) {
    decimal = 2;
  }

  // Если decimal не задан, оставляем все знаки после точки
  let precision = decimal === null ? zerosCount : Math.min(decimal, zerosCount);

  // Округляем число до указанной разрядности
  let roundedNumber = parseFloat(Number(number).toFixed(precision));

  // Преобразуем число в строку
  let formattedNumber = roundedNumber.toString();

  // Удаляем незначащие нули
  formattedNumber = formattedNumber.replace(/(\.[0-9]*[1-9])0+$/, "$1");

  return formattedNumber;
}

export function formatNumberInKilo(num, decimal = 2) {
  // Define the thresholds and corresponding suffixes
  const thresholds = [
    { value: 1e12, suffix: "T" },
    { value: 1e9, suffix: "B" }, // Billion
    { value: 1e6, suffix: "M" }, // Million
    { value: 1e3, suffix: "K" }, // Thousand
  ];

  // Iterate through the thresholds to find the right one
  for (let i = 0; i < thresholds.length; i++) {
    if (num >= thresholds[i].value) {
      return (
        (num / thresholds[i].value).toFixed(decimal) + thresholds[i].suffix
      );
    }
  }

  // If the number is less than 1000, return it as is
  return (num ?? 0).toString();
}

/**
 * Rounding number
 *
 * @param value
 * @param decimal
 * @param cut
 * @returns {number|string}
 */
export const numberRound = (value, decimal = 8, cut = false) => {
  if (String(value).includes("e")) {
    const fixedValue = formatExponentialNumber(value);

    return Number(fixedValue).toFixed(decimal);
  }

  if (cut) {
    const parts = String(value).split(".");

    if (parts.length === 2) {
      parts[1] = parts[1].slice(0, decimal);
      return parts.join(".");
    } else {
      return parts[0];
    }
  }

  return Number(value).toFixed(decimal);
};
