<template>
  <div class="px-4 py-6 h-80 flex flex-col custom-scrollbar">
    <table class="text-xs dark:text-navy-100 text-navy-200">
      <thead>
        <tr>
          <th
            v-for="item in headerItems"
            :key="item.id"
            class="border-b dark:border-navy-400 border-navy-10 font-medium p-2 text-left w-[100px]"
          >
            {{ item.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="border-b dark:border-navy-400 border-navy-10">
          <td class="p-2">In this trading pair</td>
        </tr>
        <tr
          v-for="(item, index) in showTokens"
          :key="index"
          class="border-b dark:border-navy-400 border-navy-10 dark:text-white text-navy-800"
        >
          <td
            v-if="item.isLabel"
            class="p-2 pt-8 dark:text-navy-100 text-navy-200"
          >
            Other assets
          </td>
          <template v-else>
            <td class="p-2">
              <img class="w-5 h-5" :src="item.icon" />
            </td>
            <td class="p-2">
              {{ item.currency.iso3 }}
            </td>
            <td class="p-2">
              {{ item.currency.name }}
            </td>
            <td class="p-2">
              {{
                numberAddCommas(
                  item.balance / Math.pow(10, item.decimal),
                  item.decimal
                )
              }}
            </td>
            <td class="p-2">
              ${{
                numberAddCommas(
                  item.total / Math.pow(10, item.decimal),
                  item.decimal
                )
              }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { numberAddCommas } from "@/utils/NumberFormating";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const headerItems = [
  { id: 1, name: "Symbol", value: "symbol" },
  { id: 2, name: "Asset", value: "asset" },
  { id: 3, name: "Name", value: "name" },
  { id: 4, name: "Amount", value: "amount" },
  { id: 5, name: "Total", value: "total" },
];

const store = useStore();
const selectedPair = computed(() => store.getters.selectedPair);
const balances = computed(() => store.getters.balancesVolumeModified);

const pairTokens = computed(() => [
  balances.value[selectedPair.value.main.id],
  balances.value[selectedPair.value.second.id],
]);

const otherTokens = computed(() =>
  Object.values(balances.value).filter(
    (item) =>
      item.currency.id !== selectedPair.value.main.id &&
      item.currency.id !== selectedPair.value.second.id &&
      item.balance != 0
  )
);

const showTokens = computed(() =>
  [
    ...pairTokens.value,
    { isLabel: true, label: "Other assets", currency: { id: 1 } },
    ...otherTokens.value,
  ].filter((item) => item)
);

onMounted(() => {
  console.log(balances.value);
});
</script>
