<template>
  <div
    :class="[
      'rounded-lg flex flex-col dark:bg-navy-500 bg-primary-100 w-full',
      paddingClass,
    ]"
  >
    <div class="flex flex-row items-center dark:text-navy-100 text-navy-300">
      <span :class="['mr-2 text-sm font-medium']">Total asset value</span>
      <div @click="showAssetProperty = !showAssetProperty">
        <EyeSlashIcon
          v-if="showAssetProperty"
          :class="[
            'w-4 h-4 cursor-pointer',
            props.size === 'lg' ? 'w-5 h-5' : 'w-4 h-4',
          ]"
        />
        <EyeIcon
          v-else
          :class="[
            'w-4 h-4 cursor-pointer',
            props.size === 'lg' ? 'w-5 h-5' : 'w-4 h-4',
          ]"
        />
      </div>
    </div>
    <div v-if="!isEmpty(balanceList)">
      <div class="flex flex-row items-center dark:text-white text-navy-800">
        <span
          :class="[
            'mr-1 font-bold',
            props.size === 'lg' ? 'text-[32px]' : 'text-xl',
          ]"
          >{{ showAssetProperty ? selectedBalance : "*****" }}</span
        >
        <dropdown-select @select="handleCurrencySelect">
          <template
            #header="{ handleContentClick: handleIconClick, isShowingMenu }"
          >
            <div
              class="flex flex-row items-center dark:text-white text-navy-800"
            >
              <span
                :class="['mr-1', props.size === 'lg' ? 'text-base' : 'text-sm']"
                >{{ currentSelectedCurrency.currency.iso3 }}
              </span>
              <div @click="handleIconClick">
                <ChevronUpIcon
                  v-if="isShowingMenu"
                  class="stroke-2 w-3 h-3 cursor-pointer"
                />
                <ChevronDownIcon
                  v-else
                  class="stroke-2 w-3 h-3 cursor-pointer"
                />
              </div>
            </div>
          </template>
          <template #menu="{ handleMenuItemClick }">
            <div
              class="flex flex-col w-fit dark:bg-navy-400 bg-primary-300 p-2 dark:text-white text-navy-800 text-sm font-medium gap-1 rounded-lg"
            >
              <div
                v-for="item in balanceList"
                :key="item.currency.id"
                class="py-1 px-2 cursor-pointer dark:hover:bg-navy-300 hover:bg-primary-500 rounded-md"
                @click="handleMenuItemClick(item)"
              >
                {{ item.currency.iso3 }}
              </div>
            </div>
          </template>
        </dropdown-select>
      </div>
      <div class="text-sm text-trade-buy" v-if="showAssetProperty">
        <span class="mr-1">+$5.88</span>
        <span>(+2.5%)</span>
      </div>
      <div v-else class="text-sm text-trade-buy">*****</div>
    </div>
  </div>
</template>
<script setup>
import EyeSlashIcon from "@/assets/icons/header/EyeSlashIcon.vue";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/vue/24/outline";
import { useStore } from "vuex";
import { computed, ref, defineProps, watch } from "vue";
import { isEmpty } from "@/utils/utils";
import EyeIcon from "@/assets/icons/header/EyeIcon.vue";

const props = defineProps(["size"]);
const store = useStore();

const paddingClass = computed(() => {
  return props.size === "lg" ? "p-6" : "p-4";
});

const balancesVolume = computed(() => store.getters.balancesVolumeModified);
const balanceList = computed(() => {
  if (isEmpty(balancesVolume.value)) {
    return [];
  }
  return Object.values(balancesVolume.value).filter(
    (item) => item.balance_available != 0 && item.deposit_type == "spot"
  );
});

const currentSelectedCurrency = ref(balanceList.value[0]);

watch(
  () => balanceList.value,
  (newValue) => {
    if (!isEmpty(newValue)) {
      currentSelectedCurrency.value = newValue[0];
    }
  }
);

const selectedBalance = computed(() => {
  if (isEmpty(balanceList.value)) {
    return 0;
  }
  const item = balanceList.value.find(
    (item) => item.currency.id === currentSelectedCurrency.value.currency.id
  );
  return item.balance_available_f;
});

const showAssetProperty = ref(false);

const handleCurrencySelect = (item) => {
  currentSelectedCurrency.value = item;
};
</script>
