<template>
  <div
    class="flex flex-col dark:bg-navy-700 bg-white dark:border-none border border-navy-10 dark:shadow-none shadow-2xl shadow-black/[0.08] rounded-2xl sm:px-6 px-4 py-4 w-full cursor-pointer"
  >
    <div class="flex flex-row">
      <img :src="props.headerImg" class="mr-3 w-8 h-8" />
      <div class="dark:text-white text-navy-800 text-lg font-semibold">
        {{ props.headerLabel }}
      </div>
    </div>
    <div class="my-4 border-t dark:border-navy-500 border-navy-10"></div>
    <div
      v-for="item in props.itemData.data"
      :key="item.id"
      class="flex flex-row items-center md:justify-between mb-4 sm:mb-6 sm:flex-wrap sm:justify-end justify-between"
    >
      <div class="flex flex-row items-center w-fit sm:basis-full md:basis-0">
        <img :src="item.img" class="w-6 h-6 mr-2" />
        <div class="dark:text-white text-navy-800 text-sm font-semibold mr-1">
          {{ item.token1 }}
        </div>
        <div class="text-navy-100 text-sm font-semibold">{{ item.token2 }}</div>
      </div>
      <div class="text-sm font-medium dark:text-white text-navy-800 mr-2">
        ${{ item.price }}
      </div>
      <div class="flex flex-row items-center w-fit text-sm font-medium">
        <PercentDisplay :value="item.percent" />
      </div>
    </div>
  </div>
</template>
<script setup>
import PercentDisplay from "@/common/PairTable/PercentDisplay.vue";
import { defineProps } from "vue";
const props = defineProps(["headerImg", "headerLabel", "itemData"]);
</script>
