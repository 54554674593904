<template>
  <span
    @click="handleClick"
    :class="[
      'cursor-pointer flex items-center justify-center rounded-full ',
      sizeClass,
      colorClass,
    ]"
  >
    <slot />
  </span>
</template>
<script setup>
import { defineEmits, defineProps, computed } from "vue";
const emit = defineEmits(["click"]);
const props = defineProps(["activated", "disabled", "color", "size"]);

const handleClick = () => {
  if (props.disabled) {
    return;
  }
  emit("click");
};

const colorClass = computed(() => {
  if (props.disabled) {
    return "dark:text-navy-300 dark:bg-navy-500 text-white bg-navy-100";
  }
  switch (props.color) {
    case "primary":
      return props.activated
        ? "bg-primary-700 text-white"
        : "border dark:border-navy-400 border-navy-10 dark:text-white text-navy-800 hover:bg-navy-300 hover:text-white";
    case "navy":
      return "dark:bg-navy-500 bg-navy-100 text-white hover:bg-navy-300 hover:text-white";
    case "navbar":
    default:
      return props.activated
        ? "bg-navy-300 text-white"
        : "dark:text-white text-navy-300 hover:bg-navy-300 hover:text-white";
  }
});

const sizeClass = computed(() => {
  switch (props.size) {
    case "xs":
      return "w-4 h-4";
    case "sm":
      return "w-6 h-6";
    case "lg":
      return "w-12 h-12";
    case "md":
    default:
      return "w-8 h-8 text-sm font-medium";
  }
});
</script>
