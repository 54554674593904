<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5723 3.01175C20.2399 2.9765 19.8786 3.02841 19.5156 3.17191C19.0656 3.34891 12.0144 6.31507 5.44141 9.08207L3.26953 9.99613C2.42853 10.3371 2.00391 10.8917 2.00391 11.6387C2.00391 12.1617 2.22525 12.8711 3.28125 13.2891L6.94727 14.7578C7.26427 15.7088 8.00055 17.9169 8.18555 18.5039C8.29555 18.8519 8.57339 19.7286 9.27539 19.9336C9.41939 19.9826 9.56966 20.0079 9.72266 20.0078C10.1657 20.0078 10.4846 19.8017 10.6406 19.6817L12.9707 17.711L15.8008 20.3282C15.9098 20.4392 16.4867 21 17.2617 21C18.2287 21 18.9622 20.1951 19.1152 19.4161C19.1982 18.9891 21.9277 5.2871 21.9277 5.2891C22.1727 4.1901 21.7322 3.61999 21.4492 3.37699C21.2067 3.16949 20.9046 3.047 20.5723 3.01175ZM19.9102 5.17191C19.5332 7.06191 17.478 17.3783 17.166 18.8653L13.0293 15.0391L10.2227 17.4161L11 14.375C11 14.375 16.3625 8.9469 16.6855 8.6309C16.9455 8.3779 17 8.28921 17 8.20121C17 8.08421 16.9398 8.00004 16.8008 8.00004C16.6758 8.00004 16.506 8.11982 16.416 8.17582C15.2727 8.88863 10.4041 11.6623 8.00781 13.0254L4.53125 11.6368L6.21875 10.9278C10.5177 9.11777 18.1742 5.89391 19.9102 5.17191Z"
      fill="currentColor"
    />
  </svg>
</template>
