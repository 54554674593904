<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 0.8125H1.75C1.3356 0.8125 0.938171 0.97712 0.645146 1.27015C0.35212 1.56317 0.1875 1.9606 0.1875 2.375V6.75C0.1875 10.9914 2.24375 13.5641 3.96875 14.9758C5.82109 16.4906 7.675 17.007 7.75312 17.0297C7.9148 17.0734 8.0852 17.0734 8.24688 17.0297C8.325 17.0078 10.1789 16.4906 12.0313 14.9758C13.7563 13.5641 15.8125 10.9914 15.8125 6.75V2.375C15.8125 1.9606 15.6479 1.56317 15.3549 1.27015C15.0618 0.97712 14.6644 0.8125 14.25 0.8125ZM13.9375 6.75C13.9375 9.53984 12.9148 11.8039 10.8977 13.4805C10.033 14.1939 9.0531 14.7548 8 15.1391C6.94681 14.755 5.96686 14.1941 5.10234 13.4805C3.08516 11.8039 2.0625 9.53984 2.0625 6.75V2.6875H13.9375V6.75ZM4.21172 9.28828C4.12451 9.20107 4.05534 9.09755 4.00814 8.98361C3.96095 8.86967 3.93665 8.74755 3.93665 8.62422C3.93665 8.50089 3.96095 8.37877 4.00814 8.26483C4.05534 8.15089 4.12451 8.04736 4.21172 7.96016C4.29892 7.87295 4.40245 7.80377 4.51639 7.75658C4.63033 7.70938 4.75245 7.68509 4.87578 7.68509C4.99911 7.68509 5.12123 7.70938 5.23517 7.75658C5.34911 7.80377 5.45264 7.87295 5.53984 7.96016L6.75 9.17187L10.4617 5.45937C10.6378 5.28325 10.8767 5.18431 11.1258 5.18431C11.3749 5.18431 11.6137 5.28325 11.7898 5.45937C11.966 5.6355 12.0649 5.87437 12.0649 6.12344C12.0649 6.37251 11.966 6.61138 11.7898 6.7875L7.41484 11.1625C7.32775 11.2499 7.22425 11.3192 7.1103 11.3666C6.99634 11.4139 6.87417 11.4382 6.75078 11.4382C6.62739 11.4382 6.50522 11.4139 6.39126 11.3666C6.27731 11.3192 6.17382 11.2499 6.08672 11.1625L4.21172 9.28828Z"
      fill="currentColor"
    />
  </svg>
</template>
