<template>
  <div
    class="flex flex-col w-full p-4 dark:bg-navy-600 dark:border-none border border-primary-300 rounded-3xl bg-white"
  >
    <div
      class="py-9 flex flex-row items-center justify-center gap-5 dark:bg-navy-500 bg-primary-100 rounded-2xl"
    >
      <img class="w-14 h-14 rounded-full" :src="props.icon" />
      <div class="flex flex-col">
        <div class="dark:text-white text-2xl font-semibold text-navy-800">
          {{ props.fullName }}
        </div>
        <div class="dark:text-navy-100 text-navy-800 font-medium text-base">
          {{ props.shortName }}
        </div>
      </div>
    </div>
    <div
      class="text-sm font-medium dark:text-navy-100 text-navy-400 mt-4 w-full"
    >
      Arkham (ARKM) is an intel-to-earn economy powering the deanonymization of
      the blockchain withAI.
    </div>
    <div class="flex flex-row items-center justify-between w-full mt-6">
      <TextBadge
        size="md"
        class="dark:bg-navy-300 dark:text-primary-300 bg-primary-300 text-primary-800"
        label="2024.05.02, 14:00"
      />
      <div class="text-sm font-medium text-navy-100">-</div>
      <TextBadge
        size="md"
        class="dark:bg-navy-300 dark:text-primary-300 bg-primary-300 text-primary-800"
        label="2024.05.02, 14:00"
      />
    </div>
    <div class="flex flex-row items-center justify-between w-full mt-6">
      <div class="flex flex-col gap-1">
        <div class="dark:text-white font-semibold text-base text-navy-800">
          11,546
        </div>
        <div class="dark:text-navy-100 font-medium text-base text-navy-300">
          Participants
        </div>
      </div>
      <div class="flex flex-col gap-1 items-end">
        <div class="dark:text-white font-semibold text-base text-navy-800">
          2,784,546
        </div>
        <div class="dark:text-navy-100 font-medium text-base text-navy-300">
          Participants
        </div>
      </div>
    </div>
    <div class="w-full mt-6">
      <outline-button size="lg" color="primary" width="full">
        Details
      </outline-button>
    </div>
  </div>
</template>
<script setup>
import TextBadge from "@/components/Badge/TextBadge.vue";
import { defineProps } from "vue";
const props = defineProps([
  "icon",
  "fullName",
  "shortName",
  "description",
  "startTime",
  "endTime",
  "participant",
  "totalParticipant",
]);
</script>
