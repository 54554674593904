const state = {
  announcementList: [],
};

const mutations = {
  SET_ANNOUNCEMENT_LIST(state, list) {
    state.announcementList = list;
  },
};

const actions = {
  async fetchAnnouncementList({ commit }) {
    try {
      const resposne = await fetch("/mock/announcement/announcement_list.json");
      const data = await resposne.json();
      commit("SET_ANNOUNCEMENT_LIST", data);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  announcementList: (state) => state.announcementList,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
