import store from "@/store/index";
import { postHttpRequest } from "@/api/api";
import { socket } from "@/socket/socket";

const state = {
  profile: {},
};

const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
};

const actions = {
  setProfile({ commit }, profile) {
    commit("SET_PROFILE", profile);
  },
  async fetchAccountInfo({ commit }) {
    try {
      const result = await postHttpRequest(store)("/api/account/index");
      if (result.status) {
        commit("SET_PROFILE", result.data);
        if (result.data?.profile?.id) {
          socket.emit("subscribe", `user_${result.data.profile.id}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  profile: (state) => state.profile,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
