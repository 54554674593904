<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.875C9.99747 1.875 8.0399 2.46882 6.37486 3.58137C4.70981 4.69392 3.41206 6.27523 2.64572 8.12533C1.87939 9.97543 1.67888 12.0112 2.06955 13.9753C2.46023 15.9393 3.42454 17.7435 4.84055 19.1595C6.25656 20.5755 8.06066 21.5398 10.0247 21.9305C11.9888 22.3211 14.0246 22.1206 15.8747 21.3543C17.7248 20.5879 19.3061 19.2902 20.4186 17.6251C21.5312 15.9601 22.125 14.0025 22.125 12C22.122 9.3156 21.0543 6.74199 19.1562 4.84383C17.258 2.94567 14.6844 1.87798 12 1.875ZM16.1484 14.5575C16.6234 13.789 16.875 12.9034 16.875 12C16.875 11.0966 16.6234 10.211 16.1484 9.4425L18.3047 7.28625C19.324 8.64638 19.875 10.3003 19.875 12C19.875 13.6997 19.324 15.3536 18.3047 16.7137L16.1484 14.5575ZM9.375 12C9.375 11.4808 9.52896 10.9733 9.8174 10.5416C10.1058 10.1099 10.5158 9.7735 10.9955 9.57482C11.4751 9.37614 12.0029 9.32415 12.5121 9.42544C13.0213 9.52672 13.489 9.77673 13.8562 10.1438C14.2233 10.511 14.4733 10.9787 14.5746 11.4879C14.6759 11.9971 14.6239 12.5249 14.4252 13.0045C14.2265 13.4842 13.8901 13.8942 13.4584 14.1826C13.0267 14.471 12.5192 14.625 12 14.625C11.3038 14.625 10.6361 14.3484 10.1438 13.8562C9.65157 13.3639 9.375 12.6962 9.375 12ZM16.7138 5.69531L14.5575 7.85156C13.789 7.37661 12.9034 7.12504 12 7.12504C11.0966 7.12504 10.211 7.37661 9.4425 7.85156L7.28625 5.69531C8.64639 4.67597 10.3003 4.125 12 4.125C13.6997 4.125 15.3536 4.67597 16.7138 5.69531ZM5.69532 7.28906L7.85157 9.44531C7.37661 10.2138 7.12504 11.0994 7.12504 12.0028C7.12504 12.9062 7.37661 13.7918 7.85157 14.5603L5.69532 16.7166C4.67598 15.3564 4.125 13.7025 4.125 12.0028C4.125 10.3031 4.67598 8.64919 5.69532 7.28906ZM7.28907 18.3075L9.44532 16.1512C10.2138 16.6262 11.0994 16.8778 12.0028 16.8778C12.9062 16.8778 13.7918 16.6262 14.5603 16.1512L16.7166 18.3075C15.3564 19.3268 13.7025 19.8778 12.0028 19.8778C10.3031 19.8778 8.6492 19.3268 7.28907 18.3075Z"
      fill="currentColor"
    />
  </svg>
</template>
