import axios from "./axios";

// Http Get Request
export const getHttpRequest =
  (store) =>
  async (
    url,
    isShowingToast = true,
    errorMessage = "Error while sending get http request"
  ) => {
    try {
      store.dispatch("increasePendingRequest");
      const response = await axios.get(url);
      store.dispatch("decreasePendingRequest");
      if (typeof response.data !== "object") {
        return {
          status: true,
          data: response.data,
        };
      }
      if (response.data.status) {
        if (isShowingToast) {
          store.dispatch("setToast", {
            type: "success",
            message: response.data.message,
          });
        }

        return {
          status: true,
          data: response.data.data,
        };
      } else {
        if (isShowingToast) {
          store.dispatch("setToast", {
            type: "error",
            message: response.data.errors.join(","),
          });
        }

        return {
          status: false,
          data: response.data.data,
        };
      }
    } catch (error) {
      store.dispatch("decreasePendingRequest");
      if (isShowingToast) {
        store.dispatch("setToast", {
          type: "error",
          message: errorMessage,
        });
      }
      return { status: false };
    }
  };

// Http Post Request
export const postHttpRequest =
  (store) =>
  async (
    url,
    data,
    isShowingToast = true,
    errorMessage = "Error while sending post http request"
  ) => {
    try {
      store.dispatch("increasePendingRequest");
      const response = await axios.post(url, data);
      store.dispatch("decreasePendingRequest");
      if (typeof response.data !== "object") {
        return {
          status: true,
          data: response.data,
        };
      }
      if (response.data.status) {
        if (isShowingToast) {
          store.dispatch("setToast", {
            type: "success",
            message: response.data.message,
          });
        }

        return {
          status: true,
          data: response.data.data,
        };
      } else {
        if (isShowingToast) {
          store.dispatch("setToast", {
            type: "error",
            message: response.data.errors.join(","),
          });
        }

        return {
          status: false,
          data: response.data.data,
        };
      }
    } catch (error) {
      store.dispatch("decreasePendingRequest");
      if (isShowingToast) {
        store.dispatch("setToast", {
          type: "error",
          message: errorMessage,
        });
      }
      return { status: false };
    }
  };

// Http Patch Request
export const patchHttpRequest =
  (store) =>
  async (
    url,
    data,
    isShowingToast = true,
    errorMessage = "Error while sending patch http request"
  ) => {
    try {
      store.dispatch("increasePendingRequest");
      const response = await axios.patch(url, data);
      store.dispatch("decreasePendingRequest");
      if (typeof response.data !== "object") {
        return {
          status: true,
          data: response.data,
        };
      }
      if (response.data.status) {
        if (isShowingToast) {
          store.dispatch("setToast", {
            type: "success",
            message: response.data.message,
          });
        }

        return {
          status: true,
          data: response.data.data,
        };
      } else {
        if (isShowingToast) {
          store.dispatch("setToast", {
            type: "error",
            message: response.data.errors.join(","),
          });
        }

        return {
          status: false,
          data: response.data.data,
        };
      }
    } catch (error) {
      store.dispatch("decreasePendingRequest");
      if (isShowingToast) {
        store.dispatch("setToast", {
          type: "error",
          message: errorMessage,
        });
      }
      return { status: false };
    }
  };

// Http Delete Request
export const deleteHttpRequest =
  (store) =>
  async (
    url,
    isShowingToast = true,
    errorMessage = "Error while sending delete http request"
  ) => {
    try {
      store.dispatch("increasePendingRequest");
      const response = await axios.delete(url);
      store.dispatch("decreasePendingRequest");
      if (typeof response.data !== "object") {
        return {
          status: true,
          data: response.data,
        };
      }
      if (response.data.status) {
        if (isShowingToast) {
          store.dispatch("setToast", {
            type: "success",
            message: response.data.message,
          });
        }

        return {
          status: true,
          data: response.data.data,
        };
      } else {
        if (isShowingToast) {
          store.dispatch("setToast", {
            type: "error",
            message: response.data.errors.join(","),
          });
        }

        return {
          status: false,
          data: response.data.data,
        };
      }
    } catch (error) {
      store.dispatch("decreasePendingRequest");
      if (isShowingToast) {
        store.dispatch("setToast", {
          type: "error",
          message: errorMessage,
        });
      }
      return { status: false };
    }
  };
