<template>
  <div class="p-3">
    <p class="text-sm font-normal text-navy-400 dark:text-navy-100 p-3">
      When the following conditions are met, you will be notified by email, SMS,
      or through the platform.
    </p>
  </div>
</template>

<script setup></script>
