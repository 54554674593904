<template>
  <div v-if="!isEmpty(selectedPair)" class="px-4 py-3">
    <div class="flex items-center gap-4 mb-6">
      <img class="w-10 h-10 rounded-full" :src="selectedPair.main.icon" />
      <div>
        <p class="text-lg font-bold">{{ selectedPair.main.iso3 }}</p>
        <p class="text-sm font-semibold text-navy-200">
          {{ selectedPair.main.name }}
        </p>
      </div>
    </div>

    <div class="flex sm:flex-row flex-col sm:gap-28 gap-6">
      <div class="flex-1">
        <div class="text-sm font-normal leading-[22px] dark:text-navy-50">
          {{ description[0] }}
          <link-button
            v-if="description[1]"
            :label="description[2]"
            @click="handleShowMore"
          />
        </div>
        <p class="text-sm font-semibold mt-8 mb-3">Learn More</p>
        <div class="flex gap-2 flex-wrap">
          <badge-with-icon
            v-for="item in learnMoreItems"
            :key="item.id"
            size="bg"
            :label="item.label"
            :icon="item.icon"
          />
        </div>
        <p class="text-sm font-semibold mt-8 mb-3">Social Media</p>
        <div class="flex gap-6">
          <div
            class="text-navy-200 cursor-pointer"
            @click="handleTelegramClick"
          >
            <telegram-icon width="24" height="24" />
          </div>
          <div class="text-navy-200 cursor-pointer" @click="handleTwitterClick">
            <twitter-icon width="24" height="24" />
          </div>
        </div>
      </div>

      <div class="sm:w-[300px]">
        <div
          v-for="item in detailItems"
          :key="item.id"
          class="flex justify-between mb-3 dark:text-navy-50 text-navy-200 text-sm font-medium"
        >
          <span>{{ item.name }}</span>
          <span class="dark:text-white text-navy-800">
            {{ item.prefix }}
            {{
              item.format
                ? item.func(
                    convertToField(
                      item.fromCoinMarket
                        ? mainPairDetailFromMarketCap
                        : selectedPair,
                      item.value
                    )
                  )
                : convertToField(
                    item.fromCoinMarket
                      ? mainPairDetailFromMarketCap
                      : selectedPair,
                    item.value
                  )
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { isEmpty, convertToField } from "@/utils/utils.js";
import BadgeWithIcon from "@/components/Badge/BadgeWithIcon.vue";
import LinkIcon from "@/assets/icons/common/LinkIcon.vue";
import GithubIcon from "@/assets/icons/common/GithubIcon.vue";
import GlobalIcon from "@/assets/icons/common/GlobalIcon.vue";
import WhitepapperIcon from "@/assets/icons/common/WhitepapperIcon.vue";
import TwitterIcon from "@/assets/icons/common/TwitterIcon.vue";
import TelegramIcon from "@/assets/icons/common/TelegramIcon.vue";
import { formatNumberInKilo } from "@/utils/NumberFormating";
import moment from "moment";

const htmlToString = (html) => {
  // Create a temporary DOM element
  var tempElement = document.createElement("div");

  // Set the HTML content
  tempElement.innerHTML = html;

  // Retrieve the plain text
  var plainText = tempElement.textContent || tempElement.innerText || "";

  return plainText;
};

const learnMoreItems = [
  { id: 1, label: "Official Website", icon: GlobalIcon },
  { id: 2, label: "Github", icon: GithubIcon },
  { id: 3, label: "Blockchain Explorer", icon: LinkIcon },
  { id: 4, label: "Whitepapper", icon: WhitepapperIcon },
];

const detailItems = [
  {
    id: 1,
    name: "Market cap",
    value: "quote.USD.market_cap",
    prefix: "$",
    format: true,
    fromCoinMarket: true,
    func: formatNumberInKilo,
  },
  {
    id: 2,
    name: "Fully diluted market cap",
    value: "quote.USD.fully_diluted_market_cap",
    prefix: "$",
    format: true,
    fromCoinMarket: true,
    func: formatNumberInKilo,
  },
  {
    id: 3,
    name: "24h trading volume",
    value: "quote.USD.volume_24h",
    prefix: "$",
    format: true,
    fromCoinMarket: true,
    func: formatNumberInKilo,
  },
  {
    id: 4,
    name: "Market share",
    value: "quote.USD.market_cap_dominance",
    fromCoinMarket: true,
  },
  {
    id: 5,
    name: "Listing date",
    value: "date_added",
    format: true,
    fromCoinMarket: true,
    func: (value) => moment(value).format("YYYY-MM-DD"),
  },
  { id: 6, name: "All-time high (ATH)", value: "high_f", prefix: "$" },
  { id: 7, name: "All-time low (ATL)", value: "low_f", prefix: "$" },
  {
    id: 8,
    name: "Circulating supply",
    value: "circulating_supply",
    prefix: "$",
    format: true,
    fromCoinMarket: true,
    func: formatNumberInKilo,
  },
  {
    id: 9,
    name: "Maximum supply",
    value: "max_supply",
    prefix: "$",
    format: true,
    fromCoinMarket: true,
    func: formatNumberInKilo,
  },
  {
    id: 9,
    name: "Total supply",
    value: "total_supply",
    prefix: "$",
    format: true,
    fromCoinMarket: true,
    func: formatNumberInKilo,
  },
];

const store = useStore();
const selectedPair = computed(() => store.getters.selectedPair);
const mainPairDetailFromMarketCap = computed(
  () => store.getters.mainPairDetailFromMarketCap
);
const description = computed(() => {
  const desc = htmlToString(selectedPair.value.main.description);
  if (desc.length < 200) {
    return [desc, false];
  } else {
    if (isShowMore.value) {
      return [desc, true, "Show Less"];
    } else {
      return [desc.slice(0, 220) + "...", true, "Show More"];
    }
  }
});

const isShowMore = ref(false);
const handleShowMore = () => {
  isShowMore.value = !isShowMore.value;
};

const handleTelegramClick = () => {};
const handleTwitterClick = () => {};
</script>
