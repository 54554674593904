<template>
  <div
    class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
  >
    <div class="w-8 h-8 m-auto">
      <img src="@/assets/icons/spinner.svg" />
    </div>
  </div>
</template>

<script></script>
