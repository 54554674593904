import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/tailwind.css";
import "@/assets/css/input.css";
import "vue-final-modal/style.css";
import { createVfm } from "vue-final-modal";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// Components
import TextEdit from "@/components/TextEdit/TextEdit.vue";
import PasswordInput from "@/components/TextEdit/PasswordInput.vue";
import LinkButton from "@/components/Button/LinkButton.vue";
import FilledButton from "@/components/Button/FilledButton.vue";
import ListSelect from "@/components/List/ListSelect.vue";
import ToastView from "@/components/Toast/ToastView.vue";
import CollapseView from "@/components/Collapse/CollapseView.vue";
import CheckBox from "@/components/CheckBox/CheckBox.vue";
import FillButton from "@/components/Button/FillButton.vue";
import OutlineButton from "@/components/Button/OutlineButton.vue";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";

// Mixins
import themeMixin from "@/mixins/themeMixins";

const app = createApp(App);
app.component("text-edit", TextEdit);
app.component("password-input", PasswordInput);
app.component("link-button", LinkButton);
app.component("fill-button", FillButton);
app.component("outline-button", OutlineButton);
app.component("filled-button", FilledButton);
app.component("list-select", ListSelect);
app.component("toast-view", ToastView);
app.component("collapse-view", CollapseView);
app.component("check-box", CheckBox);
app.component("dropdown-select", DropdownSelect);
app.component("VueDatePicker", VueDatePicker);

const vfm = createVfm();
app.use(vfm);

app.mixin(themeMixin);
app.use(store);
app.use(router);
app.mount("#app");
