import { SOCKET_URL } from "@/constants/constants";

// Makes requests to CryptoCompare API
export async function makeApiRequest(query) {
  try {
    const response = await fetch(`${SOCKET_URL}/graph/hist?${query}`);
    return response.json();
    // return await axios.get(`/socket.exzi.com/graph/hist?${query}`);
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`);
  }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;

  return {
    short,
    full: `${exchange}:${short}`,
  };
}

const defautCountRequest = 5000;

const dataBars = {
  pair: "",
  resolution: 60,
  from: 0,
  to: 0,
  data: [],
};

export const getData = async (pair, resolution, from, to, limit) => {
  // console.log(`>>>>> [getData]: pair=${pair}, resolution=${resolution} from=${from} to=${to}`);

  let api_resolution = resolution === "1D" ? "D" : resolution;
  api_resolution = api_resolution === "1W" ? "W" : api_resolution;

  if (resolution !== dataBars.resolution || dataBars.pair !== pair) {
    dataBars.pair = pair;
    dataBars.data = [];
    dataBars.resolution = api_resolution;
    dataBars.from = 0;
    dataBars.to = 0;
  }

  const paramTo = dataBars.from === 0 ? to : dataBars.from;

  if (dataBars.from > from || to > dataBars.to) {
    const urlParameters = {
      t: pair,
      r: resolution,
      limit: limit > defautCountRequest ? limit : defautCountRequest,
      // limit: defautCountRequest,
      end: paramTo,
    };

    const query = Object.keys(urlParameters)
      .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
      .join("&");

    try {
      // console.log(`>>>>> [getData]: makeApiRequest query:`, query);
      const result = await makeApiRequest(query);
      // console.log(`>>>>> [getData]: makeApiRequest length:`, result.length);
      // if (result.length === 0) {
      //     console.log(`>>>>> [getData]: no data to ${paramTo}`);
      // }
      if (dataBars.from === 0 && dataBars.to) {
        dataBars.data = result;
      } else {
        const array = result.filter((item) => item.time < paramTo);
        dataBars.data = dataBars.data.concat(array);
      }

      dataBars.from = dataBars.data[dataBars.data.length - 1].time;
      dataBars.to = dataBars.data[0].time;

      // console.log('>>>>> [getData]: dataBars > ', dataBars.data.map(item => item.time));
    } catch (error) {
      // console.log('>>>>> [getData]: Get error', error);
    }
  }

  const returnBars = dataBars.data.filter(
    (item) => from <= item.time && item.time <= to
  );
  // console.log( '>>>>> [getData]: count returned data: ', returnBars.length );

  return returnBars;
};
