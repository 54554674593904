const state = {
  location: {},
};

const mutations = {
  SET_LOCATION(state, location) {
    state.location = location;
  },
};

const actions = {
  setLocation({ commit }, location) {
    commit("SET_LOCATION", location);
  },
};

const getters = {
  location: (state) => state.location,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
