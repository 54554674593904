<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3126 10.625C15.3126 10.8722 15.2392 11.1139 15.1019 11.3195C14.9645 11.525 14.7693 11.6852 14.5409 11.7798C14.3125 11.8745 14.0612 11.8992 13.8187 11.851C13.5762 11.8028 13.3535 11.6837 13.1787 11.5089C13.0039 11.3341 12.8848 11.1113 12.8366 10.8689C12.7883 10.6264 12.8131 10.3751 12.9077 10.1466C13.0023 9.91824 13.1625 9.72301 13.3681 9.58566C13.5737 9.44831 13.8153 9.375 14.0626 9.375C14.3941 9.375 14.712 9.5067 14.9464 9.74112C15.1809 9.97554 15.3126 10.2935 15.3126 10.625ZM18.4376 7.8125V14.0625C18.4376 14.7255 18.1742 15.3614 17.7053 15.8303C17.2365 16.2991 16.6006 16.5625 15.9376 16.5625H4.68756C4.02452 16.5625 3.38863 16.2991 2.91979 15.8303C2.45095 15.3614 2.18756 14.7255 2.18756 14.0625V4.75938C2.17794 4.42507 2.2355 4.09224 2.35683 3.78058C2.47815 3.46892 2.66077 3.18477 2.89388 2.94496C3.127 2.70515 3.40585 2.51454 3.71395 2.38444C4.02205 2.25433 4.35312 2.18736 4.68756 2.1875H15.0001C15.2487 2.1875 15.4872 2.28627 15.663 2.46209C15.8388 2.6379 15.9376 2.87636 15.9376 3.125C15.9376 3.37364 15.8388 3.6121 15.663 3.78791C15.4872 3.96373 15.2487 4.0625 15.0001 4.0625H4.68756C4.60374 4.06246 4.52078 4.07927 4.44359 4.11194C4.36641 4.14461 4.29659 4.19248 4.23828 4.25268C4.17997 4.31289 4.13436 4.3842 4.10417 4.46239C4.07399 4.54058 4.05983 4.62404 4.06256 4.70781V4.71406C4.07568 4.87955 4.15163 5.03374 4.27482 5.14501C4.398 5.25629 4.55909 5.31621 4.72506 5.3125H15.9376C16.6006 5.3125 17.2365 5.57589 17.7053 6.04473C18.1742 6.51357 18.4376 7.14946 18.4376 7.8125ZM16.5626 7.8125C16.5626 7.64674 16.4967 7.48777 16.3795 7.37056C16.2623 7.25335 16.1033 7.1875 15.9376 7.1875H4.72506C4.50154 7.18759 4.27892 7.15924 4.06256 7.10313V14.0625C4.06256 14.2283 4.1284 14.3872 4.24562 14.5044C4.36283 14.6217 4.5218 14.6875 4.68756 14.6875H15.9376C16.1033 14.6875 16.2623 14.6217 16.3795 14.5044C16.4967 14.3872 16.5626 14.2283 16.5626 14.0625V7.8125Z"
      fill="currentColor"
    />
  </svg>
</template>
