function compareNumbers(a, b) {
  return a - b;
}

const sortData = (request) => {
  const ordered = {};
  let inOrdered = {};

  Object.keys(request)
    .sort()
    .forEach((key) => {
      ordered[key] = request[key];

      if (
        request[key] &&
        typeof request[key] === "object" &&
        !Array.isArray(request[key])
      ) {
        const requestKey = request[key];
        inOrdered = {};

        Object.keys(requestKey)
          .sort()
          .forEach((k) => {
            const requestKeyK = requestKey[k];

            if (
              requestKeyK &&
              typeof requestKeyK === "object" &&
              !Array.isArray(request[key])
            ) {
              Object.keys(requestKeyK)
                .sort(compareNumbers)
                .forEach((i) => {
                  requestKey[i] = requestKeyK[i];
                });
            }

            inOrdered[k] = requestKey[k];
          });

        ordered[key] = inOrdered;
      }
    });

  return ordered;
};

export default sortData;
