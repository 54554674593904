<template>
  <div class="w-full">
    <button
      :class="[
        'w-full h-24 dark:bg-navy-500 bg-navy-10 border-2 hover:border-primary-800 p-2 rounded-xl flex',
        mode === 'right' ? 'justify-end' : 'justify-start',
        active ? 'border-primary-700' : 'dark:border-navy-500 border-navy-10',
      ]"
      @click="handleClick"
    >
      <img src="@/assets/images/chart-settings/trading_panel.svg" />
    </button>
    <p
      :class="[
        'mt-4 text-center',
        active
          ? 'dark:text-white text-navy-800'
          : 'dark:text-navy-100 text-navy-200',
      ]"
    >
      Pro - {{ mode }}
    </p>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  mode: String,
  active: Boolean,
});

const emits = defineEmits(["click"]);

const handleClick = () => {
  emits("click");
};
</script>
