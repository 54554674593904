<template>
  <div
    class="w-full rounded-3xl flex-col flex items-start sm:items-end sm:flex-row justify-between sm:rounded-[32px] sm:max-w-[640px] dark:bg-navy-800 dark:border dark:border-navy-500 bg-primary-100 overflow-hidden"
  >
    <div class="flex flex-col justify-start sm:p-10 p-4 w-full">
      <TextBadge
        :icon="props.iconUrl"
        :label="props.iconLabel"
        class="text-base dark:text-primary-500 text-primary-700"
        size="md"
      />
      <div
        class="text-2xl font-semibold mt-3 sm:text-[32px] dark:text-white text-navy-800"
      >
        {{ props.title }}
      </div>
      <div class="mt-6 w-full sm:w-fit">
        <fill-button size="lg" width="full">{{
          props.buttonTitle
        }}</fill-button>
      </div>
    </div>
    <div class="max-w-60 max-sm:mt-4 mx-auto">
      <img :src="currentTheme === 'dark' ? props.imgUrl : props.imgLightUrl" />
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import TextBadge from "@/components/Badge/TextBadge.vue";
const props = defineProps([
  "iconUrl",
  "iconLabel",
  "title",
  "buttonTitle",
  "imgUrl",
  "imgLightUrl",
]);
</script>
