<template>
  <div class="p-3 h-screen flex flex-col">
    <div class="p-3 flex-grow overflow-y-auto">
      <p class="text-sm font-normal text-navy-400 dark:text-navy-100">
        When you switch to a new time zone, the change percentage for spot,
        margin, and futures will be calculated based on your new time zone.
        <br />
        <br />
        Switching to a new time zone will only affect the change percentage for
        the market data. This change will not apply to the candlestick chart.
      </p>
      <div
        class="mt-4 flex flex-col text-navy-400 dark:text-navy-100 flex-grow"
      >
        <nav class="flex flex-col gap-3 text-base text-blue-gray-700">
          <div v-for="timezone in allTimezones" :key="timezone">
            <button
              class="flex items-center w-full px-3 h-10 hover:bg-navy-10 dark:hover:bg-navy-400 text-navy-400 dark:text-navy-100 rounded-lg"
              :class="[
                timezone.value === selectedTimezone
                  ? 'bg-navy-10 dark:bg-navy-400 flex items-center justify-between'
                  : '',
              ]"
              v-on:click="handleChangeTimezone(timezone.value)"
            >
              {{ timezone.label }}
              <CheckIcon
                v-if="timezone.value === selectedTimezone"
                class="w-5 h-5 stroke-2 dark:text-white text-black"
              />
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { getTimezones } from "../../../utils/utils.js";
import { CheckIcon } from "@heroicons/vue/24/outline";

const store = useStore();

var selectedTimezone = ref(store.getters.chartBasis);
const allTimezones = getTimezones();

const handleChangeTimezone = (timezone) => {
  selectedTimezone.value = timezone;
  store.dispatch("setChartBasis", timezone);
};
</script>
